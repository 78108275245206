<template>
    <div class="preview_area show_sidebar" v-if="modelValue" :style="`
        --var-card-bg-color: ${form.public_settings.general_bg_color ? form.public_settings.general_bg_color : '#FFF4E2'};
        --var-card-text-color: ${form.public_settings.general_text_color ? form.public_settings.general_text_color : '#000000'};
        --var-card-elm-color: ${form.public_settings.general_element_color ? form.public_settings.general_element_color : '#ED9393'};
        --var-card-elm-fade-color: ${form.public_settings.general_element_color ? form.public_settings.general_element_color + 30 : '#2f7eed30'};;
        `">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="progress-wizard-form" class="progress-wizard-form">
                    <div class="step_wpr content_area">
                        <div class="info_bar">
                            <div class="step_bar2">
                                <ul>
                                    <li @click="toggleStep(1);" :class="{ 'active' : progressStep == 1 || progressStep > 1}">
                                        <span>1</span>
                                        <h6>Interval</h6>
                                    </li>
                                    <li @click="toggleStep(2)" :class="{ 'active' : progressStep == 2 || progressStep > 2}">
                                        <span>2</span>
                                        <h6>Tracking</h6>
                                    </li>
                                    <li @click="toggleStep(3)" :class="{ 'active' : progressStep == 3 || progressStep > 3}">
                                        <span>3</span>
                                        <h6>Public</h6>
                                    </li>
                                    <li @click="toggleStep(4);" :class="{ 'active' : progressStep == 4 || progressStep > 4}">
                                        <span>4</span>
                                        <h6>Notifications</h6>
                                    </li>
                                    <li @click="toggleStep(5)" :class="{ 'active' : progressStep == 5 || progressStep > 5 }">
                                        <span>5</span>
                                        <h6>SEO</h6>
                                    </li>
                                </ul>
                                <div class="total_bar"><span :style="`width:${(100 / 4) * (progressStep - 1) }%`"></span></div>
                            </div>
                            <button type="button" class="tutorial_btn">Tutorial</button>
                        </div>
                        <ul class="btn_list" v-if="progressStep == 2 || progressStep == 3">
                            <li v-if="toggleAll" @click="openAllSection">Open All</li>
                            <li v-else @click="closeAllSection">Close All</li>
                        </ul>
                        <div class="content_wpr" v-if="progressStep == 1">
                            <div class="section_content w-100 open_area">
                                <div class="progress_info"  v-click-outside="closeDescription">
                                    <div class="title_bar">
                                        <div class="inline-edit">
                                            <h3 class="sub_header" v-if="!editProgressName" @click="editProgressName = true;">{{ form.name }}</h3>
                                            <div class="rename_fld" v-if="editProgressName">
                                                <Field autocomplete="off" type="text" name="name" v-model="form.name" placeholder="Client tracking name" rules="required" />
                                                <button type="button" class="save_btn" @click="editProgressName = false;">Save</button>
                                                <ErrorMessage name="name" class="text-danger" />
                                            </div>
                                            <button type="button" class="setting_btn" @click="showDescription = !showDescription;"><i class="fas fa-cog"></i></button>
                                        </div>
                                    </div>
                                    <div class="setting_wpr description" :class="{'show' : showDescription}">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.description }">
                                                    <Field name="description" v-model="form.description" label="description" autocomplete="off" >
                                                        <textarea name="description" autocomplete="off" @focusout="handleAutoSaveForm()" cols="30" rows="10" placeholder="Description goes here.." v-model="form.description"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="description" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="color_container">
                                    <h3 class="sub_header mt-1">Collection Interval</h3>
                                    <div class="setting_wpr">
                                        <ul class="time_listing">
                                            <li v-if="form.is_classic">
                                                <label for="hourly" class="checkbox">
                                                    <input type="radio" id="hourly" :value="1" v-model="form.recurring_type" hidden>
                                                    <div class="selection_box">Hourly</div>
                                                </label>
                                            </li>
                                            <li>
                                                <label for="daily" class="checkbox">
                                                    <input type="radio" id="daily" :value="2" v-model="form.recurring_type" hidden>
                                                    <div class="selection_box">Daily</div>
                                                </label>
                                            </li>
                                            <li>
                                                <label for="weekly" class="checkbox">
                                                    <input type="radio" id="weekly" :value="3" v-model="form.recurring_type" hidden>
                                                    <div class="selection_box">Weekly</div>
                                                </label>
                                            </li>
                                            <li v-if="form.is_classic">
                                                <label for="monthly" class="checkbox">
                                                    <input type="radio" id="monthly" :value="4" v-model="form.recurring_type" hidden>
                                                    <div class="selection_box">Monthly</div>
                                                </label>
                                            </li>
                                            <li v-if="form.is_classic">
                                                <label for="yearly" class="checkbox">
                                                    <input type="radio" id="yearly" :value="5" v-model="form.recurring_type" hidden>
                                                    <div class="selection_box">Yearly</div>
                                                </label>
                                            </li>
                                        </ul>
                                        <div class="section_title mt-4" v-if="form.recurring_type == 1">
                                            Repeat Every <Field autocomplete="off" type="number" name="hour_count" v-model="form.recurring_settings.hours" placeholder="1" label="hours" :class="{ 'has-error': errors.hour_count }" class="sqr_input" :rules="{ min: 1 }" /> Hour(s)
                                        </div>
                                        <div v-if="form.recurring_type == 2">
                                            <ul class="day_type">
                                                <li>
                                                    <div class="day_wpr">
                                                        <label for="every">
                                                            <input type="radio" id="every" name="day_type" value="d" v-model="form.recurring_settings.day_type" hidden>
                                                            <span><i class="fas fa-check"></i></span>
                                                        </label>
                                                        <h4>Every <Field autocomplete="off" type="number" name="day_count" v-model="form.recurring_settings.days" placeholder="1" label="days" :class="{ 'has-error': errors.day_count }" :disabled="form.recurring_settings.day_type != 'd'" class="sqr_input" /> Day(s)</h4>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="day_wpr">
                                                        <label for="work">
                                                            <input type="radio" id="work" name="day_type" value="w" v-model="form.recurring_settings.day_type" hidden>
                                                            <span><i class="fas fa-check"></i></span>
                                                        </label>
                                                        <h4>Every Work Day</h4>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div class="time_selection">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">At</label>
                                                        <div class="field_wpr">
                                                            <multiselect v-model="form.recurring_settings.send_at" v-bind="hours"></multiselect>
                                                        </div>
                                                    </div>
                                                    <div class="group_item">
                                                        <div class="field_wpr">
                                                            <multiselect v-model="form.recurring_settings.send_at_type" v-bind="clock"></multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Timezone</label>
                                                        <div class="field_wpr">
                                                            <multiselect
                                                                v-model="form.recurring_settings.time_zone"
                                                                :options="timezones"
                                                                valueProp="timezone"
                                                                label="timezone"
                                                                :searchable="true"
                                                            >
                                                                <template v-slot:option="{ option }">
                                                                    <span>{{ `${ option.abbr} ${ option.utc_offset_str} ${ option.timezone}` }}</span>
                                                                </template>
                                                            </multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-4" v-if="form.recurring_type == 3">
                                            <div class="collection-wpr mb-5" v-if="form.is_classic == 0">
                                                <div class="day_wpr">
                                                    <label for="single-collection">
                                                        <input type="radio" id="single-collection" name="collection_type" :value="0" v-model="form.collection_type" hidden>
                                                        <span><i class="fas fa-circle"></i></span>
                                                    </label>
                                                    <h4>Single Entry</h4>
                                                </div>
                                                <div class="day_wpr">
                                                    <label for="spreadsheet-collection">
                                                        <input type="radio" id="spreadsheet-collection" name="collection_type" :value="1" v-model="form.collection_type" hidden>
                                                        <span><i class="fas fa-circle"></i></span>
                                                    </label>
                                                    <h4>Spreadsheet</h4>
                                                </div>
                                            </div>
                                            <div class="section_title">Repeat Every <Field autocomplete="off" type="number" name="week_count" class="sqr_input" v-model="form.recurring_settings.weeks" :class="{ 'has-error': errors.week_count }" :rules="{ min: 1 }" :disabled="form.is_classic == 0 && form.collection_type == 1" /> Week(s)</div>
                                            <ul class="days mt-2">
                                                <li v-for="(week, key) of weekdays" :key="key">
                                                    <label :for="`check-${key}`" :class="{'active' : form.recurring_settings.weekdays[week]}">
                                                        <span class="weekdays capitalize">{{ week }}</span>
                                                        <input type="checkbox" :id="`check-${key}`" v-model="form.recurring_settings.weekdays[week]" :value="true" @change="handleWeekdaysChange" :data-day="week" hidden>
                                                        <span class="tick_box"></span>
                                                    </label>
                                                </li>
                                            </ul>
                                            <div class="time_selection">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">At</label>
                                                        <div class="field_wpr">
                                                            <multiselect v-model="form.recurring_settings.send_at" v-bind="hours"></multiselect>
                                                        </div>
                                                    </div>
                                                    <div class="group_item">
                                                        <div class="field_wpr">
                                                            <multiselect v-model="form.recurring_settings.send_at_type" v-bind="clock"></multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Timezone</label>
                                                        <div class="field_wpr">
                                                            <multiselect
                                                                v-model="form.recurring_settings.time_zone"
                                                                :options="timezones"
                                                                valueProp="timezone"
                                                                label="timezone"
                                                                :searchable="true"
                                                            >
                                                                <template v-slot:option="{ option }">
                                                                    <span>{{ `${ option.abbr} ${ option.utc_offset_str} ${ option.timezone}` }}</span>
                                                                </template>
                                                            </multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="form.recurring_type == 4">
                                            <ul class="day_type">
                                                <li>
                                                    <div class="day_wpr">
                                                        <label for="month_repeat">
                                                            <input type="radio" id="month_repeat" name="month_type" :value="1" v-model="form.recurring_settings.repeat" hidden>
                                                            <span><i class="fas fa-check"></i></span>
                                                        </label>
                                                        <h4>
                                                            Repeat
                                                            <Field autocomplete="off" type="number" name="month_day" v-model="form.recurring_settings.day" placeholder="1" label="day" :class="{ 'has-error': errors.month_day }" :disabled="form.recurring_settings.repeat == 2" class="sqr_input" />
                                                            Day(s) Every
                                                            <Field autocomplete="off" type="number" name="month_count" v-model="form.recurring_settings.month" placeholder="1" label="month" :class="{ 'has-error': errors.month_count }" :disabled="form.recurring_settings.repeat == 2" class="sqr_input" />
                                                            Month(s)
                                                        </h4>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="day_wpr">
                                                        <label for="month_on">
                                                            <input type="radio" id="month_on" name="month_type" :value="2" v-model="form.recurring_settings.repeat" hidden>
                                                            <span><i class="fas fa-check"></i></span>
                                                        </label>
                                                        <h4>
                                                            On
                                                            <Field autocomplete="off" type="number" name="month_week2" v-model="form.recurring_settings.week" placeholder="1" label="week" :class="{ 'has-error': errors.month_week2 }" :disabled="form.recurring_settings.repeat == 1" class="sqr_input" />
                                                            <multiselect
                                                                v-model="form.recurring_settings.week_name"
                                                                :options="weekdays"
                                                                class="small_box weekdays"
                                                                :disabled="form.recurring_settings.repeat == 1"
                                                            ></multiselect>
                                                            Every
                                                            <Field autocomplete="off" type="number" name="month_count2" v-model="form.recurring_settings.month" placeholder="1" label="days" :class="{ 'has-error': errors.month_count }" :disabled="form.recurring_settings.repeat == 1" class="sqr_input" />
                                                            Month(s)
                                                        </h4>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div class="time_selection">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">At</label>
                                                        <div class="field_wpr">
                                                            <multiselect v-model="form.recurring_settings.send_at" v-bind="hours"></multiselect>
                                                        </div>
                                                    </div>
                                                    <div class="group_item">
                                                        <div class="field_wpr">
                                                            <multiselect v-model="form.recurring_settings.send_at_type" v-bind="clock"></multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Timezone</label>
                                                        <div class="field_wpr">
                                                            <multiselect
                                                                v-model="form.recurring_settings.time_zone"
                                                                :options="timezones"
                                                                valueProp="timezone"
                                                                label="timezone"
                                                                :searchable="true"
                                                            >
                                                                <template v-slot:option="{ option }">
                                                                    <span>{{ `${ option.abbr} ${ option.utc_offset_str} ${ option.timezone}` }}</span>
                                                                </template>
                                                            </multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="form.recurring_type == 5">
                                            <ul class="day_type">
                                                <li>
                                                    <div class="day_wpr">
                                                        <label for="year_every">
                                                            <input type="radio" id="year_every" name="year_type" :value="1" v-model="form.recurring_settings.repeat" hidden>
                                                            <span><i class="fas fa-check"></i></span>
                                                        </label>
                                                        <h4>
                                                            Every <Field autocomplete="off" type="number" name="year_day" v-model="form.recurring_settings.day" placeholder="1" label="day" :class="{ 'has-error': errors.year_day }" :disabled="form.recurring_settings.repeat == 2" class="sqr_input" />
                                                            <multiselect
                                                                v-model="form.recurring_settings.month"
                                                                :options="months"
                                                                class="small_box weekdays"
                                                                :disabled="form.recurring_settings.repeat == 2"
                                                            ></multiselect>
                                                            Month
                                                        </h4>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="day_wpr">
                                                        <label for="year_on">
                                                            <input type="radio" id="year_on" name="year_type" :value="2" v-model="form.recurring_settings.repeat" hidden>
                                                            <span><i class="fas fa-check"></i></span>
                                                        </label>
                                                        <h4>
                                                            On <Field autocomplete="off" type="number" name="year_week2" v-model="form.recurring_settings.week" placeholder="1" label="week" :class="{ 'has-error': errors.year_week2 }" :disabled="form.recurring_settings.repeat == 1" class="sqr_input" />
                                                            <multiselect
                                                                v-model="form.recurring_settings.week_name"
                                                                :options="weekdays"
                                                                class="small_box weekdays"
                                                                :disabled="form.recurring_settings.repeat == 1"
                                                            ></multiselect>
                                                            Of
                                                            <multiselect
                                                                v-model="form.recurring_settings.month"
                                                                :options="months"
                                                                class="small_box weekdays"
                                                                :disabled="form.recurring_settings.repeat == 1"
                                                            ></multiselect>
                                                            Month
                                                        </h4>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div class="time_selection">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">At</label>
                                                        <div class="field_wpr">
                                                            <multiselect v-model="hours.value" v-bind="hours"></multiselect>
                                                        </div>
                                                    </div>
                                                    <div class="group_item">
                                                        <div class="field_wpr">
                                                            <multiselect v-model="clock.value" v-bind="clock"></multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Timezone</label>
                                                        <div class="field_wpr">
                                                            <multiselect
                                                                v-model="form.recurring_settings.time_zone"
                                                                :options="timezones"
                                                                valueProp="timezone"
                                                                label="timezone"
                                                                :searchable="true"
                                                            >
                                                                <template v-slot:option="{ option }">
                                                                    <span>{{ `${ option.abbr} ${ option.utc_offset_str} ${ option.timezone}` }}</span>
                                                                </template>
                                                            </multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="section_title mt-4">Until</div>
                                        <ul class="day_type">
                                            <li>
                                                <div class="day_wpr">
                                                    <label for="ongoing">
                                                        <input type="radio" id="ongoing" value="1" v-model="form.recurring_settings.end_type" hidden>
                                                        <span><i class="fas fa-check"></i></span>
                                                    </label>
                                                    <h4>Ongoing</h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="day_wpr">
                                                    <label for="after">
                                                        <input type="radio" id="after" value="2" v-model="form.recurring_settings.end_type" hidden>
                                                        <span><i class="fas fa-check"></i></span>
                                                    </label>
                                                    <h4>After <Field autocomplete="off" type="number" name="end_occurrences" v-model="form.recurring_settings.end_occurrences" placeholder="1" rules="numeric" label="occurrences" :class="{ 'has-error': errors.end_occurrences }" :disabled="form.recurring_settings.end_type != 2" class="sqr_input" />Occurrences</h4>
                                                </div>
                                                <ErrorMessage name="end_occurrences" class="text-danger" />
                                            </li>
                                            <li>
                                                <div class="day_wpr">
                                                    <label for="end">
                                                        <input type="radio" id="end" value="3" v-model="form.recurring_settings.end_type" hidden>
                                                        <span><i class="fas fa-check"></i></span>
                                                    </label>
                                                    <h4>End On &nbsp;<datepicker v-model="form.recurring_settings.end_date" :min-date="new Date()" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy" :enableTimePicker="false" :disabled="form.recurring_settings.end_type != 3" class="dsdsf"></datepicker></h4>
                                                </div>
                                            </li>
                                        </ul>
                                        <div class="setting_wpr mt-4" v-if="form.recurring_settings.end_type == 1">
                                            <div class="section_title">
                                                Limited Submissions
                                                <label for="limited_submission" class="switch_option capsule_btn p-0 ml-auto">
                                                    <input type="checkbox" id="limited_submission" :true-value="1" :false-value="0" v-model="form.data_settings.limited_submission" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="form_grp pt-0" v-if="form.data_settings.limited_submission">
                                                <div class="group_item">
                                                    <label class="input_label">Allowable Submissions</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.total_submission }">
                                                        <Field autocomplete="off" type="number" name="total_submission" v-model="form.data_settings.total_submission" placeholder="1" rules="numeric" label="allowable submissions" />
                                                    </div>
                                                    <ErrorMessage name="total_submission" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="progressStep == 2">
                            <div class="section_content w-100" ref="formsetting">
                                <div class="section_header">
                                    <h2>General Settings</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container">
                                        <div class="setting_wpr">
                                            <div class="capsule_elm">
                                                <h5>Show Section Label</h5>
                                                <label for="show_section_label" class="switch_option capsule_btn m-0 border-0">
                                                    <input type="checkbox" id="show_section_label" :true-value="1" :false-value="0" v-model="form.data_settings.has_progress_question_label" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <Transition duration="550" name="nested">
                                                <div class="form_grp pt-1" v-if="form.data_settings.has_progress_question_label">
                                                    <div class="group_item">
                                                        <label class="input_label">Section Label</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.progress_question_label }">
                                                            <Field autocomplete="off" type="text" name="progress_question_label" v-model="form.data_settings.progress_question_label" placeholder="ex: Client Tracking Name" label="question label" rules="required" />
                                                        </div>
                                                        <ErrorMessage name="progress_question_label" class="text-danger" />
                                                    </div>
                                                </div>
                                            </Transition>
                                            <div class="scope_area mt-2 mb-2" v-if="form.is_classic">
                                                <div class="capsule_elm">
                                                    <h5>Set Goal Prior To Starting</h5>
                                                    <label for="coach_set_goal" class="switch_option capsule_btn border-0">
                                                        <input type="radio" name="reverse_goal" id="coach_set_goal" :value="0" v-model="form.data_settings.reverse_goal" @change="handleProgressAutoSave" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="capsule_elm">
                                                    <h5>Set Goal After First Submission</h5>
                                                    <label for="no_goal" class="switch_option capsule_btn border-0">
                                                        <input type="radio" name="reverse_goal" id="no_goal" :value="1" v-model="form.data_settings.reverse_goal" @change="handleProgressAutoSave" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="scope_area mt-2 mb-2" v-else>
                                                <div class="capsule_elm">
                                                    <h5>Enable Goals</h5>
                                                    <label for="set_goal_first" class="switch_option capsule_btn border-0">
                                                        <input type="radio" name="reverse_goal" id="set_goal_first" :value="1" v-model="form.has_goal" @change="handleProgressAutoSave" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="capsule_elm">
                                                    <h5>Disable Goals</h5>
                                                    <label for="set_goal_last" class="switch_option capsule_btn border-0">
                                                        <input type="radio" name="reverse_goal" id="set_goal_last" :value="0" v-model="form.has_goal" @change="handleProgressAutoSave" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <h3 class="sub_header">Color Settings</h3>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Background Color <span class="information"  v-tooltip="`Set the background color of the card`"><i class="fas fa-info-circle"></i></span> <a class="reset-default" @click="form.public_settings.general_bg_color = defaultForm.public_settings.general_bg_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="general_bg_color" type="text" label="label background color">
                                                        <color-picker v-model="form.public_settings.general_bg_color"/>
                                                    </Field>
                                                    <ErrorMessage name="general_bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Text Color <span class="information"  v-tooltip="`Set the text color for all the text in the card.`"><i class="fas fa-info-circle"></i></span> <a class="reset-default" @click="form.public_settings.general_text_color = defaultForm.public_settings.general_text_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="general_text_color" type="text" label="label text color">
                                                        <color-picker v-model="form.public_settings.general_text_color" />
                                                    </Field>
                                                    <ErrorMessage name="general_text_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Element Color <span class="information"  v-tooltip="`Set the slider color for all the slider in the card.`"><i class="fas fa-info-circle"></i></span> <a class="reset-default" @click="form.public_settings.general_element_color = defaultForm.public_settings.general_element_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="general_element_color" type="text" label="label element color">
                                                        <color-picker v-model="form.public_settings.general_element_color" />
                                                    </Field>
                                                    <ErrorMessage name="general_element_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" v-if="form.is_classic">
                                <div class="section_header">
                                    <h2>Body Composition</h2>
                                    <span class="status" :style="`color: ${form.data_settings.has_weight || form.data_settings.has_body_fat || form.data_settings.has_circumference ? '#2f7eed' : '#999'};`">{{ form.data_settings.has_weight || form.data_settings.has_body_fat || form.data_settings.has_circumference ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mb-4">
                                        <div class="section_title m-0">
                                            <img :src="require(`@/assets/images/weight.svg`)" alt="Weight">
                                            <div class="inline-edit">
                                                <div>Weight</div>
                                            </div>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="weight" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="weight" :true-value="1" :false-value="0" v-model="form.data_settings.has_weight" @change="handleProgressAutoSave" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <Transition duration="550" name="nested">
                                            <div class="setting_wpr mt-2 content_details">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Title</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.body_weight_title }">
                                                            <Field autocomplete="off" type="text" name="body_weight_title" v-model="form.data_settings.body_weight_title" placeholder="New Title" label="body weight title" rules="required" />
                                                        </div>
                                                        <ErrorMessage :name="`body_weight_title`" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Subtext</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.body_weight_sub_text }">
                                                            <Field autocomplete="off" type="text" name="body_weight_sub_text" v-model="form.data_settings.body_weight_sub_text" label="body weight sub text" :class="{ 'has-error': errors.body_weight_sub_text }">
                                                                <textarea cols="30" rows="10" v-model="form.data_settings.body_weight_sub_text" placeholder="Subtext goes here.."></textarea>
                                                            </Field>
                                                        </div>
                                                        <ErrorMessage name="body_weight_sub_text" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Max Limit</label>
                                                        <div class="field_wpr">
                                                            <input type="number" min="1" name="max_weight" v-model="form.data_settings.max_weight" placeholder="500">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Unit</label>
                                                        <div class="field_wpr">
                                                            <multiselect v-model="form.data_settings.weight_unit" :options="['Pounds', 'Kilograms']"></multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                    <div class="color_container mb-4">
                                        <div class="section_title m-0">
                                            <img :src="require(`@/assets/images/body_fat.svg`)" alt="Body Fat">
                                            <div class="inline-edit">
                                                <div>Body Fat</div>
                                            </div>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="body_fat" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="body_fat" :true-value="1" :false-value="0" v-model="form.data_settings.has_body_fat" @change="handleProgressAutoSave" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <Transition duration="550" name="nested">
                                            <div class="setting_wpr mt-2 content_details">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Title</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.body_fat_title }">
                                                            <Field autocomplete="off" type="text" name="body_fat_title" v-model="form.data_settings.body_fat_title" placeholder="New Title" label="body fat title" rules="required" />
                                                        </div>
                                                        <ErrorMessage :name="`body_fat_title`" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Subtext</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.body_fat_sub_text }">
                                                            <Field autocomplete="off" type="text" name="body_fat_sub_text" v-model="form.data_settings.body_fat_sub_text" label="body fat sub text" :class="{ 'has-error': errors.body_fat_sub_text }">
                                                                <textarea cols="30" rows="10" v-model="form.data_settings.body_fat_sub_text" placeholder="Subtext goes here.."></textarea>
                                                            </Field>
                                                        </div>
                                                        <ErrorMessage name="body_fat_sub_text" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp pb-3">
                                                    <div class="group_item">
                                                        <label class="input_label">Max Limit (%)</label>
                                                        <div class="field_wpr">
                                                            <input type="number" min="1" name="max_percent" v-model="form.data_settings.max_percent" placeholder="100">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                    <div class="color_container mb-4">
                                        <div class="section_title m-0">
                                            <img :src="require(`@/assets/images/measurement.svg`)" alt="Circumference">
                                            <div class="inline-edit">
                                                <div>Circumference</div>
                                            </div>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="circumference" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="circumference" :true-value="1" :false-value="0" v-model="form.data_settings.has_circumference" @change="handleProgressAutoSave" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <Transition duration="550" name="nested">
                                            <div class="setting_wpr mt-2 content_details">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Title</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.body_circumference_title }">
                                                            <Field autocomplete="off" type="text" name="body_circumference_title" v-model="form.data_settings.body_circumference_title" placeholder="New Title" label="body circumference title" rules="required" />
                                                        </div>
                                                        <ErrorMessage :name="`body_circumference_title`" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Subtext</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.body_circumference_sub_text }">
                                                            <Field autocomplete="off" type="text" name="body_circumference_sub_text" v-model="form.data_settings.body_circumference_sub_text" label="body circumference sub text" :class="{ 'has-error': errors.body_circumference_sub_text }">
                                                                <textarea cols="30" rows="10" v-model="form.data_settings.body_circumference_sub_text" placeholder="Subtext goes here.."></textarea>
                                                            </Field>
                                                        </div>
                                                        <ErrorMessage name="body_circumference_sub_text" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Unit</label>
                                                        <div class="field_wpr">
                                                            <multiselect v-model="form.data_settings.c_unit" :options="['Inches', 'Centimeters']"></multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul class="circumference_list">
                                                    <li>
                                                        <div class="sub_scope_area">
                                                            <div class="capsule_elm">
                                                                <h5>Neck</h5>
                                                                <label for="circumference-neck" class="switch_option capsule_btn p-0">
                                                                    <input type="checkbox" id="circumference-neck" :true-value="1" :false-value="0" v-model="form.data_settings.neck" @change="handleProgressAutoSave" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="sub_scope_area">
                                                            <div class="capsule_elm">
                                                                <h5>Shoulders</h5>
                                                                <label for="circumference-shoulders" class="switch_option capsule_btn p-0">
                                                                    <input type="checkbox" id="circumference-shoulders" :true-value="1" :false-value="0" v-model="form.data_settings.shoulders" @change="handleProgressAutoSave" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="sub_scope_area">
                                                            <div class="capsule_elm">
                                                                <h5>Biceps</h5>
                                                                <label for="circumference-biceps" class="switch_option capsule_btn p-0">
                                                                    <input type="checkbox" id="circumference-biceps" :true-value="1" :false-value="0" v-model="form.data_settings.biceps" @change="handleProgressAutoSave" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="sub_scope_area">
                                                            <div class="capsule_elm">
                                                                <h5>Chest</h5>
                                                                <label for="circumference-chest" class="switch_option capsule_btn p-0">
                                                                    <input type="checkbox" id="circumference-chest" :true-value="1" :false-value="0" v-model="form.data_settings.chest" @change="handleProgressAutoSave" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="sub_scope_area">
                                                            <div class="capsule_elm">
                                                                <h5>Belly Button</h5>
                                                                <label for="circumference-belly-button" class="switch_option capsule_btn p-0">
                                                                    <input type="checkbox" id="circumference-belly-button" :true-value="1" :false-value="0" v-model="form.data_settings.belly_button" @change="handleProgressAutoSave" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="sub_scope_area">
                                                            <div class="capsule_elm">
                                                                <h5>Waist</h5>
                                                                <label for="circumference-waist" class="switch_option capsule_btn p-0">
                                                                    <input type="checkbox" id="circumference-waist" :true-value="1" :false-value="0" v-model="form.data_settings.waist" @change="handleProgressAutoSave" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="sub_scope_area">
                                                            <div class="capsule_elm">
                                                                <h5>Hips</h5>
                                                                <label for="circumference-hips" class="switch_option capsule_btn p-0">
                                                                    <input type="checkbox" id="circumference-hips" :true-value="1" :false-value="0" v-model="form.data_settings.hips" @change="handleProgressAutoSave" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="sub_scope_area">
                                                            <div class="capsule_elm">
                                                                <h5>Thigh</h5>
                                                                <label for="circumference-thigh" class="switch_option capsule_btn p-0">
                                                                    <input type="checkbox" id="circumference-thigh" :true-value="1" :false-value="0" v-model="form.data_settings.thigh" @change="handleProgressAutoSave" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="sub_scope_area">
                                                            <div class="capsule_elm">
                                                                <h5>Calf</h5>
                                                                <label for="circumference-calf" class="switch_option capsule_btn p-0">
                                                                    <input type="checkbox" id="circumference-calf" :true-value="1" :false-value="0" v-model="form.data_settings.calf" @change="handleProgressAutoSave" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100" v-else>
                                <draggable v-model="form.categories" tag="div" item-key="id" class="drag_catagories w-100" :animation="200" handle=".handle-cat-drag">
                                    <template #item="{ element: category , index: c }">
                                        <div class="section_content w-100">
                                            <div class="section_header">
                                                <i class="fas fa-arrows-alt mr-2 handle-cat-drag"></i>
                                                <div class="inline-edit">
                                                    <h2 v-if="!category.is_edit" @click="category.is_edit = true;">{{ category.name }}</h2>
                                                    <div class="rename_fld" v-if="category.is_edit">
                                                        <Field autocomplete="off" type="text" name="name" v-model="category.name" placeholder="Category name" @change="updateProgressCategory(category)" rules="required" />
                                                        <button type="button" class="save_btn" @click="category.is_edit = false;">Save</button>
                                                        <ErrorMessage name="name" class="text-danger" />
                                                    </div>
                                                </div>
                                                <span class="status" :style="`color: ${category.progresses.find(prog => prog.enabled) ? '#2f7eed' : '#999'};`" v-if="category.progresses">{{ (category.progresses.find(prog => prog.enabled) && !category.is_custom) || (category.progresses.find(prog => prog.enabled) && category.is_custom && category.enabled) ? 'On' : 'Off'}}</span>
                                                <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                            </div>
                                            <div v-if="category.is_custom" class="toggle_content">
                                                <div class="color_container mb-4">
                                                    <div class="section_title m-0">
                                                        Show Custom Tracking
                                                        <label :for="`custom-category-${category.id}`" class="switch_option capsule_btn p-0 ml-auto">
                                                            <input type="checkbox" :id="`custom-category-${category.id}`" :true-value="1" :false-value="0" @change="updateProgressCategory(category)" v-model="category.enabled" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <Transition duration="550" name="nested">
                                                        <div class="setting_wpr mt-2 pb-3" v-if="category.enabled">
                                                            <template v-if="category.progresses && category.progresses.length">
                                                                <div class="question_wpr mb-4 color_container" v-for="(prog, p) of category.progresses" :key="p" @click="scrollingTo(`preview-${prog.id}`)" :id="`setting-${prog.id}`">
                                                                    <div class="section_title m-0">
                                                                        <img v-if="prog.icon" :src="prog.icon" :alt="prog.title">
                                                                        <img v-else-if="prog.default_icon" :src="require(`@/assets/images/${prog.default_icon}`)" :alt="prog.title">
                                                                        <div class="inline-edit">
                                                                            <div v-if="!prog.is_edit" @click="prog.is_edit = true;">{{prog.title}}</div>
                                                                            <div class="rename_fld" v-if="prog.is_edit">
                                                                                <Field autocomplete="off" type="text" :name="`name_${prog.id}`" v-model="prog.title" label="name" rules="required" />
                                                                                <button type="button" class="save_btn" @click="prog.is_edit = false; updateCategoryProgress(prog);">Save</button>
                                                                                <ErrorMessage :name="`name_${prog.id}`" class="text-danger" />
                                                                            </div>
                                                                        </div>
                                                                        <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                                                        <label :for="`custom-progress-${prog.id}`" class="switch_option capsule_btn p-0">
                                                                            <input type="checkbox" :id="`custom-progress-${prog.id}`" :true-value="1" :false-value="0" v-model="prog.enabled" hidden @change="updateCategoryProgress(prog)">
                                                                            <div><span></span></div>
                                                                        </label>
                                                                        <button type="button" class="delete_btn" @click="handleDeleteCustomTracking(category.progresses, prog.id, p)"><i class="fas fa-trash"></i></button>
                                                                    </div>
                                                                    <Transition duration="550" name="nested">
                                                                        <div class="tracking_wpr content_details">
                                                                            <div class="form_grp">
                                                                                <div class="group_item">
                                                                                    <label class="input_label">Title</label>
                                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`title_${prog.id}`] }">
                                                                                        <Field autocomplete="off" type="text" :name="`title_${prog.id}`" v-model="prog.title" placeholder="New Title" label="title" rules="required" />
                                                                                    </div>
                                                                                    <ErrorMessage :name="`title_${prog.id}`" class="text-danger" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="form_grp">
                                                                                <div class="group_item">
                                                                                    <label class="input_label">Subtext</label>
                                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`sub_text_${prog.id}`] }">
                                                                                        <Field autocomplete="off" type="text" :name="`sub_text_${prog.id}`" v-model="prog.sub_text" label="sub text">
                                                                                            <textarea cols="30" rows="10" v-model="prog.sub_text" placeholder="Subtext goes here.."></textarea>
                                                                                        </Field>
                                                                                    </div>
                                                                                    <ErrorMessage :name="`sub_text_${prog.id}`" class="text-danger" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="form_grp col-3">
                                                                                <div class="group_item" v-if="prog.type != 'percentage'">
                                                                                    <label class="input_label">Unit</label>
                                                                                    <div class="field_wpr m-0">
                                                                                        <multiselect
                                                                                            v-model="prog.unit"
                                                                                            :groups="true"
                                                                                            :searchable="true"
                                                                                            :options="prog.options"
                                                                                            valueProp="value"
                                                                                            label="value"
                                                                                            placeholder="Select unit..."
                                                                                            :group-select="false"
                                                                                            group-label="type"
                                                                                            group-options="units"
                                                                                            :group-hide-empty="true"
                                                                                            rules="required"
                                                                                            @select="handleUnitMinMax($event, prog)"
                                                                                        >
                                                                                            <template v-slot:grouplabel="{ group }">
                                                                                                <span class="status-tabs-header">{{ group.type }}</span>
                                                                                            </template>
                                                                                            <template v-slot:option="{ option }">
                                                                                                <span>{{ option.value }}</span>
                                                                                            </template>
                                                                                        </multiselect>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="group_item" v-if="prog.type != 'percentage'">
                                                                                    <label class="input_label">Low Limit</label>
                                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`low_limit_${prog.id}`] }">
                                                                                        <Field autocomplete="off" type="number" :name="`low_limit_${prog.id}`" min="0" v-model="prog.low_limit" :max="prog.unit == '%' ? 100 : (prog.type == 'ratings' ? 10 : 99999)" @keyup="handleNumberFieldMaxType($event, prog, 'low_limit')" @keydown="handleNumberFieldMaxType($event, prog, 'low_limit')" label="low limit" rules="required" />
                                                                                    </div>
                                                                                    <ErrorMessage :name="`low_limit_${prog.id}`" class="text-danger" />
                                                                                </div>
                                                                                <div class="group_item" v-if="prog.type != 'percentage'">
                                                                                    <label class="input_label">High Limit</label>
                                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`high_limit_${prog.id}`] }">
                                                                                        <Field autocomplete="off" type="number" :name="`high_limit_${prog.id}`" min="0" v-model="prog.high_limit" :max="prog.unit == '%' ? 100 : (prog.type == 'ratings' ? 10 : 99999)" @keyup="handleNumberFieldMaxType($event, prog, 'high_limit')" @keydown="handleNumberFieldMaxType($event, prog, 'high_limit')" label="high limit" rules="required" />
                                                                                    </div>
                                                                                    <ErrorMessage :name="`high_limit_${prog.id}`" class="text-danger" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="form_grp col-3" v-if="prog.unit && prog.unit !== 'num'">
                                                                                <div class="group_item">
                                                                                    <label class="input_label">Type</label>
                                                                                    <div class="field_wpr m-0">
                                                                                        <multiselect v-model="prog.type" :options="[{ value: 'ratings', label: 'Ratings'}, { value: 'slider', label: 'Slider' }, { value: 'input', label: 'Input' }, { value: 'percentage', label: 'Percentage' }]" @select="handleUnitMinMax($event, prog)"></multiselect>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="group_item" v-if="['Slider', 'slider', 'Ratings', 'ratings'].includes(prog.type) || prog.has_limit_label">
                                                                                    <label class="input_label">Low Label </label>
                                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`low_label_${prog.id}`] }">
                                                                                        <Field autocomplete="off" type="text" :name="`low_label_${prog.id}`" v-model="prog.low_label" placeholder="" label="low label" rules="required" />
                                                                                    </div>
                                                                                    <ErrorMessage :name="`low_label_${prog.id}`" class="text-danger" />
                                                                                </div>
                                                                                <div class="group_item" v-if="['Slider', 'slider', 'Ratings', 'ratings'].includes(prog.type) || prog.has_limit_label">
                                                                                    <label class="input_label">High Label</label>
                                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`high_label_${prog.id}`] }">
                                                                                        <Field autocomplete="off" type="text" :name="`high_label_${prog.id}`" v-model="prog.high_label" placeholder="" label="`high label" rules="required" />
                                                                                    </div>
                                                                                    <ErrorMessage :name="`high_label_${prog.id}`" class="text-danger" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="capsule_elm">
                                                                                <h5>Use Custom Icon</h5>
                                                                                <label :for="`${prog.id}-has_logo`" class="switch_option capsule_btn py-3 m-0">
                                                                                    <input type="checkbox" :id="`${prog.id}-has_logo`" :true-value="1" :false-value="0" v-model="prog.has_custom_icon" hidden>
                                                                                    <div><span></span></div>
                                                                                </label>
                                                                            </div>
                                                                            <div v-if="prog.has_custom_icon">
                                                                                <image-library v-model="prog.icon" image-type="custom-icon" upload-text="Logo"/>
                                                                            </div>
                                                                            <div class="capsule_elm">
                                                                                <h5>Additional Notes</h5>
                                                                                <label :for="`${prog.id}-has_notes`" class="switch_option capsule_btn py-3">
                                                                                    <input type="checkbox" :id="`${prog.id}-has_notes`" :true-value="1" :false-value="0" v-model="prog.has_notes" hidden>
                                                                                    <div><span></span></div>
                                                                                </label>
                                                                            </div>
                                                                            <div class="scope_area mt-2" v-if="prog.has_goal && form.has_goal">
                                                                                <div class="capsule_elm">
                                                                                    <h5>Goal?</h5>
                                                                                    <label :for="`${prog.id}-has-goal`" class="switch_option capsule_btn py-1">
                                                                                        <input type="checkbox" :id="`${prog.id}-has-goal`" :true-value="1" :false-value="0" v-model="prog.has_goal" hidden>
                                                                                        <div><span></span></div>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="pt-2" v-if="prog.has_goal">
                                                                                    <label :for="`${prog.id}-client-override`" class="checkbox py-2">
                                                                                        <input type="checkbox" :id="`${prog.id}-client-override`" :true-value="1" :false-value="0" v-model="prog.can_override_goal" hidden>
                                                                                        <span><i class="fas fa-check"></i></span>
                                                                                        <p>Client can override goal settings</p>
                                                                                    </label>
                                                                                    <div class="setting_wpr">
                                                                                        <div class="form_grp" v-if="prog.settings && prog.settings.goals && (prog.is_circumference || prog.is_macros || prog.is_vitamins || prog.is_minerals || prog.is_supplements || prog.is_workout || prog.is_zones || prog.is_blood_pressure || prog.is_sleep || prog.is_pain || prog.is_push_up || prog.is_pull_up)">
                                                                                            <div class="group_item" v-if="prog.goal_type">
                                                                                                <label class="input_label">Goal Type</label>
                                                                                                <div class="field_wpr m-0">
                                                                                                    <multiselect v-model="prog.goal_type" :options="goalOptions"></multiselect>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="w-100" v-for="(goal, g) of prog.settings.goals" :key="g">
                                                                                                <h3 class="sub_header mb-0 capitalize">Goal for {{ goal.title.replaceAll('_', ' ') }}</h3>
                                                                                                <div class="form_grp col-3 pt-2">
                                                                                                    <div class="group_item" v-if="prog.goal_type == 'Range'">
                                                                                                        <label class="input_label">Low Limit</label>
                                                                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors[`goal_low_limit_${prog.id}_${g}`] }">
                                                                                                            <Field autocomplete="off" type="number" :name="`goal_low_limit_${prog.id}_${g}`" v-model="prog.settings.goals[g].low_limit" :class="{ 'has-error': errors[`goal_low_limit_${prog.id}_${g}`] }" :min="0" :max="prog.unit == '%' ? 100 : (prog.unit == 'ratings' ? 10 : prog.max_limit)" @keyup="handleNumberFieldMaxType($event, prog.settings.goals[g], 'low_limit')" @keydown="handleNumberFieldMaxType($event, prog.settings.goals[g], 'low_limit')" />
                                                                                                            <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ (prog.unit == 'ratings' || prog.unit == 'Ratings') ? 'Stars' :  prog.unit }}</span>
                                                                                                        </div>
                                                                                                        <ErrorMessage :name="`goal_low_limit_${prog.id}_${g}`" class="text-danger" />
                                                                                                    </div>
                                                                                                    <div class="group_item" v-if="prog.goal_type == 'Range'">
                                                                                                        <label class="input_label">High Limit</label>
                                                                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors[`goal_high_limit_${prog.id}_${g}`] }">
                                                                                                            <Field autocomplete="off" type="number" :name="`goal_high_limit_${prog.id}_${g}`" v-model="prog.settings.goals[g].high_limit" :class="{ 'has-error': errors[`goal_high_limit_${prog.id}_${g}`] }" :min="0" :max="prog.unit == '%' ? 100 : (prog.unit == 'ratings' ? 10 : prog.max_limit)" @keyup="handleNumberFieldMaxType($event, prog.settings.goals[g], 'high_limit')" @keydown="handleNumberFieldMaxType($event, prog.settings.goals[g], 'high_limit')" />
                                                                                                            <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ (prog.unit == 'ratings' || prog.unit == 'Ratings') ? 'Stars' :  prog.unit }}</span>
                                                                                                        </div>
                                                                                                        <ErrorMessage :name="`goal_high_limit_${prog.id}_${g}`" class="text-danger" />
                                                                                                    </div>
                                                                                                    <div class="group_item" v-if="prog.goal_type == 'Target'">
                                                                                                        <label class="input_label">Target Type</label>
                                                                                                        <div class="field_wpr m-0">
                                                                                                            <multiselect v-model="prog.settings.goals[g].target_type" :options="goalTargetOptions"></multiselect>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="group_item" v-if="prog.goal_type == 'Target'">
                                                                                                        <label class="input_label">Target Value</label>
                                                                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors[`goal_target_${prog.id}_${g}`] }">
                                                                                                            <Field autocomplete="off" type="number" :name="`goal_target_${prog.id}_${g}`" v-model="prog.settings.goals[g].target" :class="{ 'has-error': errors[`goal_target_${prog.id}_${g}`] }" :min="0" :max="prog.max_limit" />
                                                                                                            <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ prog.unit }}</span>
                                                                                                        </div>
                                                                                                        <ErrorMessage :name="`goal_target_${prog.id}_${g}`" class="text-danger" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="form_grp col-3" v-else>
                                                                                            <div class="group_item">
                                                                                                <label class="input_label">Goal Type</label>
                                                                                                <div class="field_wpr m-0">
                                                                                                    <multiselect v-model="prog.goal_type" :options="goalOptions"></multiselect>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="group_item" v-if="prog.goal_type == 'Range'">
                                                                                                <label class="input_label">Low Limit</label>
                                                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors[`goal_low_limit_${prog.id}`] }">
                                                                                                    <Field autocomplete="off" type="number" :name="`goal_low_limit_${prog.id}`" v-model="prog.goal_low_limit" :class="{ 'has-error': errors[`goal_low_limit_${prog.id}`] }" :min="0" :max="prog.unit == '%' ? 100 : (prog.unit == 'ratings' ? 10 : prog.max_limit)" @keyup="handleNumberFieldMaxType($event, prog, 'goal_low_limit')" @keydown="handleNumberFieldMaxType($event, prog, 'goal_low_limit')" />
                                                                                                    <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ (prog.unit == 'ratings' || prog.unit == 'Ratings') ? 'Stars' :  prog.unit }}</span>
                                                                                                </div>
                                                                                                <ErrorMessage :name="`goal_low_limit_${prog.id}`" class="text-danger" />
                                                                                            </div>
                                                                                            <div class="group_item" v-if="prog.goal_type == 'Range'">
                                                                                                <label class="input_label">High Limit</label>
                                                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors[`goal_high_limit_${prog.id}`] }">
                                                                                                    <Field autocomplete="off" type="number" :name="`goal_high_limit_${prog.id}`" v-model="prog.goal_high_limit" :class="{ 'has-error': errors[`goal_high_limit_${prog.id}`] }" :min="0" :max="prog.unit == '%' ? 100 : (prog.unit == 'ratings' ? 10 : prog.max_limit)" @keyup="handleNumberFieldMaxType($event, prog, 'goal_high_limit')" @keydown="handleNumberFieldMaxType($event, prog, 'goal_high_limit')" />
                                                                                                    <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ (prog.unit == 'ratings' || prog.unit == 'Ratings') ? 'Stars' :  prog.unit }}</span>
                                                                                                </div>
                                                                                                <ErrorMessage :name="`goal_high_limit_${prog.id}`" class="text-danger" />
                                                                                            </div>
                                                                                            <div class="group_item" v-if="prog.goal_type == 'Target'">
                                                                                                <label class="input_label">Target Type</label>
                                                                                                <div class="field_wpr m-0">
                                                                                                    <multiselect v-model="prog.goal_target_type" :options="goalTargetOptions"></multiselect>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="group_item" v-if="prog.goal_type == 'Target'">
                                                                                                <label class="input_label">Target Value</label>
                                                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors[`goal_target_${prog.id}`] }">
                                                                                                    <Field autocomplete="off" type="number" :name="`goal_target_${prog.id}`" v-model="prog.goal_target" :class="{ 'has-error': errors[`goal_target_${prog.id}`] }" :min="0" max="999" @keyup="handleNumberFieldMaxType($event, prog, 'goal_target')" @keydown="handleNumberFieldMaxType($event, prog, 'goal_target')" />
                                                                                                    <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ prog.unit }}</span>
                                                                                                </div>
                                                                                                <ErrorMessage :name="`goal_target_${prog.id}`" class="text-danger" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="action_wpr mb-0 mt-4">
                                                                                <button type="button" :disabled="categoryProgressUpdateLoader" id="custom-tracking-update" class="btn save_btn" @click="handleCustomTracking(prog)">
                                                                                    <i class="fa fa-spin fa-spinner" v-if="categoryProgressUpdateLoader"></i> {{ categoryProgressUpdateLoader ? 'Updating' : 'Update' }}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </Transition>
                                                                </div>
                                                            </template>
                                                            <div class="empty_items" v-else>No Custom tracking items found</div>
                                                            <button type="button" :disabled="categoryProgressSaveLoader" class="custom_btn" @click="handleCustomTrakingCreate(c, category)">
                                                                <template v-if="categoryProgressSaveLoader">
                                                                    <i class="fa fa-spin fa-spinner"></i> Adding
                                                                </template>
                                                                <template v-else>
                                                                    <i class="fas fa-plus"></i>Add New Option
                                                                </template>
                                                            </button>
                                                        </div>
                                                    </Transition>
                                                </div>
                                            </div>
                                            <div v-else class="toggle_content">
                                                <div class="color_container mb-4" v-for="(prog, p) in category.progresses" :key="p" @click="scrollingTo(`preview-${prog.id}`)" :id="`setting-${prog.id}`">
                                                    <div class="section_title m-0">
                                                        <img v-if="prog.icon" :src="prog.icon" :alt="prog.title">
                                                        <img v-else-if="prog.default_icon" :src="require(`@/assets/images/${prog.default_icon}`)" :alt="prog.title">
                                                        <div class="inline-edit">
                                                            <div v-if="!prog.is_edit" @click="prog.is_edit = true;">{{prog.title}}</div>
                                                            <div class="rename_fld" v-if="prog.is_edit">
                                                                <Field autocomplete="off" type="text" :name="`name_${prog.id}`" v-model="prog.title" label="name" rules="required" />
                                                                <button type="button" class="save_btn" @click="prog.is_edit = false; updateCategoryProgress(prog);">Save</button>
                                                                <ErrorMessage name="name" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                                        <label :for="`${category.id}-${prog.id}`" class="switch_option capsule_btn p-0">
                                                            <input type="checkbox" :id="`${category.id}-${prog.id}`" :true-value="1" :false-value="0" v-model="prog.enabled" hidden @change="updateCategoryProgress(prog)">
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <Transition duration="550" name="nested">
                                                        <div class="setting_wpr mt-2 pb-3 content_details">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Title</label>
                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`title_${prog.id}`] }">
                                                                        <Field autocomplete="off" type="text" :name="`title_${prog.id}`" v-model="prog.title" placeholder="New Title" label="title" rules="required" />
                                                                    </div>
                                                                    <ErrorMessage :name="`title_${prog.id}`" class="text-danger" />
                                                                </div>
                                                            </div>
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Subtext</label>
                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`sub_text_${prog.id}`] }">
                                                                        <Field autocomplete="off" type="text" :name="`sub_text_${prog.id}`" v-model="prog.sub_text" label="sub text">
                                                                            <textarea cols="30" rows="10" v-model="prog.sub_text" placeholder="Subtext goes here.."></textarea>
                                                                        </Field>
                                                                    </div>
                                                                    <ErrorMessage :name="`sub_text_${prog.id}`" class="text-danger" />
                                                                </div>
                                                            </div>
                                                            <div class="form_grp col-3" v-if="!prog.is_sleep && !prog.is_stool && !prog.is_vitamins && !prog.is_minerals && !prog.is_supplements && !prog.is_push_up &&  !prog.is_pull_up">
                                                                <div class="group_item">
                                                                    <label class="input_label">Unit</label>
                                                                    <div class="field_wpr m-0">
                                                                        <multiselect v-model="prog.unit" :options="prog.options" @select="handleUnitMinMax($event, prog)"></multiselect>
                                                                    </div>
                                                                </div>
                                                                <div class="group_item" v-if="prog.has_limit && prog.unit != '%'">
                                                                    <label class="input_label">Low Limit</label>
                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`low_limit_${prog.id}`] }">
                                                                        <Field autocomplete="off" type="number" :name="`low_limit_${prog.id}`" :min="0" :max="prog.unit == '%' ? 100 : (prog.unit == 'ratings' ? 10 : prog.max_limit)" v-model="prog.low_limit" label="low limit" rules="required" @keyup="handleNumberFieldMaxType($event, prog, 'low_limit')" @keydown="handleNumberFieldMaxType($event, prog, 'low_limit')" />
                                                                    </div>
                                                                    <ErrorMessage :name="`low_limit_${prog.id}`" class="text-danger" />
                                                                </div>
                                                                <div class="group_item" v-if="prog.has_limit && prog.unit != '%'">
                                                                    <label class="input_label">High Limit</label>
                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`high_limit_${prog.id}`] }">
                                                                        <Field  autocomplete="off" type="number" :name="`high_limit_${prog.id}`" :min="0" :max="prog.unit == '%' ? 100 : (prog.unit == 'ratings' ? 10 : prog.max_limit)" v-model="prog.high_limit" label="high limit" rules="required" @keyup="handleNumberFieldMaxType($event, prog, 'high_limit')" @keydown="handleNumberFieldMaxType($event, prog, 'high_limit')" />
                                                                    </div>
                                                                    <ErrorMessage :name="`high_limit_${prog.id}`" class="text-danger" />
                                                                </div>
                                                            </div>
                                                            <div class="form_grp" v-if="prog.unit && (['Slider', 'slider', 'Ratings', 'ratings'].includes(prog.unit) || prog.has_limit_label) && !prog.is_sleep">
                                                                <div class="group_item">
                                                                    <label class="input_label">Low Label </label>
                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`low_label_${prog.id}`] }">
                                                                        <Field autocomplete="off" type="text" :name="`low_label_${prog.id}`" v-model="prog.low_label" placeholder="" label="low label" rules="required" />
                                                                    </div>
                                                                    <ErrorMessage :name="`low_label_${prog.id}`" class="text-danger" />
                                                                </div>
                                                                <div class="group_item">
                                                                    <label class="input_label">High Label</label>
                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`high_label_${prog.id}`] }">
                                                                        <Field autocomplete="off" type="text" :name="`high_label_${prog.id}`" v-model="prog.high_label" placeholder="" label="`high label" rules="required" />
                                                                    </div>
                                                                    <ErrorMessage :name="`high_label_${prog.id}`" class="text-danger" />
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-2" v-if="prog.is_sleep">
                                                                <div class="group_item" v-if="prog.unit">
                                                                    <label class="input_label">Unit</label>
                                                                    <div class="field_wpr m-0">
                                                                        <multiselect v-model="prog.unit" :options="prog.options" @select="handleUnitMinMax($event, prog)"></multiselect>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="prog.is_sleep" class="sub_scope_area_v2">
                                                                <div class="capsule_elm">
                                                                    <h5>Sleep Quality</h5>
                                                                    <label :for="`${prog.id}-${prog.title}`" class="switch_option capsule_btn p-0">
                                                                        <input type="checkbox" :id="`${prog.id}-${prog.title}`" :true-value="1" :false-value="0" v-model="prog.settings.has_sleep_quality" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                </div>
                                                                <div class="form_grp">
                                                                    <div class="group_item" v-if="prog.has_limit">
                                                                        <label class="input_label">Low Limit</label>
                                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors[`low_limit_${prog.id}`] }">
                                                                            <Field autocomplete="off" type="number" :name="`low_limit_${prog.id}`" :min="0" :max="prog.unit == '%' ? 100 : (prog.unit == 'ratings' ? 10 : prog.max_limit)" v-model="prog.low_limit" label="low limit" rules="required" @keyup="handleNumberFieldMaxType($event, prog, 'low_limit')" @keydown="handleNumberFieldMaxType($event, prog, 'low_limit')" />
                                                                        </div>
                                                                        <ErrorMessage :name="`low_limit_${prog.id}`" class="text-danger" />
                                                                    </div>
                                                                    <div class="group_item" v-if="prog.has_limit">
                                                                        <label class="input_label">High Limit</label>
                                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors[`high_limit_${prog.id}`] }">
                                                                            <Field autocomplete="off" type="number" :name="`high_limit_${prog.id}`" :min="0" :max="prog.unit == '%' ? 100 : (prog.unit == 'ratings' ? 10 : prog.max_limit)" v-model="prog.high_limit" label="high limit" rules="required" @keyup="handleNumberFieldMaxType($event, prog, 'high_limit')" @keydown="handleNumberFieldMaxType($event, prog, 'high_limit')" />
                                                                        </div>
                                                                        <ErrorMessage :name="`high_limit_${prog.id}`" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Low Label </label>
                                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors[`low_label_${prog.id}`] }">
                                                                            <Field autocomplete="off" type="text" :name="`low_label_${prog.id}`" v-model="prog.low_label" placeholder="" label="low label" rules="required" />
                                                                        </div>
                                                                        <ErrorMessage :name="`low_label_${prog.id}`" class="text-danger" />
                                                                    </div>
                                                                    <div class="group_item">
                                                                        <label class="input_label">High Label</label>
                                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors[`high_label_${prog.id}`] }">
                                                                            <Field autocomplete="off" type="text" :name="`high_label_${prog.id}`" v-model="prog.high_label" placeholder="" label="`high label" rules="required" />
                                                                        </div>
                                                                        <ErrorMessage :name="`high_label_${prog.id}`" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="prog.settings && (prog.is_circumference || prog.is_macros || prog.is_workout || prog.is_blood_pressure || prog.is_sleep || prog.is_push_up || prog.is_pull_up)">
                                                                <ul class="circumference_list mb-0" v-if="prog.settings.measurements && !prog.is_workout">
                                                                    <li v-for="(measurement, m) of prog.settings.measurements" :key="m">
                                                                        <div class="sub_scope_area">
                                                                            <div class="capsule_elm">
                                                                                <h5 class="capitalize">{{ measurement.title.replaceAll('_', ' ').replaceAll('rem', 'REM') }}</h5>
                                                                                <label :for="`${measurement.title}_${prog.id}`" class="switch_option capsule_btn p-0">
                                                                                    <input type="checkbox" :id="`${measurement.title}_${prog.id}`" :true-value="1" :false-value="0" v-model="prog.settings.measurements[m].enabled" hidden>
                                                                                    <div><span></span></div>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <ul class="checkbox_list" v-else-if="prog.is_workout">
                                                                    <li v-for="(measurement, m) of prog.settings.measurements" :key="m">
                                                                        <label :for="`${measurement.title}_${prog.id}`" class="checkbox">
                                                                            <span :class="{'checked' : measurement.enabled}"  @click="handleSelectOneCategory(measurement, prog.settings.measurements)"><i class="fas fa-check"></i></span>
                                                                            <div class="item_edit" v-if="measurement.is_custom">
                                                                                <p v-if="!measurement.is_edited" @click="measurement.is_edited = 1;" class="capitalize">{{ measurement.title }}</p>
                                                                                <div class="edit_fld" v-if="measurement.is_edited">
                                                                                    <Field autocomplete="off" type="text" name="custom_variable_name" v-model="measurement.title"/>
                                                                                    <button type="button" class="save_btn" @click="measurement.is_edited = 0;">Save</button>
                                                                                    <ErrorMessage name="name" class="text-danger" />
                                                                                </div>
                                                                            </div>
                                                                            <p v-else class="capitalize">{{measurement.title}}</p>
                                                                            <a class="delete_btn" v-if="measurement.is_custom && !measurement.is_edited" @click="deleteCustomVariable(m, prog.settings.measurements, prog)"><i class="fas fa-minus"></i></a>
                                                                        </label>
                                                                    </li>
                                                                    <li class="add_item">
                                                                        <button type="button" @click="customVariablePrompt($event)"><i class="fas fa-plus"></i>Add A {{  prog.is_vitamins ? 'Vitamin' : (prog.is_minerals ? 'Miniral' : 'Category')  }}</button>
                                                                        <div class="dropdown_wpr">
                                                                            <div class="form_grp p-0 m-0">
                                                                                <div class="group_item p-0">
                                                                                    <label class="input_label">Name:</label>
                                                                                    <div class="field_wpr">
                                                                                        <input type="text" v-model="customVariableName">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="action_wpr">
                                                                                <button type="button" class="cancel_btn" @click="customVariablePrompt($event)">Cancel</button>
                                                                                <button type="button" class="okay_btn" @click="addCustomVariable($event, prog.settings.measurements, prog)">Okay</button>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div v-else-if="prog.settings && (prog.is_vitamins || prog.is_minerals || prog.is_supplements)">
                                                                <ul class="checkbox_list">
                                                                    <li v-for="(measurement, m) of prog.settings" :key="m">
                                                                        <label :for="`${measurement.title}_${prog.id}`" class="checkbox">
                                                                            <span :class="{'checked' : measurement.enabled}" @click="handleSelectOneCategory(measurement, prog.settings)"><i class="fas fa-check"></i></span>
                                                                            <div class="item_edit" v-if="measurement.is_custom">
                                                                                <p v-if="!measurement.is_edited" @click="measurement.is_edited = 1;" class="capitalize">{{ measurement.title }}</p>
                                                                                <div class="edit_fld" v-if="measurement.is_edited">
                                                                                    <Field autocomplete="off" type="text" name="custom_variable_name" v-model="measurement.title"/>
                                                                                    <button type="button" class="save_btn" @click="measurement.is_edited = 0;">Save</button>
                                                                                    <ErrorMessage name="name" class="text-danger" />
                                                                                </div>
                                                                            </div>
                                                                            <p v-else class="capitalize">{{measurement.title}}</p>
                                                                            <a class="delete_btn" v-if="measurement.is_custom && !measurement.is_edited" @click="deleteCustomVariable(m, prog.settings, prog)"><i class="fas fa-minus"></i></a>
                                                                        </label>
                                                                    </li>
                                                                    <li class="add_item">
                                                                        <button type="button" @click="customVariablePrompt($event)"><i class="fas fa-plus"></i>Add A {{  prog.is_vitamins ? 'Vitamin' : (prog.is_minerals ? 'Miniral' : 'Supplement')  }}</button>
                                                                        <div class="dropdown_wpr">
                                                                            <div class="form_grp p-0 m-0">
                                                                                <div class="group_item p-0">
                                                                                    <label class="input_label">Name:</label>
                                                                                    <div class="field_wpr">
                                                                                        <input type="text" v-model="customVariableName">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="action_wpr">
                                                                                <button type="button" class="cancel_btn" @click="customVariablePrompt($event)">Cancel</button>
                                                                                <button type="button" class="okay_btn" @click="addCustomVariable($event, prog.settings, prog)">Okay</button>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div v-else-if="prog.is_pain">
                                                                <div class="body_area py-2" v-if="prog.settings.upper_body">
                                                                    <h3 class="sub_heading2 m-0">Upper Body</h3>
                                                                    <ul class="checkbox_list m-0">
                                                                        <li v-for="(upperBody, ub) of prog.settings.upper_body" :key="ub">
                                                                            <label :for="`${upperBody.title}_${prog.id}`" class="checkbox">
                                                                                <span :class="{'checked' : upperBody.enabled}" @click="handleSelectOneCategory(upperBody, prog.settings.upper_body)"><i class="fas fa-check"></i></span>
                                                                                <div class="item_edit" v-if="upperBody.is_custom">
                                                                                    <p v-if="!upperBody.is_edited" @click="upperBody.is_edited = 1;" class="capitalize">{{upperBody.title}}</p>
                                                                                    <div class="edit_fld" v-if="upperBody.is_edited">
                                                                                        <Field autocomplete="off" type="text" name="custom_variable_name" v-model="upperBody.title"/>
                                                                                        <button type="button" class="save_btn" @click="upperBody.is_edited = 0;">Save</button>
                                                                                        <ErrorMessage name="name" class="text-danger" />
                                                                                    </div>
                                                                                </div>
                                                                                <p v-else class="capitalize">{{upperBody.title}}</p>
                                                                                <a class="delete_btn" v-if="upperBody.is_custom && !upperBody.is_edited" @click="deleteCustomVariable(ub, prog.settings.upper_body, prog)"><i class="fas fa-minus"></i></a>
                                                                            </label>
                                                                        </li>
                                                                        <li class="add_item">
                                                                            <button type="button" @click="customVariablePrompt($event)"><i class="fas fa-plus"></i>Add A Pain</button>
                                                                            <div class="dropdown_wpr">
                                                                                <div class="form_grp p-0 m-0">
                                                                                    <div class="group_item p-0">
                                                                                        <label class="input_label">Name:</label>
                                                                                        <div class="field_wpr">
                                                                                            <input type="text" v-model="customVariableName">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="action_wpr">
                                                                                    <button type="button" class="cancel_btn" @click="customVariablePrompt($event)">Cancel</button>
                                                                                    <button type="button" class="okay_btn" @click="addCustomVariable($event, prog.settings.upper_body, prog)">Okay</button>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div class="body_area py-2" v-if="prog.settings.lower_body">
                                                                    <h3 class="sub_heading2 m-0">Lower Body</h3>
                                                                    <ul class="checkbox_list m-0">
                                                                        <li v-for="(lowerBody, ub) of prog.settings.lower_body" :key="ub">
                                                                            <label :for="`${lowerBody.title}_${prog.id}`" class="checkbox">
                                                                                <span :class="{'checked' : lowerBody.enabled}" @click="handleSelectOneCategory(lowerBody, prog.settings.lower_body)"><i class="fas fa-check"></i></span>
                                                                                <div class="item_edit" v-if="lowerBody.is_custom">
                                                                                    <p v-if="!lowerBody.is_edited" @click="lowerBody.is_edited = 1;" class="capitalize">{{lowerBody.title}}</p>
                                                                                    <div class="edit_fld" v-if="lowerBody.is_edited">
                                                                                        <Field autocomplete="off" type="text" name="custom_variable_name" v-model="lowerBody.title"/>
                                                                                        <button type="button" class="save_btn" @click="lowerBody.is_edited = 0;">Save</button>
                                                                                        <ErrorMessage name="name" class="text-danger" />
                                                                                    </div>
                                                                                </div>
                                                                                <p v-else class="capitalize">{{lowerBody.title}}</p>
                                                                                <a class="delete_btn" v-if="lowerBody.is_custom && !lowerBody.is_edited" @click="deleteCustomVariable(ub, prog.settings.lower_body, prog)"><i class="fas fa-minus"></i></a>
                                                                            </label>
                                                                        </li>
                                                                        <li class="add_item">
                                                                            <button type="button" @click="customVariablePrompt($event)"><i class="fas fa-plus"></i>Add A Pain</button>
                                                                            <div class="dropdown_wpr">
                                                                                <div class="form_grp p-0 m-0">
                                                                                    <div class="group_item p-0">
                                                                                        <label class="input_label">Name:</label>
                                                                                        <div class="field_wpr">
                                                                                            <input type="text" v-model="customVariableName">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="action_wpr">
                                                                                    <button type="button" class="cancel_btn" @click="customVariablePrompt($event)">Cancel</button>
                                                                                    <button type="button" class="okay_btn" @click="addCustomVariable($event, prog.settings.lower_body, prog)">Okay</button>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div v-else-if="prog.is_zones">
                                                                <ul class="circumference_list mb-0" v-if="prog.settings.measurements">
                                                                    <li v-for="(measurement, m) of prog.settings.measurements" :key="m" class="full">
                                                                        <div class="sub_scope_area">
                                                                            <h3 class="sub_header mt-1">
                                                                                <div class="inline-edit">
                                                                                    <div class="capitalize">{{ measurement.title.replaceAll('_', ' ') }}</div>
                                                                                </div>
                                                                                <label :for="`${measurement.title}_${prog.id}`" class="switch_option capsule_btn p-0">
                                                                                    <input type="checkbox" :id="`${measurement.title}_${prog.id}`" :true-value="1" :false-value="0" v-model="prog.settings.measurements[m].enabled" hidden>
                                                                                    <div><span></span></div>
                                                                                </label>
                                                                            </h3>
                                                                            <div class="form_grp col-3" v-show="prog.settings.measurements[m].enabled">
                                                                                <div class="group_item">
                                                                                    <label class="input_label">Low Limit</label>
                                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`low_limit_${prog.id}`] }">
                                                                                        <Field autocomplete="off" type="number" :name="`low_limit_${prog.id}_${measurement.title.replaceAll(' ', '_').toLowerCase()}`" :min="0" :max="prog.unit == '%' ? 100 : (prog.unit == 'ratings' ? 10 : prog.max_limit)" v-model="measurement.low_limit" label="low limit" rules="required" @keyup="handleNumberFieldMaxType($event, measurement, 'low_limit')" @keydown="handleNumberFieldMaxType($event, measurement, 'low_limit')" />
                                                                                    </div>
                                                                                    <ErrorMessage :name="`low_limit_${prog.id}`" class="text-danger" />
                                                                                </div>
                                                                                <div class="group_item">
                                                                                    <label class="input_label">High Limit</label>
                                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`high_limit_${prog.id}`] }">
                                                                                        <Field autocomplete="off" type="number" :name="`high_limit_${prog.id}_${measurement.title.replaceAll(' ', '_').toLowerCase()}`" :min="0" :max="prog.unit == '%' ? 100 : (prog.unit == 'ratings' ? 10 : prog.max_limit)" v-model="measurement.high_limit" label="high limit" rules="required" @keyup="handleNumberFieldMaxType($event, measurement, 'high_limit')" @keydown="handleNumberFieldMaxType($event, measurement, 'high_limit')" />
                                                                                    </div>
                                                                                    <ErrorMessage :name="`high_limit_${prog.id}`" class="text-danger" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="form_grp" v-show="prog.settings.measurements[m].enabled && prog.unit == '%%%'">
                                                                                <div class="group_item">
                                                                                    <label class="input_label">Low Label </label>
                                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`low_label_${prog.id}`] }">
                                                                                        <Field autocomplete="off" type="text" :name="`low_label_${prog.id}_${measurement.title.replaceAll(' ', '_').toLowerCase()}`" v-model="measurement.low_label" placeholder="" label="low label" rules="required" />
                                                                                    </div>
                                                                                    <ErrorMessage :name="`low_label_${prog.id}`" class="text-danger" />
                                                                                </div>
                                                                                <div class="group_item">
                                                                                    <label class="input_label">High Label</label>
                                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`high_label_${prog.id}`] }">
                                                                                        <Field autocomplete="off" type="text" :name="`high_label_${prog.id}_${measurement.title.replaceAll(' ', '_').toLowerCase()}`" v-model="measurement.high_label" placeholder="" label="high label" rules="required" />
                                                                                    </div>
                                                                                    <ErrorMessage :name="`high_label_${prog.id}`" class="text-danger" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="capsule_elm">
                                                                <h5>Use Custom Icon</h5>
                                                                <label :for="`${prog.id}-has_logo`" class="switch_option capsule_btn py-3 m-0">
                                                                    <input type="checkbox" :id="`${prog.id}-has_logo`" :true-value="1" :false-value="0" v-model="prog.has_custom_icon" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                            <div v-if="prog.has_custom_icon">
                                                                <image-library v-model="prog.icon" image-type="custom-icon" upload-text="Your Icon" />
                                                            </div>
                                                            <div class="capsule_elm">
                                                                <h5>Additional Notes</h5>
                                                                <label :for="`${prog.id}-has_notes`" class="switch_option capsule_btn py-3">
                                                                    <input type="checkbox" :id="`${prog.id}-has_notes`" :true-value="1" :false-value="0" v-model="prog.has_notes" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                            <div class="scope_area mt-2" v-if="!prog.is_stool && !prog.is_vitamins && !prog.is_minerals && !prog.is_supplements && form.has_goal">
                                                                <div class="capsule_elm">
                                                                    <h5>Goal?</h5>
                                                                    <label :for="`${prog.id}-has-goal`" class="switch_option capsule_btn py-1">
                                                                        <input type="checkbox" :id="`${prog.id}-has-goal`" :true-value="1" :false-value="0" v-model="prog.has_goal" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                </div>
                                                                <div class="pt-2" v-if="prog.has_goal">
                                                                    <label :for="`${prog.id}-client-override`" class="checkbox py-2">
                                                                        <input type="checkbox" :id="`${prog.id}-client-override`" :true-value="1" :false-value="0" v-model="prog.can_override_goal" hidden>
                                                                        <span><i class="fas fa-check"></i></span>
                                                                        <p>Client can override goal settings</p>
                                                                    </label>
                                                                    <div class="setting_wpr">
                                                                        <div class="form_grp" v-if="prog.settings && prog.settings.goals && (prog.is_circumference || prog.is_macros || prog.is_vitamins || prog.is_minerals || prog.is_supplements || prog.is_workout || prog.is_zones || prog.is_blood_pressure || prog.is_sleep || prog.is_pain || prog.is_push_up || prog.is_pull_up)">
                                                                            <div class="group_item" v-if="prog.goal_type">
                                                                                <label class="input_label">Goal Type</label>
                                                                                <div class="field_wpr m-0">
                                                                                    <multiselect v-model="prog.goal_type" :options="goalOptions"></multiselect>
                                                                                </div>
                                                                            </div>
                                                                            <div class="w-100" v-if="prog.is_push_up || prog.is_pull_up || prog.is_circumference">
                                                                                <div class="w-100" v-for="(measurement, g) of prog.settings.measurements" :key="g">
                                                                                <h3 class="sub_header mb-0 capitalize" v-if="measurement.title !='rest' && measurement.enabled">Goal for {{ measurement.title.replaceAll('_', ' ') }}</h3>
                                                                                    <div class="form_grp col-3 pt-2" v-if="measurement.title !='rest' && measurement.enabled " >
                                                                                        <div class="group_item" v-if="prog.goal_type == 'Range'">
                                                                                            <label class="input_label">Low Limit</label>
                                                                                            <div class="field_wpr m-0" :class="{ 'has-error': errors[`goal_low_limit_${prog.id}_${g}`] }">
                                                                                                <Field autocomplete="off" type="number" :name="`goal_low_limit_${prog.id}_${g}`" v-model="prog.settings.goals[g].low_limit" :class="{ 'has-error': errors[`goal_low_limit_${prog.id}_${g}`] }" />
                                                                                                <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ (prog.unit == 'ratings' || prog.unit == 'Ratings') ? 'Stars' :  prog.unit }}</span>
                                                                                            </div>
                                                                                            <ErrorMessage :name="`goal_low_limit_${prog.id}_${g}`" class="text-danger" />
                                                                                        </div>
                                                                                        <div class="group_item" v-if="prog.goal_type == 'Range'">
                                                                                            <label class="input_label">High Limit</label>
                                                                                            <div class="field_wpr m-0" :class="{ 'has-error': errors[`goal_high_limit_${prog.id}_${g}`] }">
                                                                                                <Field autocomplete="off" type="number" :name="`goal_high_limit_${prog.id}_${g}`" v-model="prog.settings.goals[g].high_limit" :class="{ 'has-error': errors[`goal_high_limit_${prog.id}_${g}`] }" />
                                                                                                <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ (prog.unit == 'ratings' || prog.unit == 'Ratings') ? 'Stars' :  prog.unit }}</span>
                                                                                            </div>
                                                                                            <ErrorMessage :name="`goal_high_limit_${prog.id}_${g}`" class="text-danger" />
                                                                                        </div>
                                                                                        <div class="group_item" v-if="prog.goal_type == 'Target'">
                                                                                            <label class="input_label">Target Type</label>
                                                                                            <div class="field_wpr m-0">
                                                                                                <multiselect v-model="prog.settings.goals[g].target_type" :options="goalTargetOptions"></multiselect>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="group_item" v-if="prog.goal_type == 'Target'">
                                                                                            <label class="input_label">Target Value</label>
                                                                                            <div class="field_wpr m-0" :class="{ 'has-error': errors[`goal_target_${prog.id}_${g}`] }">
                                                                                                <Field autocomplete="off" type="number" :name="`goal_target_${prog.id}_${g}`" v-model="prog.settings.goals[g].target" :class="{ 'has-error': errors[`goal_target_${prog.id}_${g}`] }" />
                                                                                                <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ prog.unit }}</span>
                                                                                            </div>
                                                                                            <ErrorMessage :name="`goal_target_${prog.id}_${g}`" class="text-danger" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div v-else class="w-100" v-for="(goal, g) of prog.settings.goals" :key="g">
                                                                                <h3 class="sub_header mb-0 capitalize">Goal for {{ goal.title.replaceAll('_', ' ') }}</h3>
                                                                                <div class="form_grp col-3 pt-2">
                                                                                    <div class="group_item" v-if="prog.goal_type == 'Range'">
                                                                                        <label class="input_label">Low Limit</label>
                                                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors[`goal_low_limit_${prog.id}_${g}`] }">
                                                                                            <Field autocomplete="off" type="number" :name="`goal_low_limit_${prog.id}_${g}`" v-model="prog.settings.goals[g].low_limit" :class="{ 'has-error': errors[`goal_low_limit_${prog.id}_${g}`] }" />
                                                                                            <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ (prog.unit == 'ratings' || prog.unit == 'Ratings') ? 'Stars' :  prog.unit }}</span>
                                                                                        </div>
                                                                                        <ErrorMessage :name="`goal_low_limit_${prog.id}_${g}`" class="text-danger" />
                                                                                    </div>
                                                                                    <div class="group_item" v-if="prog.goal_type == 'Range'">
                                                                                        <label class="input_label">High Limit</label>
                                                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors[`goal_high_limit_${prog.id}_${g}`] }">
                                                                                            <Field autocomplete="off" type="number" :name="`goal_high_limit_${prog.id}_${g}`" v-model="prog.settings.goals[g].high_limit" :class="{ 'has-error': errors[`goal_high_limit_${prog.id}_${g}`] }" />
                                                                                            <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ (prog.unit == 'ratings' || prog.unit == 'Ratings') ? 'Stars' :  prog.unit }}</span>
                                                                                        </div>
                                                                                        <ErrorMessage :name="`goal_high_limit_${prog.id}_${g}`" class="text-danger" />
                                                                                    </div>
                                                                                    <div class="group_item" v-if="prog.goal_type == 'Target'">
                                                                                        <label class="input_label">Target Type</label>
                                                                                        <div class="field_wpr m-0">
                                                                                            <multiselect v-model="prog.settings.goals[g].target_type" :options="goalTargetOptions"></multiselect>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="group_item" v-if="prog.goal_type == 'Target'">
                                                                                        <label class="input_label">Target Value</label>
                                                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors[`goal_target_${prog.id}_${g}`] }">
                                                                                            <Field autocomplete="off" type="number" :name="`goal_target_${prog.id}_${g}`" v-model="prog.settings.goals[g].target" :class="{ 'has-error': errors[`goal_target_${prog.id}_${g}`] }" />
                                                                                            <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ prog.unit }}</span>
                                                                                        </div>
                                                                                        <ErrorMessage :name="`goal_target_${prog.id}_${g}`" class="text-danger" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form_grp col-3" v-else>
                                                                            <div class="group_item">
                                                                                <label class="input_label">Goal Type</label>
                                                                                <div class="field_wpr m-0">
                                                                                    <multiselect v-model="prog.goal_type" :options="goalOptions"></multiselect>
                                                                                </div>
                                                                            </div>
                                                                            <div class="group_item" v-if="prog.goal_type == 'Range'">
                                                                                <label class="input_label">Low Limit</label>
                                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors[`goal_low_limit_${prog.id}`] }">
                                                                                    <Field autocomplete="off" type="number" :name="`goal_low_limit_${prog.id}`" v-model="prog.goal_low_limit" :class="{ 'has-error': errors[`goal_low_limit_${prog.id}`] }" :min="0" :max="prog.unit == '%' ? 100 : (prog.unit == 'ratings' ? 10 : prog.max_limit)" @keyup="handleNumberFieldMaxType($event, prog, 'goal_low_limit')" @keydown="handleNumberFieldMaxType($event, prog, 'goal_low_limit')" />
                                                                                    <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ (prog.unit == 'ratings' || prog.unit == 'Ratings') ? 'Stars' :  prog.unit }}</span>
                                                                                </div>
                                                                                <ErrorMessage :name="`goal_low_limit_${prog.id}`" class="text-danger" />
                                                                            </div>
                                                                            <div class="group_item" v-if="prog.goal_type == 'Range'">
                                                                                <label class="input_label">High Limit</label>
                                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors[`goal_high_limit_${prog.id}`] }">
                                                                                    <Field v-if="prog.name == 'Steps' || prog.name == 'Running Walking' || prog.name == 'Step Count'" autocomplete="off" type="number" :name="`goal_high_limit_${prog.id}`" v-model="prog.goal_high_limit" :class="{ 'has-error': errors[`goal_high_limit_${prog.id}`] }" :min="0"  @keyup="handleNumberFieldMaxType($event, prog, 'goal_high_limit')" @keydown="handleNumberFieldMaxType($event, prog, 'goal_high_limit')" />
                                                                                    <Field v-else autocomplete="off" type="number" :name="`goal_high_limit_${prog.id}`" v-model="prog.goal_high_limit" :class="{ 'has-error': errors[`goal_high_limit_${prog.id}`] }" :min="0" :max="prog.unit == '%' ? 100 : (prog.unit == 'ratings' ? 10 : prog.max_limit)" @keyup="handleNumberFieldMaxType($event, prog, 'goal_high_limit')" @keydown="handleNumberFieldMaxType($event, prog, 'goal_high_limit')" />
                                                                                    <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ (prog.unit == 'ratings' || prog.unit == 'Ratings') ? 'Stars' :  prog.unit }}</span>
                                                                                </div>
                                                                                <ErrorMessage :name="`goal_high_limit_${prog.id}`" class="text-danger" />
                                                                            </div>
                                                                            <div class="group_item" v-if="prog.goal_type == 'Target'">
                                                                                <label class="input_label">Target Type</label>
                                                                                <div class="field_wpr m-0">
                                                                                    <multiselect v-model="prog.goal_target_type" :options="goalTargetOptions"></multiselect>
                                                                                </div>
                                                                            </div>
                                                                            <div class="group_item" v-if="prog.goal_type == 'Target'">
                                                                                <label class="input_label">Target Value</label>
                                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors[`goal_target_${prog.id}`] }">
                                                                                    <Field autocomplete="off" type="number" :name="`goal_target_${prog.id}`" v-model="prog.goal_target" :class="{ 'has-error': errors[`goal_target_${prog.id}`] }" :min="0" :max="prog.unit == '%' ? 100 : (prog.unit == 'ratings' ? 10 : prog.max_limit)" @keyup="handleNumberFieldMaxType($event, prog, 'goal_target')" @keydown="handleNumberFieldMaxType($event, prog, 'goal_target')" />
                                                                                    <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ prog.unit }}</span>
                                                                                </div>
                                                                                <ErrorMessage :name="`goal_target_${prog.id}`" class="text-danger" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="action_wpr mb-0 mt-4">
                                                                <button type="button" :disabled="categoryProgressUpdateLoader" class="btn save_btn tracking-update" @click="updateCategoryProgress(prog)">
                                                                    <i class="fa fa-spin fa-spinner" v-if="categoryProgressUpdateLoader"></i> {{ categoryProgressUpdateLoader ? 'Updating' : 'Update' }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Transition>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </draggable>
                            </div>
                            <div class="section_content w-100" v-if="form.is_classic">
                                <div class="section_header">
                                    <h2>Custom Questions</h2>
                                    <span class="status" :style="`color: ${form.data_settings.has_custom_questions ? '#2f7eed' : '#999'};`">{{ form.data_settings.has_custom_questions ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="capsule_elm">
                                            <h5>Show</h5>
                                            <label for="custom_questions" class="switch_option capsule_btn border-0">
                                                <input type="checkbox" id="custom_questions" :true-value="1" :false-value="0" v-model="form.data_settings.has_custom_questions" @change="handleProgressAutoSave" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <Transition duration="550" name="nested">
                                            <div class="setting_wpr" v-if="form.data_settings.has_custom_questions">
                                                <button type="button" class="add_btn ml-auto mt-4 mr-0" @click="addSection()"><i class="fas fa-plus"></i>Add New Section</button>
                                                <draggable v-model="sections" tag="ul" item-key="id" handle=".handle-question-section-drag" :animation="200" class="custom_list mt-3" @end="handleSectionSort">
                                                    <template #item="{ element, index }">
                                                        <li class="move">
                                                            <h5 class="category_bar">
                                                                <div class="bar_text">
                                                                    <i class="fas fa-arrows-alt mr-2 handle-question-section-drag"></i>
                                                                    <input type="text" v-model="element.title" placeholder="ex: Client Tracking Name" @keydown="isQuestionTyping = true;" @keyup="questionElement = element;" @focusout="handleSectionAutosave(element);">
                                                                    <span class="save" @click="handleSectionAutosave(element);">Save</span>
                                                                </div>
                                                                <div class="bar_actions">
                                                                    <label :for="`show-section-${element.id}`" class="switch_option capsule_btn">
                                                                        <input type="checkbox" :id="`show-section-${element.id}`" :true-value="1" :false-value="0" v-model="element.enabled" @change="handleSectionAutosave(element)" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                    <button type="button" class="add_btn" @click="addEditSectionQuetion(element)"><i class="fas fa-plus mr-2"></i>Add Question</button>
                                                                    <button type="button" class="delete_btn" @click="deleteSectionPrompt(element, index)"><i class="fas fa-trash"></i></button>
                                                                </div>
                                                            </h5>
                                                            <Transition duration="550" name="nested">
                                                                <draggable :id="`question-${element.id}`" v-model="element.questions" tag="div" item-key="id" handle=".handle-question-drag" :animation="200" class="sub_categories mt-3 outer" @end="handleQuestionSort(element.questions)" v-if="element.enabled">
                                                                    <template #item="{ element, index }">
                                                                        <div class="prog_item">
                                                                            <h5 class="category_bar">
                                                                                <div class="bar_text">
                                                                                    <i class="fas fa-arrows-alt mr-2 handle-question-drag"></i>
                                                                                    <div class="quest_title" v-html="element.title"></div>
                                                                                </div>
                                                                                <div class="bar_actions">
                                                                                    <label class="pointer has" @click="addEditSectionQuetion(sections.filter(s => s.id == element.progress_question_section_id)[0], element)">
                                                                                        <i class="far fa-edit mr-1"></i> Edit
                                                                                    </label>
                                                                                    <button type="button" class="delete_btn" @click="deleteQuestionPrompt(element, index)"><i class="fas fa-trash"></i></button>
                                                                                </div>
                                                                            </h5>
                                                                            <div class="prog_edit">
                                                                                <div class="group_item">
                                                                                    <label class="input_label d-block" v-html="element.title"></label>
                                                                                    <div v-if="element.type == 'checkbox'">
                                                                                        <div class="question-options">
                                                                                            <label :for="`styled_radio1-${option.id}`" class="checkbox" v-for="(option, o) in element.options" :key="o">
                                                                                                <input type="checkbox" value="1" :id="`styled_radio1-${option.id}`" name="is_first" class="mr-2" hidden>
                                                                                                <span><i class="fas fa-check"></i></span>
                                                                                                <p>{{ option.title }}</p>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div v-if="element.type == 'radio'">
                                                                                        <div class="question-options">
                                                                                            <label :for="`styled_radio1-${option.id}`" class="radio_opt" v-for="(option, o) in element.options" :key="o">
                                                                                                <input type="radio" value="1" :id="`styled_radio1-${option.id}`" name="is_first" class="mr-2" hidden>
                                                                                                <div class="radio_box">
                                                                                                    <span class="dot"></span>
                                                                                                </div>
                                                                                                <p>{{ option.title }}</p>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div v-if="element.type == 'true-false'">
                                                                                        <div class="grp_wpr">
                                                                                            <label :for="`styled_radio1-${option.id}`" v-for="(option, o) in element.options" :key="o" :class="option.title === 'True' ? 'yes' : 'no'">
                                                                                                <input type="radio" value="1" :id="`styled_radio1-${option.id}`" name="is_first" class="mr-2" hidden>
                                                                                                <div class="box_item"><i :class="`fas fa-thumbs-${option.title == 'True' ? 'up' : 'down'} mr-2`"></i>{{option.title == 'True' ? 'Yes' : 'No'}}</div>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div v-if="element.type == 'text'" class="field_wpr">
                                                                                        <input type="text">
                                                                                    </div>
                                                                                    <div v-if="element.type == 'file'">
                                                                                        <image-upload image-type="all" upload-text="Image" v-model="dummyFile[element.id]"  :is-avatar="false" />
                                                                                    </div>
                                                                                    <div v-if="element.type == 'textarea'" class="field_wpr">
                                                                                        <textarea cols="30" rows="10"></textarea>
                                                                                    </div>
                                                                                    <div v-if="element.type == 'assessment'">
                                                                                        <div v-if="element.unit == 'BP' || element.unit == 'bp'">
                                                                                            <div class="field_wpr has_suffix">
                                                                                                <input type="text" placeholder="080/120">
                                                                                                <span class="suffix">{{ element.unit }}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div v-else-if="['Pain Index', 'Points', 'Rating', 'Stars'].includes(element.unit)">
                                                                                            <div class="field_wpr has_suffix">
                                                                                                <input type="number" name="number" min="1" max="10" step="1">
                                                                                                <span class="suffix">{{ element.unit }}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div v-else class="field_wpr has_suffix">
                                                                                            <input type="number" name="number">
                                                                                            <span class="suffix">{{ element.unit }}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </draggable>
                                                                <div class="sub_categories" v-else>
                                                                    <h6>No questions found</h6>
                                                                </div>
                                                            </Transition>
                                                        </li>
                                                    </template>
                                                </draggable>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Client Pics</h2>
                                    <span class="status" :style="`color: ${form.data_settings.has_progress_pics ? '#2f7eed' : '#999'};`">{{ form.data_settings.has_progress_pics ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container">
                                        <div class="section_title m-0">Show
                                            <label for="progress_pic" class="switch_option capsule_btn p-0 ml-auto">
                                                <input type="checkbox" id="progress_pic" :true-value="1" :false-value="0" v-model="form.data_settings.has_progress_pics" @change="handleProgressAutoSave" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <Transition duration="550" name="nested">
                                            <div class="setting_wpr" v-if="form.data_settings.has_progress_pics">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Photo Orientation</label>
                                                        <div class="field_wpr">
                                                            <multiselect
                                                                v-model="form.data_settings.photo_orientation"
                                                                :options="['Portrait', 'Landscape']"
                                                            ></multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h3 class="sub_header m-0 pt-1">Views</h3>
                                                <ul class="circumference_list">
                                                    <li>
                                                        <div class="sub_scope_area">
                                                            <div class="capsule_elm">
                                                                <h5>Front</h5>
                                                                <label for="orientation-front" class="switch_option capsule_btn p-0">
                                                                    <input type="checkbox" id="orientation-front" v-model="form.data_settings.photo_view.front" @change="handleProgressAutoSave" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="sub_scope_area">
                                                            <div class="capsule_elm">
                                                                <h5>Side</h5>
                                                                <label for="orientation-side" class="switch_option capsule_btn p-0">
                                                                    <input type="checkbox" id="orientation-side" v-model="form.data_settings.photo_view.side" @change="handleProgressAutoSave" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="sub_scope_area">
                                                            <div class="capsule_elm">
                                                                <h5>Back</h5>
                                                                <label for="orientation-back" class="switch_option capsule_btn p-0">
                                                                    <input type="checkbox" id="orientation-back" v-model="form.data_settings.photo_view.back" @change="handleProgressAutoSave" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="progressStep == 3">
                            <div class="section_content w-100 open_area">
                                <div class="section_header">
                                    <h2>Public Page</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp p-0 mt-1 mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Public Url</label>
                                                <div class="field_wpr has_suffix">
                                                    <Field autocomplete="off" type="text" v-model="form.url" name="url" placeholder="https://superfitcoaching.com" readonly />
                                                    <span class="suffix pointer" @click="handleCopy(form.url)">Copy</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="capsule_elm pt-2">
                                            <h5>Apply Saved Styling?</h5>
                                            <label for="apply_saved_styling" class="switch_option capsule_btn border-0">
                                                <input type="checkbox" id="apply_saved_styling" :true-value="1" :false-value="0" v-model="form.apply_saved_styling" @change="handleApplySavedStylingWarning()" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" ref="hdrsetting" @click="goTo('hdrsection')">
                                <div class="section_header">
                                    <h2>Header</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_header ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_header ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="section_title m-0">
                                            <div class="mr-auto">Show</div>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="display_header" class="switch_option capsule_btn">
                                                <input type="checkbox" id="display_header" :true-value="1" :false-value="0" v-model="form.public_settings.display_header" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <Transition duration="550" name="nested">
                                            <div class="content_details mt-4">
                                                <div class="setting_wpr outer">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Company Name <a class="reset-default" @click="form.public_settings.company_branding = defaultForm.public_settings.company_branding">Reset to default</a></label>
                                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.company_branding }">
                                                                <Field autocomplete="off" type="text" name="company_branding" v-model="form.public_settings.company_branding" placeholder="Company Name" />
                                                            </div>
                                                            <ErrorMessage name="company_branding" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Header Background Color <a class="reset-default" @click="form.public_settings.header_bgcolor = defaultForm.public_settings.header_bgcolor">Reset to default</a></label>
                                                            <Field autocomplete="off" name="header_bgcolor" type="text" label="header background color">
                                                                <color-picker v-model="form.public_settings.header_bgcolor"/>
                                                            </Field>
                                                            <ErrorMessage name="header_bgcolor" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Header Text Color <a class="reset-default" @click="form.public_settings.header_textcolor = defaultForm.public_settings.header_textcolor">Reset to default</a></label>
                                                            <Field autocomplete="off" name="header_textcolor" type="text" label="header text color">
                                                                <color-picker v-model="form.public_settings.header_textcolor"/>
                                                            </Field>
                                                            <ErrorMessage name="header_textcolor" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <ul class="tab_sec mt-2">
                                                        <li @click="headerTab = 'logo'" :class="{ active: headerTab === 'logo' }">Header Logo</li>
                                                        <li @click="headerTab = 'author'" :class="{ active: headerTab === 'author' }">Header Author Image</li>
                                                    </ul>
                                                    <div class="setting_wpr">
                                                        <div v-if="headerTab === 'logo'">
                                                            <div class="capsule_elm">
                                                                <h5>Display Logo</h5>
                                                                <label for="show_logo" class="switch_option capsule_btn py-3">
                                                                    <input type="checkbox" id="show_logo" :true-value="1" :false-value="0" v-model="form.public_settings.display_logo" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                            <div v-if="form.public_settings.display_logo">
                                                                <image-library image-type="public-logo" upload-text="Logo" v-model="form.public_settings.logo" />
                                                            </div>
                                                        </div>
                                                        <div v-if="headerTab === 'author'">
                                                            <div class="capsule_elm">
                                                                <h5>Display Author</h5>
                                                                <label for="coach_img" class="switch_option capsule_btn py-3">
                                                                    <input type="checkbox" id="coach_img" :true-value="1" :false-value="0" v-model="form.public_settings.display_author" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                            <div v-if="form.public_settings.display_author">
                                                                <image-library image-type="avatar" :is-avatar="true" upload-text="Image" v-model="form.public_settings.author" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" ref="cvrsetting"  @click="goTo('cvrsection')">
                                <div class="section_header">
                                    <h2>Cover</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_cover ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_cover ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="section_title m-0">
                                            <div class="mr-auto">Show <label v-if="!form.public_settings.small_cover">Upload a small screen cover for a better view</label></div>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="cover" class="switch_option capsule_btn">
                                                <input type="checkbox" id="cover" :true-value="1" :false-value="0" v-model="form.public_settings.display_cover" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <Transition duration="550" name="nested">
                                            <div class="content_details">
                                                <div class="cover_type">
                                                    <ul class="type_list">
                                                        <li>
                                                            <label for="clr_text">
                                                                <input type="radio" id="clr_text" :value="1" v-model="form.public_settings.cover_type" hidden>
                                                                <img src="@/assets/images/background.svg" alt="">
                                                                <h5>Color + Text</h5>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="img_text">
                                                                <input type="radio" id="img_text" :value="2" v-model="form.public_settings.cover_type" hidden>
                                                                <img src="@/assets/images/picture.svg" alt="">
                                                                <h5>Image + Text</h5>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="img_only">
                                                                <input type="radio" id="img_only" :value="3" v-model="form.public_settings.cover_type" hidden>
                                                                <img src="@/assets/images/picture.svg" alt="">
                                                                <h5>Image Only</h5>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="setting_wpr" v-if="form.public_settings.cover_type !== 3">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Headline
                                                                <div class="flex-center">
                                                                    <button type="button" class="reset-default" @click="form.public_settings.headline = defaultForm.public_settings.headline">Reset to default</button>
                                                                    <button type="button" class="px-1" @click="headlineSetting = !headlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                                </div>
                                                            </label>
                                                            <div class="field_wpr" :class="{ 'has-error': errors.headline }">
                                                                <Field autocomplete="off" type="text" name="headline" v-model="form.public_settings.headline" placeholder="Compelling Headline Goes Here" />
                                                            </div>
                                                            <ErrorMessage name="headline" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div v-if="headlineSetting">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Family <a class="reset-default" @click="form.headline_setting.font_family = defaultForm.headline_setting.font_family">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.headline_setting.font_family" v-bind="fontFamily"></multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Weight <a class="reset-default" @click="form.headline_setting.font_weight = defaultForm.headline_setting.font_weight">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.headline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Size <a class="reset-default" @click="form.headline_setting.font_size = defaultForm.headline_setting.font_size">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="nofill" type="number" name="headline_setting_font_size" v-model="form.headline_setting.font_size" min="0" max="100" placeholder="90" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Color <a class="reset-default" @click="form.headline_setting.font_color = defaultForm.headline_setting.font_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="headline_setting_cover_textcolor" v-model="form.headline_setting.font_color" type="text" label="element color">
                                                                    <color-picker v-model="form.headline_setting.font_color" :classes="{ 'has-error': errors.headline_setting_cover_textcolor }" />
                                                                </Field>
                                                                <ErrorMessage name="headline_setting_cover_textcolor" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="mt-4">
                                                            <div class="capsule_elm">
                                                                <h5>Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                                <label for="headline_shadow" class="switch_option capsule_btn">
                                                                    <input type="checkbox" id="headline_shadow" :true-value="1" :false-value="0" v-model="form.headline_setting.has_shadow" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                            <div v-if="form.headline_setting.has_shadow">
                                                                <div class="form_grp">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Offset-x <a class="reset-default" @click="form.headline_setting.shadow_x = defaultForm.headline_setting.shadow_x">Reset to default</a></label>
                                                                        <div class="field_wpr">
                                                                            <Field autocomplete="nofill" type="number" name="headline_setting_shadow_x" v-model="form.headline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="group_item">
                                                                        <label class="input_label">Offset-y <a class="reset-default" @click="form.headline_setting.shadow_y = defaultForm.headline_setting.shadow_y">Reset to default</a></label>
                                                                        <div class="field_wpr ">
                                                                            <Field autocomplete="nofill" type="number" name="headline_setting_shadow_y" v-model="form.headline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-3">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Blur-Radius <a class="reset-default" @click="form.public_settings.shadow_blur = defaultForm.public_settings.shadow_blur">Reset to default</a></label>
                                                                        <div class="field_wpr">
                                                                            <Field autocomplete="nofill" type="number" name="headline_setting_shadow_blur" v-model="form.headline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-2">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Shadow Color <a class="reset-default" @click="form.public_settings.shadow_color = defaultForm.public_settings.shadow_color">Reset to default</a></label>
                                                                        <Field autocomplete="off" name="headline_setting_shadow_color" v-model="form.headline_setting.shadow_color" type="text" label="element color">
                                                                            <color-picker v-model="form.headline_setting.shadow_color" :classes="{ 'has-error': errors.headline_setting_shadow_color }" />
                                                                        </Field>
                                                                        <ErrorMessage name="headline_setting_shadow_color" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr" v-if="form.public_settings.cover_type !== 3">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Sub Headline
                                                                <div class="flex-center">
                                                                    <button type="button" class="reset-default" @click="form.public_settings.subheadline = defaultForm.public_settings.subheadline">Reset to default</button>
                                                                    <button type="button" class="px-1" @click="subheadlineSetting = !subheadlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                                </div>
                                                            </label>
                                                            <div class="field_wpr" :class="{ 'has-error': errors.sub_headline }">
                                                                <Field autocomplete="off" type="text" name="sub_headline" v-model="form.public_settings.subheadline" placeholder="This can be edited or changed to a cover image by clicking here" />
                                                            </div>
                                                            <ErrorMessage name="sub_headline" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div v-if="subheadlineSetting">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Family <a class="reset-default" @click="form.subheadline_setting.font_family = defaultForm.subheadline_setting.font_family">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.subheadline_setting.font_family" v-bind="fontFamily"></multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Weight <a class="reset-default" @click="form.subheadline_setting.font_weight = defaultForm.subheadline_setting.font_weight">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.subheadline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Size <a class="reset-default" @click="form.subheadline_setting.font_size = defaultForm.subheadline_setting.font_size">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="nofill" type="number" name="subheadline_setting_font_size" v-model="form.subheadline_setting.font_size" min="0" max="100" placeholder="90" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Color <a class="reset-default" @click="form.subheadline_setting.font_color = defaultForm.subheadline_setting.font_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="subheadline_setting_font_color" v-model="form.subheadline_setting.font_color" type="text" label="element color">
                                                                    <color-picker v-model="form.subheadline_setting.font_color" :classes="{ 'has-error': errors.subheadline_setting_font_color }" />
                                                                </Field>
                                                                <ErrorMessage name="subheadline_setting_font_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="mt-4">
                                                            <div class="capsule_elm">
                                                                <h5>Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                                <label for="subheadline_shadow" class="switch_option capsule_btn">
                                                                    <input type="checkbox" id="subheadline_shadow" :true-value="1" :false-value="0" v-model="form.subheadline_setting.has_shadow" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                            <div v-if="form.subheadline_setting.has_shadow">
                                                                <div class="form_grp">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Offset-x <a class="reset-default" @click="form.subheadline_setting.shadow_x = defaultForm.subheadline_setting.shadow_x">Reset to default</a></label>
                                                                        <div class="field_wpr">
                                                                            <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_x" v-model="form.subheadline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="group_item">
                                                                        <label class="input_label">Offset-y <a class="reset-default" @click="form.subheadline_setting.shadow_y = defaultForm.subheadline_setting.shadow_y">Reset to default</a></label>
                                                                        <div class="field_wpr ">
                                                                            <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_y" v-model="form.subheadline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-3">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Blur-Radius <a class="reset-default" @click="form.subheadline_setting.shadow_blur = defaultForm.subheadline_setting.shadow_blur">Reset to default</a></label>
                                                                        <div class="field_wpr">
                                                                            <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_blur" v-model="form.subheadline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-2">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Shadow Color <a class="reset-default" @click="form.subheadline_setting.shadow_color = defaultForm.subheadline_setting.shadow_color">Reset to default</a></label>
                                                                        <Field autocomplete="off" name="subheadline_setting_shadow_color" v-model="form.subheadline_setting.shadow_color" type="text" label="element color">
                                                                            <color-picker v-model="form.subheadline_setting.shadow_color" :classes="{ 'has-error': errors.subheadline_setting_shadow_color }" />
                                                                        </Field>
                                                                        <ErrorMessage name="subheadline_setting_shadow_color" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr mt-2" v-if="form.public_settings.cover_type !== 1">
                                                    <h3 class="sub_header mt-1">Cover Image</h3>
                                                    <image-library image-type="public-cover" upload-text="Cover" v-model="form.public_settings.cover" />
                                                    <div class="border-bottom">
                                                        <div class="capsule_elm">
                                                            <h5>Small Screen Image</h5>
                                                            <label for="small_image" class="switch_option capsule_btn">
                                                                <input type="checkbox" id="small_image" :true-value="1" :false-value="0" v-model="form.public_settings.has_small_cover" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <image-library v-if="form.public_settings.has_small_cover" image-type="public-cover-small" upload-text="Cover" v-model="form.public_settings.small_cover" />
                                                    </div>
                                                    <div class="capsule_elm">
                                                        <h5>Add Image Overlay</h5>
                                                        <label for="overlay" class="switch_option capsule_btn">
                                                            <input type="checkbox" id="overlay" :true-value="1" :false-value="0" v-model="form.public_settings.has_cover_overlay" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <div v-if="form.public_settings.has_cover_overlay" class="setting_wpr mt-2">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Overlay Color <a class="reset-default" @click="form.public_settings.overlay_color = defaultForm.public_settings.overlay_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="overlay_color" v-model="form.public_settings.overlay_color" type="text" label="element color">
                                                                    <color-picker v-model="form.public_settings.overlay_color" />
                                                                </Field>
                                                                <ErrorMessage name="overlay_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Overlay opacity <a class="reset-default" @click="form.public_settings.overlay_opacity = defaultForm.public_settings.overlay_opacity">Reset to default</a></label>
                                                                <div class="field_wpr" :class="{ 'has-error': errors.overlay_opacity }">
                                                                    <Field autocomplete="off" type="number" name="overlay_opacity" v-model="form.public_settings.overlay_opacity" min="0" max="100" rules="min:0|max:100" placeholder="68" />
                                                                </div>
                                                                <ErrorMessage name="overlay_opacity" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr" v-if="form.public_settings.cover_type === 1">
                                                    <div class="group_item">
                                                        <label class="input_label">Cover Background <a class="reset-default" @click="form.public_settings.cover_color = defaultForm.public_settings.cover_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="cover_background_color" v-model="form.public_settings.cover_color" type="text" label="element color">
                                                            <color-picker v-model="form.public_settings.cover_color" />
                                                        </Field>
                                                        <ErrorMessage name="cover_background_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header"  @click="goTo('expvidsection')">
                                    <h2>Page Explainer Video</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_explainer_video ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_explainer_video ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="section_title m-0">
                                            <div class="mr-auto">Show</div>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="explain_video" class="switch_option capsule_btn">
                                                <input type="checkbox" id="explain_video" :true-value="1" :false-value="0" v-model="form.public_settings.display_explainer_video" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <Transition duration="550" name="nested">
                                            <div class="content_details mt-4">
                                                <div class="setting_wpr">
                                                    <div class="border-bottom pb-2">
                                                        <div class="capsule_elm">
                                                            <h5>Video Link (youtube, vimeo, wistia, screencast-o-matic, loom)</h5>
                                                            <label for="video_url" class="switch_option capsule_btn border-0">
                                                                <input type="radio" id="video_url" value="0" v-model="form.public_settings.explainer_video_type" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div class="setting_wpr mb-2" v-if="form.public_settings.explainer_video_type == 0">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.explainer_video }">
                                                                        <Field autocomplete="off" type="text" name="explainer_video" v-model="form.public_settings.explainer_video" placeholder="Video Link Goes Here..." rules="url" label="explainer video" />
                                                                        <span class="prefix">URL</span>
                                                                    </div>
                                                                    <ErrorMessage name="explainer_video" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="py-2">
                                                        <div class="capsule_elm">
                                                            <h5>Embeded Code (youtube, vimeo, wistia, screencast-o-matic, loom)</h5>
                                                            <label for="embed_video" class="switch_option capsule_btn border-0">
                                                                <input type="radio" id="embed_video" value="1" v-model="form.public_settings.explainer_video_type" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div class="setting_wpr" v-if="form.public_settings.explainer_video_type == 1">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.explainer_embed }">
                                                                        <Field autocomplete="off" type="text" name="explainer_embed" v-model="form.public_settings.explainer_embed" label="explainer embed code" :class="{ 'has-error': errors.explainer_embed }">
                                                                            <textarea cols="30" rows="10" v-model="form.public_settings.explainer_embed" placeholder="Embed code goes here.."></textarea>
                                                                        </Field>
                                                                    </div>
                                                                    <ErrorMessage name="explainer_embed" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Submit Button</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div ref="subbtnsetting"  @click="goTo('subbtnsection')">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Submit Button Text <a class="reset-default" @click="form.public_settings.submit_btn_text = defaultForm.public_settings.submit_btn_text">Reset to default</a></label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.name }">
                                                            <Field autocomplete="off" type="text" name="name" v-model="form.public_settings.submit_btn_text" placeholder="ex: Submit Client" rules="required" />
                                                        </div>
                                                        <ErrorMessage name="name" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Submit Button Color <a class="reset-default" @click="form.public_settings.submit_btn_bgcolor = defaultForm.public_settings.submit_btn_bgcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="button_bg_color" type="text" label="button background color">
                                                            <color-picker v-model="form.public_settings.submit_btn_bgcolor" />
                                                        </Field>
                                                        <ErrorMessage name="button_bg_color" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Submit Text Color <a class="reset-default" @click="form.public_settings.submit_btn_textcolor = defaultForm.public_settings.submit_btn_textcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="button_text_color" type="text" label="button text color">
                                                            <color-picker v-model="form.public_settings.submit_btn_textcolor" />
                                                        </Field>
                                                        <ErrorMessage name="button_text_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" ref="thankyousetting" @click="goTo('thankyousection')">
                                <div class="section_header">
                                    <h2>Thank you</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Background Color <a class="reset-default" @click="form.public_settings.thankyou_bgcolor = defaultForm.public_settings.thankyou_bgcolor">Reset to default</a></label>
                                                    <Field autocomplete="off" name="thankyou_bgcolor" v-model="form.public_settings.thankyou_bgcolor" type="text" label="element color">
                                                        <color-picker v-model="form.public_settings.thankyou_bgcolor" :classes="{ 'has-error': errors.thankyou_bgcolor }" />
                                                    </Field>
                                                    <ErrorMessage name="thankyou_bgcolor" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Text Color <a class="reset-default" @click="form.public_settings.thankyou_textcolor = defaultForm.public_settings.thankyou_textcolor">Reset to default</a></label>
                                                    <Field autocomplete="off" name="thankyou_textcolor" v-model="form.public_settings.thankyou_textcolor" type="text" label="element color">
                                                        <color-picker v-model="form.public_settings.thankyou_textcolor" :classes="{ 'has-error': errors.thankyou_textcolor }" />
                                                    </Field>
                                                    <ErrorMessage name="thankyou_textcolor" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" ref="ftrsetting">
                                <div class="section_header">
                                    <h2>Footer</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_footer ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_footer ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1 mb-2">
                                        <div class="section_title m-0">
                                            <div class="mr-auto">Show</div>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="has-login-footer" class="switch_option capsule_btn">
                                                <input type="checkbox" id="has-login-footer" :true-value="1" :false-value="0" v-model="form.public_settings.display_footer" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <p class="saved-style-text pt-1" @click="handleApplySavedStyle('footer')">Apply saved footer styling</p>
                                        <Transition duration="550" name="nested">
                                            <div class="content_details mt-5">
                                                <div class="capsule_elm">
                                                    <h5>Facebook</h5>
                                                    <label for="facebook" class="switch_option capsule_btn p-0 border-0">
                                                        <input type="checkbox" id="facebook" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_facebook" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_facebook">
                                                    <div class="form_grp pt-2">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_facebook" v-model="form.public_settings.footer_facebook" placeholder="Facebook link here" rules="url|validate_url:facebook" label="facebook" />
                                                            </div>
                                                            <ErrorMessage name="footer_facebook" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="capsule_elm pt-3">
                                                    <h5>Twitter</h5>
                                                    <label for="twitter" class="switch_option capsule_btn p-0 border-0">
                                                        <input type="checkbox" id="twitter" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_twitter" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_twitter">
                                                    <div class="form_grp pt-2">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_twitter" v-model="form.public_settings.footer_twitter" placeholder="Twitter link here" rules="url|validate_url:twitter" label="twitter" />
                                                            </div>
                                                            <ErrorMessage name="footer_twitter" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="capsule_elm pt-3">
                                                    <h5>Instagram</h5>
                                                    <label for="instagram" class="switch_option capsule_btn p-0 border-0">
                                                        <input type="checkbox" id="instagram" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_instagram" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_instagram">
                                                    <div class="form_grp pt-2">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_instagram" v-model="form.public_settings.footer_instagram" placeholder="Instagram link here" rules="url|validate_url:instagram" label="instagram" />
                                                            </div>
                                                            <ErrorMessage name="footer_instagram" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                  <div class="capsule_elm pt-3">
                                                    <h5>Linkedin</h5>
                                                    <label for="linkedin" class="switch_option capsule_btn p-0 border-0">
                                                        <input type="checkbox" id="linkedin" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_linkedin" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_linkedin">
                                                    <div class="form_grp pt-2">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_linkedin" v-model="form.public_settings.footer_linkedin" placeholder=" link here" rules="url|validate_url:linkedin" label="linkedin" />
                                                            </div>
                                                            <ErrorMessage name="footer_linkedin" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                  <div class="capsule_elm pt-3">
                                                    <h5>Youtube</h5>
                                                    <label for="youtube" class="switch_option capsule_btn p-0 border-0">
                                                        <input type="checkbox" id="youtube" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_youtube" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_youtube">
                                                    <div class="form_grp pt-2">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_youtube" v-model="form.public_settings.footer_youtube" placeholder="Youtube link here" rules="url|validate_url:youtu" label="youtube" />
                                                            </div>
                                                            <ErrorMessage name="footer_youtube" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Icons Color <a class="reset-default" @click="form.public_settings.footer_textcolor = defaultForm.public_settings.footer_textcolor">Reset to default</a></label>
                                                            <Field autocomplete="off" name="footer_textcolor" v-model="form.public_settings.footer_textcolor" type="text" label="element color">
                                                                <color-picker v-model="form.public_settings.footer_textcolor"/>
                                                            </Field>
                                                            <ErrorMessage name="footer_textcolor" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Background Color <a class="reset-default" @click="form.public_settings.footer_bgcolor = defaultForm.public_settings.footer_bgcolor">Reset to default</a></label>
                                                            <Field autocomplete="off" name="footer_bgcolor" v-model="form.public_settings.footer_bgcolor" type="text" label="element color">
                                                                <color-picker v-model="form.public_settings.footer_bgcolor"/>
                                                            </Field>
                                                            <ErrorMessage name="footer_bgcolor" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Copyright Text</label>
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_company" v-model="form.public_settings.footer_company" :placeholder="`© ${new Date().getFullYear()} - ${user.businessname ? user.businessname : 'Company Name'}`" />
                                                            </div>
                                                            <ErrorMessage name="footer_company" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Terms Link</label>
                                                            <div class="field_wpr has_prefix m-0">
                                                                <Field autocomplete="off" type="text" name="footer_terms" v-model="form.public_settings.footer_terms" placeholder="https://superfitcoaching.com" rules="url" label="term link" />
                                                                <span class="prefix">URL</span>
                                                            </div>
                                                            <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                            <ErrorMessage name="footer_terms" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Privacy Link</label>
                                                            <div class="field_wpr has_prefix m-0">
                                                                <Field autocomplete="off" type="text" name="footer_policy" v-model="form.public_settings.footer_policy" placeholder="https://superfitcoaching.com" rules="url" label="privacy link" />
                                                                <span class="prefix">URL</span>
                                                            </div>
                                                            <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                            <ErrorMessage name="footer_policy" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="progressStep == 4">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Notifications</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <swiper :slidesPerView="'auto'" :spaceBetween="30" :navigation='true' class="tab_slider">
                                            <swiper-slide><span @click="reminderTab = 'reminders'" :class="{ active: reminderTab === 'reminders' }">Client Reminders <label class="tag" :style="`background: #${form.reminder.has_reminder ? '2f7eed' : 'f2a31d'}`">{{ form.reminder.has_reminder ? 'On' : 'Off' }}</label></span></swiper-slide>
                                            <swiper-slide><span @click="reminderTab = 'admin'" :class="{ active: reminderTab === 'admin' }">Admin Notiication <label class="tag" :style="`background: #${form.reminder.has_notification ? '2f7eed' : 'f2a31d'}`">{{ form.reminder.has_notification ? 'On' : 'Off' }}</label></span></swiper-slide>
                                            <swiper-slide><span @click="reminderTab = 'award'" :class="{ active: reminderTab === 'award' }">Award Points <label class="tag" :style="`background: #${form.data_settings.rewards.has_reward_points ? '2f7eed' : 'f2a31d'}`">{{ form.data_settings.rewards.has_reward_points ? 'On' : 'Off' }}</label></span></swiper-slide>
                                        </swiper>
                                        <p class="notification-warning" v-if="form.reminder && (!form.reminder.has_reminder || !form.reminder.has_notification)">Some of your notifications and reminders are set to OFF and this could affect client tracking and scoring performance. You can adjust them here.</p>
                                        <Transition duration="550" name="nested">
                                            <div class="outer" v-if="reminderTab === 'reminders'">
                                                <div class="capsule_elm mt-2 mb-4">
                                                    <h5>Reminders</h5>
                                                    <label for="track_reminders" class="switch_option capsule_btn mt-2 mb-4">
                                                        <input type="checkbox" id="track_reminders" :true-value="1" :false-value="0" v-model="form.reminder.has_reminder" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <Transition duration="550" name="nested">
                                                    <div v-if="form.reminder.has_reminder" class="mt-2 outer">
                                                        <ul class="tab_sec mb-4">
                                                            <li @click="clientReminderTab = 'goal'" :class="{ active: clientReminderTab === 'goal' }">Delivery Message</li>
                                                            <li @click="clientReminderTab = 'client'" :class="{ active: clientReminderTab === 'client' }">Client Reminders</li>
                                                        </ul>
                                                        <div v-if="clientReminderTab === 'goal'">
                                                            <div class="edit_section border mb-2">
                                                                <sending-method v-model="form.reminder.goal_sending_method" />
                                                            </div>
                                                        </div>
                                                        <div v-if="clientReminderTab === 'client'">
                                                            <div class="edit_section border mb-2">
                                                                <sending-method v-model="form.reminder.sending_method" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Transition>
                                            </div>
                                        </Transition>
                                        <Transition duration="550" name="nested">
                                            <div class="outer" v-if="reminderTab === 'admin'">
                                                <div class="capsule_elm mt-2">
                                                    <h5>Admin Notification</h5>
                                                    <label for="adm_notification" class="switch_option capsule_btn mt-2">
                                                        <input type="checkbox" id="adm_notification" :true-value="1" :false-value="0" v-model="form.reminder.has_notification" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <Transition duration="550" name="nested">
                                                    <div v-if="form.reminder.has_notification" class="mt-2 outer">
                                                        <div class="edit_section border mb-2">
                                                            <sending-method v-model="form.reminder.notification_sending_method" />
                                                        </div>
                                                    </div>
                                                </Transition>
                                            </div>
                                        </Transition>
                                        <Transition duration="550" name="nested">
                                            <div class="outer" v-if="reminderTab === 'award'">
                                                <div class="capsule_elm mt-2">
                                                    <h5>Award Points</h5>
                                                    <label for="award_point" class="switch_option capsule_btn mt-2">
                                                        <input type="checkbox" id="award_point" :true-value="1" :false-value="0" v-model="form.data_settings.rewards.has_reward_points" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <Transition duration="550" name="nested">
                                                    <div v-if="form.data_settings.rewards.has_reward_points">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Points Awarded</label>
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="off" type="number" name="award_point" v-model.number="form.data_settings.rewards.reward_points" placeholder="1" @keyup="form.data_settings.rewards.reward_points = Number(form.data_settings.rewards.reward_points).toString();" label="award point" :class="{ 'has-error': errors.award_point }" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="capsule_elm mt-2">
                                                            <h5>Subscriber Reward Notifications?</h5>
                                                            <label for="reward_notify" class="switch_option capsule_btn m-0">
                                                                <input type="checkbox" id="reward_notify" v-model="form.data_settings.rewards.reward_notif" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div v-if="form.data_settings.rewards.reward_notif">
                                                            <div class="edit_section">
                                                                <sending-method v-model="form.data_settings.rewards.reward_notif_method" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mt-2">
                                                            <div class="group_item">
                                                                <label class="input_label">You just earned {{ form.data_settings.rewards.reward_points }} points for</label>
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="off" type="text" name="award_reason" v-model="form.data_settings.rewards.reward_reason" placeholder="Type here..." label="award reason" :class="{ 'has-error': errors.award_reason }" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Transition>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                                <div class="setting_wpr mt-3" v-if="reminderTab === 'reminders' && clientReminderTab === 'goal'">
                                    <div v-if="form.reminder.goal_sending_method == 1 || form.reminder.goal_sending_method == 3">
                                        <email-component v-model="goalEmail" :errors="errors" :has-email-button="true" :handle-default-email="handleGoalDefaultEmail" preview-module="progress-tracking" ref="goal-email-component" is-full-view />
                                    </div>
                                    <div v-if="form.reminder.goal_sending_method == 2 || form.reminder.goal_sending_method == 3" class="sms-component">
                                        <sms-component v-model="form.reminder.goal_sms_message" media-field-name="goal_sms_media" :media="form.reminder.goal_media" :default-sms-handler="true" preview-module="progress-tracking" :handle-default-sms="handleGoalDefaultSms" :update-sms-text="updateSmsText()" ref="goal-sms-component" />
                                    </div>
                                </div>
                                <div class="setting_wpr mt-3" v-if="reminderTab === 'reminders' && clientReminderTab === 'client'">
                                    <div v-if="form.reminder.sending_method == 1 || form.reminder.sending_method == 3">
                                        <email-component v-model="reminderEmail" :errors="errors" :has-email-button="true" :handle-default-email="handleReminderDefaultEmail" preview-module="progress-tracking" ref="reminder-email-component" is-full-view />
                                    </div>
                                    <div v-if="form.reminder.sending_method == 2 || form.reminder.sending_method == 3" class="sms-component">
                                        <sms-component v-model="form.reminder.sms_message" media-field-name="reminder_sms_media" :media="form.reminder.media" :default-sms-handler="true" preview-module="progress-tracking" :handle-default-sms="handleReminderDefaultSms" :update-sms-text="updateSmsText()" ref="message-sms-component" />
                                    </div>
                                </div>
                                <template v-if="reminderTab === 'admin'">
                                    <div class="color_container mt-3" v-if="form.reminder.notification_sending_method == 1 || form.reminder.notification_sending_method == 3">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Email Recipient</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.notification_email_recipient }">
                                                        <Field autocomplete="off" name="notification_email_recipient" v-model="form.reminder.notification_email_recipient" rules="required" label="email recipient">
                                                            <vue-tags-input :tags="form.reminder.notification_email_recipient" @on-tags-changed="handleNotificationEmailRecipient" />
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="notification_email_recipient" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="setting_wpr mt-3" v-if="form.reminder.notification_sending_method == 1 || form.reminder.notification_sending_method == 3">
                                        <email-component v-model="notificationEmail" :errors="errors" :has-email-button="true" :handle-default-email="handleNotificationDefaultEmail" preview-module="progress-tracking" ref="notification-email-component" is-full-view />
                                    </div> 
                                    <div class="color_container mt-3" v-if="form.reminder.notification_sending_method == 2 || form.reminder.notification_sending_method == 3">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">SMS Recipient</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.notification_sms_recipient }">
                                                        <Field autocomplete="off" name="notification_sms_recipient" v-model="form.reminder.notification_sms_recipient" rules="required" label="sms recipient">
                                                            <vue-tags-input :tags="form.reminder.notification_sms_recipient" @on-tags-changed="handleNotificationSMSRecipient" />
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="notification_sms_recipient" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="setting_wpr mt-3" v-if="form.reminder.notification_sending_method == 2 || form.reminder.notification_sending_method == 3">
                                        <sms-component v-model="form.reminder.notification_sms_message" media-field-name="notification_sms_media" :media="form.reminder.notification_media" :default-sms-handler="true" preview-module="progress-tracking" :handle-default-sms="handleNotificationDefaultSms" :update-sms-text="updateSmsText()" ref="notification-sms-component" />
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="progressStep == 5">
                            <div class="section_content w-100 open_area" @click="goTo('seosection')" ref="seosetting">
                                <div class="section_header">
                                    <h2>SEO Settings</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Title</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_title }">
                                                    <Field autocomplete="off" type="text" name="seo_title" placeholder="ex: The title will be displayed in browser tabs." v-model="form.public_settings.seo_title" />
                                                </div>
                                                <ErrorMessage name="seo_title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Keywords</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_keyword }">
                                                    <Field autocomplete="off" type="text" name="seo_keyword" placeholder="ex: Several keywords that describe your page best." v-model="form.public_settings.seo_keyword" />
                                                </div>
                                                <ErrorMessage name="seo_keyword" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.seo_desc }">
                                                    <Field autocomplete="off" type="textarea" name="seo_desc" v-model="form.seo_desc">
                                                        <textarea cols="10" rows="10" placeholder="Description Goes Here..." v-model="form.public_settings.seo_desc"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="seo_desc" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="capsule_elm">
                                        <h5>I want my page indexed by search engine</h5>
                                        <label for="page_indexed" class="switch_option capsule_btn border-0">
                                            <input type="checkbox" id="page_indexed" :true-value="1" :false-value="0" v-model="form.public_settings.seo_index" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100 open_area" @click="goTo('sharesection')" ref="sharesetting">
                                <div class="section_header">
                                    <h2>Social Share</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Title</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_share_title }">
                                                    <Field autocomplete="off" type="text" name="seo_share_title" placeholder="ex: New Title" v-model="form.public_settings.seo_share_title" />
                                                </div>
                                                <ErrorMessage name="seo_share_title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.seo_share_desc }">
                                                    <Field autocomplete="off" type="textarea" name="seo_share_desc" v-model="form.public_settings.seo_share_desc">
                                                        <textarea cols="10" rows="10" placeholder="Description Goes Here..." v-model="form.public_settings.seo_share_desc"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="seo_share_desc" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="sub_header mt-4">SEO Thumbnail</h3>
                                    <image-library v-model="form.public_settings.seo_thumb" image-type="social-share" />
                                </div>
                            </div>
                            <div class="section_content w-100 open_area" @click="goTo('favsection')" ref="favsetting">
                                <div class="section_header">
                                    <h2>Favicon</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <image-library v-model="form.public_settings.seo_fav" image-type="favicon" />
                                </div>
                            </div>
                        </div>
                        <button type="button" class="preview_btn" @click="preview = true">Preview</button>
                    </div>
                    <div class="action_wpr">
                        <button type="button" class="btn cancel_btn" @click="closeModal()" v-if="progressStep == 1">Close</button>
                        <button type="button" class="btn cancel_btn" @click="progressStep -= 1" v-if="progressStep > 1">Back</button>
                        <button type="button" class="btn danger_btn" v-if="progressTracking.id" @click="handleDeleteProgressTracking([progressTracking.id])">Delete</button>
                        <button class="btn save_btn" id="progress-save-button" v-if="progressStep < 5"><i class="fa fa-spinner fa-spin" v-if="progressTrackingUpdateLoader"></i> {{ progressTrackingUpdateLoader ? 'Saving' : 'Next' }}</button>
                        <button class="btn save_btn" id="progress-save-button" v-if="progressStep == 5"><i class="fa fa-spinner fa-spin" v-if="progressTrackingUpdateLoader"></i> {{ progressTrackingUpdateLoader ? (isNew ? 'Saving' : 'Updating') : (isNew ? 'Finish' : 'Update') }}</button>
                    </div>
                </Form>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="progressStep == 1">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <div class="cell">
                    <div class="content_area">
                        <div class="px-3 mt-4">
                            <v-calendar :first-day-of-week="2" :attributes="calendarAttributes" is-expanded @update:from-page="resetCalendar" v-if="form.recurring_type != 1">
                                <template #day-popover="{ dayTitle }">
                                    <div class="task_details">
                                        <div class="task_header">
                                            {{ dayTitle }}
                                        </div>
                                    </div>
                                </template>
                            </v-calendar>
                            <div class="hourly_info" v-if="form.recurring_type == 1">
                                Your client will be reminded every {{ form.recurring_settings.hours }} hours that they need to submit their progress {{ recurringEndDate ? 'until' : '' }} {{ recurringEndDate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="progressStep == 2 || progressStep == 3">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="full_preview" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="large">Full View</h5>
                            <input type="checkbox" id="full_preview" v-model="fullPreview" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <ul class="alt_prev">
                    <li @click="previewPage = 'progress'" :class="{'active' : previewPage == 'progress'}">Progress</li>
                    <li @click="previewPage = 'thankyou'" :class="{'active' : previewPage == 'thankyou'}">Thank You</li>
                </ul>
                <div class="cell">
                    <template v-if="form.is_classic">
                        <div class="content_area tracker_container" v-show="previewPage == 'progress'">
                            <preview-content v-show="previewPage == 'progress'" :progress-tracking="form" ref="preview-content" :is-new="isNew" />
                        </div>
                    </template>
                    <template v-else>
                        <div class="content_area tracker_container" v-show="previewPage == 'progress'">
                            <preview-content-advanced v-show="previewPage == 'progress'" :progress-tracking="form" ref="preview-content" :is-new="isNew" />
                        </div>
                    </template>
                    <div class="content_area " v-show="previewPage == 'thankyou'">
                        <thankyou-preview-content :progress-tracking="form" ref="preview-content" :is-new="isNew" />
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="progressStep == 4">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_notification" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_notification" v-model="previewReminder" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <ul class="alt_prev">
                    <li @click="reminderPreviewType = 'email'" :class="{'active' : reminderPreviewType == 'email'}" v-if="reminderTab == 'reminders' && clientReminderTab == 'goal' && (form.reminder.goal_sending_method == 1 || form.reminder.goal_sending_method == 3)">E-mail</li>
                    <li @click="reminderPreviewType = 'sms'" :class="{'active' : reminderPreviewType == 'sms'}" v-if="reminderTab == 'reminders' && clientReminderTab == 'goal' && (form.reminder.goal_sending_method == 2 || form.reminder.goal_sending_method == 3)">SMS</li>
                    <li @click="reminderPreviewType = 'email'" :class="{'active' : reminderPreviewType == 'email'}" v-if="reminderTab == 'reminders' && clientReminderTab == 'client' && (form.reminder.sending_method == 1 || form.reminder.sending_method == 3)">E-mail</li>
                    <li @click="reminderPreviewType = 'sms'" :class="{'active' : reminderPreviewType == 'sms'}" v-if="reminderTab == 'reminders' && clientReminderTab == 'client' && (form.reminder.sending_method == 2 || form.reminder.sending_method == 3)">SMS</li>
                    <li @click="reminderPreviewType = 'email'" :class="{'active' : reminderPreviewType == 'email'}" v-if="reminderTab == 'admin' && (form.reminder.notification_sending_method == 1 || form.reminder.notification_sending_method == 3)">E-mail</li>
                    <li @click="reminderPreviewType = 'sms'" :class="{'active' : reminderPreviewType == 'sms'}" v-if="reminderTab == 'admin' && (form.reminder.notification_sending_method == 2 || form.reminder.notification_sending_method == 3)">SMS</li>
                </ul>
                <div class="cell">
                    <div class="content_area" v-if="reminderPreviewType == 'email'">
                        <div class="msg_preview" v-if="reminderTab == 'reminders' && clientReminderTab == 'goal'">
                            <h2>{{ replaceVariables(goalEmail.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="goalEmail.show_email_logo">
                                <img :src="goalEmail.email_logo ? goalEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(goalEmail.email, goalEmail.email_button_bg_color, goalEmail.email_button_text_color, 'View Request')"></div>
                            <div v-html="generateSignature(goalEmail.is_signature, goalEmail.signature_id)"></div>
                        </div>
                        <div class="msg_preview" v-if="reminderTab == 'reminders' && clientReminderTab == 'client'">
                            <h2>{{ replaceVariables(reminderEmail.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="reminderEmail.show_email_logo">
                                <img :src="reminderEmail.email_logo ? reminderEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(reminderEmail.email, reminderEmail.email_button_bg_color, reminderEmail.email_button_text_color, 'Go To Tracker')"></div>
                            <div v-html="generateSignature(reminderEmail.is_signature, reminderEmail.signature_id)"></div>
                        </div>
                        <div class="msg_preview" v-if="reminderTab == 'admin'">
                            <h2>{{ replaceVariables(notificationEmail.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="notificationEmail.show_email_logo">
                                <img :src="notificationEmail.email_logo ? notificationEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(notificationEmail.email, notificationEmail.email_button_bg_color, notificationEmail.email_button_text_color, 'View Response')"></div>
                            <div v-html="generateSignature(notificationEmail.is_signature, notificationEmail.signature_id)"></div>
                        </div>
                        <div class="msg_preview" v-if="reminderTab == 'award'">
                            <h2>{{ replaceVariables(rewardDetail.awarded_subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="rewardDetail.reward_show_email_logo">
                                <img :src="rewardDetail.reward_email_logo ? rewardDetail.reward_email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(rewardDetail.awarded_email, rewardDetail.email_button_bg_color, rewardDetail.email_button_text_color, 'Go To Tracker')"></div>
                            <div v-html="generateSignature(rewardDetail.is_reward_signature, rewardDetail.reward_signature_id)"></div>
                        </div>
                        <div class="msgFooter text-center mt-2 mb-2">
                            <p>
                                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                    <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                    <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                </template>
                                <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                            </p>
                            <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                            <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                        </div>
                    </div>
                    <div class="content_area" v-if="reminderPreviewType == 'sms'">
                        <div class="sms_preview">
                            <div v-if="(reminderTab == 'reminders' && clientReminderTab == 'goal') || reminderTab == 'award'" class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(goalSmsText, '', '', '', '', false)"></div>
                            <div v-if="reminderTab == 'reminders' && clientReminderTab == 'client'" class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(messageSmsText, '', '', '', '', false)"></div>
                            <div v-if="reminderTab == 'admin'" class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(notificationSmsText, '', '', '', '', false)"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="progressStep == 5">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <div class="area_wpr">
                    <h3 class="sub_header" ref="seosection" @click="setting('seosetting')">Search Engine Listing</h3>
                    <div class="google_ranking">
                        <h6>About 5,29,000 results(0.36 seconds)</h6>
                        <h4>{{ form.public_settings.seo_title ? replaceVariables(form.public_settings.seo_title) : 'Lorem Ipsum Dolor' }}</h4>
                        <p>{{ form.public_settings.seo_desc ? form.public_settings.seo_desc : 'It takes whole chocolate protein. The members of the fleet are turpis at the ends of the pulvinar. No vehicles are accepted at the bed.' }}</p>
                    </div>
                </div>
                <div class="area_wpr" ref="sharesection" @click="setting('sharesetting')">
                    <h3 class="sub_header">Social Share</h3>
                    <div class="seo_card">
                        <img :src="form.public_settings.seo_thumb ? form.public_settings.seo_thumb : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                        <div class="seo_info">
                            <h3>{{ form.public_settings.seo_share_title ? replaceVariables(form.public_settings.seo_share_title) : 'Optimize Your Metabolism In 30 Days' }}</h3>
                            <p>{{ form.public_settings.seo_share_desc ? form.public_settings.seo_share_desc : 'It is easy to speed upa slow metabolism in as little as 30 days using this proven technique.' }}</p>
                        </div>
                    </div>
                </div>
                <div class="area_wpr" ref="favsection" @click="setting('favsetting')">
                    <h3 class="sub_header">Favicon</h3>
                    <div class="browser_layout">
                        <ul class="browser_tabs">
                            <li>
                                <img :src="form.public_settings.seo_fav ? form.public_settings.seo_fav : (env.VUE_APP_NAME ? require('@/assets/images/'+env.VUE_APP_NAME+'/logo.svg') : require('@/assets/images/logo.png'))" alt="">
                                <h6>{{ form.public_settings.seo_title ? (form.public_settings.seo_title.length > 15 ? replaceVariables(form.public_settings.seo_title).substr(0, 15)+'...' : form.public_settings.seo_title) : 'Thrive Coach' }}</h6>
                                <i class="fas fa-times"></i>
                            </li>
                            <li><i class="fas fa-plus"></i></li>
                        </ul>
                        <div class="browser_info">
                            <i class="fas fa-arrow-left"></i>
                            <i class="fas fa-arrow-right"></i>
                            <i class="fas fa-redo"></i>
                            <div class="browser_search">
                                <i class="fas fa-lock"></i>
                                <p>https://thrivecoach.io</p>
                                <i class="far fa-star ml-auto"></i>
                            </div>
                            <img src="@/assets/images/default-avatar.svg" class="user" alt=""/>
                            <i class="fas fa-ellipsis-v"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <preview-progress-tracking v-model="fullPreview" :progress-tracking="form" :preview-page="previewPage" />
        <custom-question v-model="customQuestionPanel" :section-id="customQuestionSectionId" :question="selectedCustomQuestion" />
        <reminder-preview v-model="previewReminder" :reminder-tab="reminderTab" :client-reminder-tab="clientReminderTab" :form="form" :goal-email="goalEmail" :reminder-email="reminderEmail"  :notification-email="notificationEmail" />
    </div>
</template>

<script>
    import { copyText } from 'vue3-clipboard'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
    import SwiperCore, { Navigation } from 'swiper'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import { defineAsyncComponent } from 'vue'

    const ImageUpload = defineAsyncComponent(() => import('@/components/image-library/ImageUpload'))
    const PreviewContent = defineAsyncComponent(() => import('@/pages/progress-tracking/components/PreviewContent'))
    const PreviewContentAdvanced = defineAsyncComponent(() => import('@/pages/progress-tracking/components/PreviewContentAdvanced'))
    const ThankyouPreviewContent = defineAsyncComponent(() => import('@/pages/progress-tracking/components/ThankyouPreviewContent'))
    const CustomQuestion = defineAsyncComponent(() => import('@/pages/progress-tracking/components/CustomQuestion'))
    const PreviewProgressTracking = defineAsyncComponent(() => import('@/pages/progress-tracking/components/PreviewProgressTracking'))
    const ReminderPreview = defineAsyncComponent(() => import('@/pages/progress-tracking/components/ReminderPreview'))

    import moment from 'moment'
    import Swal from 'sweetalert2'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import Draggable from 'vuedraggable'
    import VueTagsInput from 'vue3-tags-input'
    import 'swiper/swiper-bundle.min.css'

    SwiperCore.use([Navigation])

    export default {
        name: 'Progress Tracking Wizard',

        data () {
            return  {
                progressStep: 1,
                isNew: false,
                toggleAll: 1,
                editProgressName: false,
                newCategoryName: '',
                form: {
                    name: "",
                    is_classic: 1,
                    has_goal: 1,
                    access_code: "",
                    url: "",
                    recurring_type: 1,
                    collection_type: 1,
                    recurring_settings: {
                        hours: 1,
                        day: 1,
                        days: 1,
                        weeks: 1,
                        months: 1,
                        week: 1,
                        month: 1,
                        years: 1,
                        end_type: 1,
                        end_occurrences: 1,
                        end_date: '',
                        week_name: 'mon',
                        weekdays: {
                            mon: false,
                            tue: false,
                            wed: false,
                            thu: false,
                            fri: false,
                            sat: false,
                            sun: true,
                        },
                        repeat: 1,
                        send_before: 5,
                        send_before_type: 3,
                        send_at: 10,
                        send_at_type: 'AM',
                        time_zone: this.user ? this.user.timezone : '',
                        day_type: 'd',
                    },
                    reminder: {
                        has_reminder: 1,
                        sending_method: 1,
                        throttle_for_user_tz: 1,
                        recipient_email:'',
                        recipient_mobile:'',
                        email_subject: '',
                        email_message: '',
                        sms_message: '',
                        media: [],
                        show_email_logo: true,
                        show_email_signature: false,
                        signature_id: 0,
                        logo_image: '',
                        goal_sending_method: 1,
                        goal_email_subject: '',
                        goal_email_message: '',
                        goal_sms_message: '',
                        show_goal_email_logo: true,
                        goal_logo_image: '',
                        goal_media: [],
                        has_notification: 0,
                        notification_sending_method: 1,
                        notification_email_subject: '',
                        notification_email_message: '',
                        notification_sms_message: '',
                        show_notification_email_logo: true,
                        notification_logo_image: '',
                        notification_media: [],
                        notification_email_recipient: [this.user ? this.user.email : ''],
                        notification_sms_recipient: [this.user ? this.user.sms_notification_number : ''],
                        show_goal_signature: 0,
                        goal_signature_id: 0,
                        show_signature: 0,
                        show_notification_signature: 0,
                        notification_signature_id: 0,
                        goal_email_button_bg_color: '',
                        goal_email_button_text_color: '',
                        email_button_bg_color: '',
                        email_button_text_color: '',
                        notification_email_button_bg_color: '',
                        notification_email_button_text_color: '',
                    },
                    data_settings: {
                        has_weight: 1,
                        weight_unit: 'Pounds',
                        max_weight: 500,
                        has_body_fat: 1,
                        max_percent:100,
                        has_circumference: 0,
                        c_unit:'Inches',
                        has_progress_pics: 1,
                        photo_orientation:'Portrait',
                        photo_view:{
                            front: true,
                            back:true,
                            side: true
                        },
                        total_submission: 6,
                        limited_submission: 0,
                        reverse_goal: 1,
                        week_enabled: 1,
                        has_progress_question_label: 1,
                        progress_question_label: 'Progress Questions',
                        rewards: {
                            has_reward_points: 0,
                            reward_points: 0,
                            reward_notif: 0,
                            reward_notif_method: 1,
                            reward_reason: 'submitting your progress report',
                        },
                        neck: 1,
                        shoulders: 1,
                        chest: 1,
                        waist: 1,
                        biceps: 0,
                        belly_button: 0,
                        hips: 0,
                        thigh: 0,
                        calf: 0,
                        bg_color: '#fff4e2',
                        text_color: '#121525',
                        body_weight_title: 'Weight',
                        body_weight_sub_text: 'What\'s your weight today?',
                        body_fat_title: 'Body Fat',
                        body_fat_sub_text: 'What\'s your body fat percentage today?',
                        body_circumference_title: 'Circumference',
                        body_circumference_sub_text: 'What are your circumference measurements today?',
                    },
                    public_settings: {
                        display_explainer_video: 0,
                        explainer_video_type: 0,
                        explainer_video: "",
                        explainer_embed: "",
                        display_company_branding: 1,
                        company_branding: "",
                        display_cover: 1,
                        headline: 'Time To Submit Your Progress',
                        subheadline: '',
                        display_logo:1,
                        logo: '',
                        display_author:1,
                        author:"",
                        display_header:1,
                        display_footer:1,
                        header_bgcolor: '#FFF',
                        header_textcolor: '#000',
                        submit_btn_text: 'Submit Progress',
                        element_color: '#2c3e50',
                        element_text_color: '#2C3E50',
                        submit_btn_textcolor: '#fff',
                        submit_btn_bgcolor: '#2c3e50',
                        footer_bgcolor: '#D4D4D4',
                        footer_textcolor: '#2C3E50',
                        cover_color: '#1976d2',
                        cover_textcolor: '#2C3E50',
                        cover: '',
                        small_cover: '',
                        footer_has_facebook: 1,
                        footer_has_twitter: 1,
                        footer_has_instagram: 1,
                        footer_has_linkedin: 0,
                        footer_has_youtube: 0,
                        thankyou_bgcolor: '#edebeb',
                        thankyou_textcolor: '#2c3e50',
                    },
                    headline_setting: {},
                    subheadline_setting: {},
                    description:'',
                    apply_saved_styling: 0,
                },
                months: {
                    1: 'Jan',
                    2: 'Feb',
                    3: 'Mar',
                    4: 'Apr',
                    5: 'May',
                    6: 'Jun',
                    7: 'Jul',
                    8: 'Aug',
                    9: 'Sep',
                    10: 'Oct',
                    11: 'Nov',
                    12: 'Dec'
                },
                reminderOption: [{
                        reminder_value: '',
                        reminder_type: 'on time',
                    },
                    {
                        reminder_value: 5,
                        reminder_type: 'minutes',
                    },
                    {
                        reminder_value: 10,
                        reminder_type: 'minutes',
                    },
                    {
                        reminder_value: 20,
                        reminder_type: 'minutes',
                    },
                    {
                        reminder_value: 30,
                        reminder_type: 'minutes',
                    },
                    {
                        reminder_value: 1,
                        reminder_type: 'hour',
                    },
                    {
                        reminder_value: 2,
                        reminder_type: 'hours',
                    },
                    {
                        reminder_value: 5,
                        reminder_type: 'hours',
                    },
                    {
                        reminder_value: 10,
                        reminder_type: 'hours',
                    },
                    {
                        reminder_value: '',
                        reminder_type: 'custom',
                    }
                ],
                hours: {
                    mode: 'single',
                    value: [7],
                    options: [
                        { value: 1, label: '1' },
                        { value: 2, label: '2' },
                        { value: 3, label: '3' },
                        { value: 4, label: '4' },
                        { value: 5, label: '5' },
                        { value: 6, label: '6' },
                        { value: 7, label: '7' },
                        { value: 8, label: '8' },
                        { value: 9, label: '9' },
                        { value: 10, label: '10' },
                        { value: 11, label: '11' },
                        { value: 12, label: '12' },
                    ],
                    createTag: true
                },
                clock: {
                    mode: 'single',
                    value: ['AM'],
                    options: [
                        { value: 'AM', label: 'AM' },
                        { value: 'PM', label: 'PM' }
                    ],
                    createTag: true
                },
                intervalTimeone: {},
                weekdays: [
                    'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'
                ],
                env: {},
                reminderTab: 'reminders',
                clientReminderTab: 'goal',
                preview: true,
                goalEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                reminderEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                notificationEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                calendarAttributes: [],
                recurringEndDate: '',
                reminderPreviewType: 'email',
                previewReminder: false,
                headlineSetting: false,
                subheadlineSetting: false,
                headerTab: 'logo',
                fullPreview: false,
                customQuestionPanel: false,
                customQuestionSectionId: 0,
                selectedCustomQuestion: {},
                sections: [],
                goalSmsText: '',
                messageSmsText: '',
                notificationSmsText: '',
                goal_sms_media: [],
                reminder_sms_media: [],
                notification_sms_media: [],
                questionElement: {},
                isQuestionTyping: false,
                previewPage: 'progress',
                switchStep: true,
                dummyFile: [],
                lastStep: 0,
                goalOptions: [
                    { value: 'Range', label: 'Range' },
                    { value: 'Target', label: 'Specific Target' }
                ],
                goalTargetOptions: [
                    { value: 'Greater', label: 'Greater Than' },
                    { value: 'Less', label: 'Less Than' },
                    { value: 'Equal', label: 'Equal To' },
                ],
                customVariableName: '',
                showDescription: false,
                moment,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            VueTagsInput,
            PreviewContent,
            PreviewContentAdvanced,
            PreviewProgressTracking,
            Draggable,
            CustomQuestion,
            ImageUpload,
            ReminderPreview,
            ThankyouPreviewContent,
            Swiper,
            SwiperSlide,
        },

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.previewPage    = 'progress';
                    vm.activeProgress = 0;
                    vm.switchStep     = true;
                    vm.resetForm();
                    vm.getQuestionSections({ progress_tracking_id: vm.progressTracking.id });

                    setTimeout(function () {
                        vm.resetCalendar();
                    }, 1000);

                    if (vm.rewardDetail && !Object.keys(vm.rewardDetail).length) {
                        vm.getReward();
                    }
                }
            },

            progressTracking (progress) {
                if (progress) {
                    const vm = this;

                    vm.resetForm(false);

                    vm.addVarsToReservedWords({ name: 'progress_scenario', value: progress.name });
                    vm.addVarsToReservedWords({ name: 'tracking_type', value: progress.tracking_type });
                }
            },

            questionSections (sections) {
                const vm = this;

                vm.sections = JSON.parse(JSON.stringify(sections));
            },

            'form.recurring_type' (type) {
                const vm = this;

                vm.resetCalendar();
            },

            'form.recurring_settings.weekdays': {
                handler (days) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.end_type': {
                handler (type) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.end_occurrences': {
                handler (day) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.end_date': {
                handler (date) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.days': {
                handler (days) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.day_type': {
                handler (type) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.weeks': {
                handler (week) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.send_at': {
                handler (hour) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.send_at_type': {
                handler (meridiem) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.data_settings.total_submission': {
                handler (val) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.data_settings.limited_submission': {
                handler (val) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.day': {
                handler (val) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.week': {
                handler (val) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.week_name': {
                handler (val) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.month': {
                handler (val) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.year': {
                handler (val) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.repeat': {
                handler (val) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            fullPreview (val) {
                const vm = this;

                if (val) {
                    document.body.classList.add('modal-open');

                    const button = document.getElementById('progress-save-button');

                    if (button) {
                        vm.switchStep   = false;
                        vm.closeWindow  = false;
                        button.click();
                    }
                }

                vm.toggleGistChatBox();
            },

            'form.reminder.goal_sending_method': {
                handler (val) {
                    const vm = this;

                    vm.reminderTab         = 'reminders';
                    vm.clientReminderTab   = 'goal';
                    vm.reminderPreviewType = val == 2 ? 'sms' : 'email';
                },
                deep: true,
            },

            'form.reminder.sending_method': {
                handler (val) {
                    const vm = this;

                    vm.reminderTab         = 'reminders';
                    vm.clientReminderTab   = 'client';
                    vm.reminderPreviewType = val == 2 ? 'sms' : 'email';
                },
                deep: true,
            },

            'form.reminder.notification_sending_method': {
                handler (val) {
                    const vm = this;

                    vm.reminderTab         = 'admin';
                    vm.reminderPreviewType = val == 2 ? 'sms' : 'email';
                },
                deep: true,
            },

            reminderTab (tab) {
                const vm = this;

                if (tab == 'reminders') {
                    if (vm.clientReminderTab == 'goal') {
                        vm.reminderPreviewType = vm.form.reminder.goal_sending_method == 2 ? 'sms' : 'email';
                        vm.goalSmsText         = vm.form.reminder && vm.form.reminder.goal_media ? vm.form.reminder.goal_sms_message + '\n\n' +vm.form.reminder.goal_media : vm.form.reminder.goal_sms_message;
                    } else if (vm.clientReminderTab == 'client') {
                        vm.reminderPreviewType = vm.form.reminder.sending_method == 2 ? 'sms' : 'email';
                        vm.messageSmsText      = vm.form.reminder && vm.form.reminder.media ? vm.form.reminder.sms_message + '\n\n' + vm.form.reminder.media : vm.form.reminder.goal_sms_message;
                    }
                } else if (tab == 'admin') {
                    vm.reminderPreviewType = vm.form.reminder.notification_sending_method == 2 ? 'sms' : 'email';
                    vm.notificationSmsText = vm.form.reminder && vm.form.reminder.notification_media ? vm.form.reminder.notification_sms_message + '\n\n' + vm.form.reminder.notification_media : vm.form.reminder.goal_sms_message;
                }
            },

            clientReminderTab (val) {
                const vm = this;

                if (val == 'goal') {
                    vm.reminderPreviewType = vm.form.reminder.goal_sending_method == 2 ? 'sms' : 'email';
                    vm.goalSmsText         = vm.form.reminder && vm.form.reminder.goal_media ? vm.form.reminder.goal_sms_message + '\n\n' +vm.form.reminder.goal_media : vm.form.reminder.goal_sms_message;
                } else if (val == 'client') {
                    vm.reminderPreviewType = vm.form.reminder.sending_method == 2 ? 'sms' : 'email';
                    vm.messageSmsText      = vm.form.reminder && vm.form.reminder.media ? vm.form.reminder.sms_message + '\n\n' + vm.form.reminder.media : vm.form.reminder.goal_sms_message;
                }
            },

            goal_sms_media (media) {
                const vm = this;

                vm.form.reminder.goal_media = media;
            },

            reminder_sms_media (media) {
                const vm = this;

                vm.form.reminder.media = media;
            },

            notification_sms_media (media) {
                const vm = this;

                vm.form.reminder.notification_media = media;
            },

            isQuestionTyping (val) {
                const vm = this;

                if (!vm.isQuestionTyping) {
                    vm.handleSectionSave();
                }
            },

            questionElement (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isQuestionTyping = false;
                }, 5000);
            },

            progressStep (val) {
                const vm = this;

                vm.toggleAll = 1;
            },

            'form.collection_type' (val) {
                const vm = this;

                if (val == 1) {
                    vm.form.recurring_settings.weeks = 1;
                }
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                membership: state => state.authModule.membership,
                timezones: state => state.commonModule.timezones,
                fontFamily: state => state.commonModule.fontFamily,
                fontWeight: state => state.commonModule.fontWeight,
                progressTracking: state => state.progressTrackingModule.selectedProgressTracking,
                progressTrackingUpdateLoader: state => state.progressTrackingModule.progressTrackingUpdateLoader,
                questionSections: state => state.progressTrackingModule.questionSections,
                rewardDetail: state => state.rewardModule.reward,
                defaultForm: state => state.progressTrackingModule.defaultForm,
                progressTrackingDefaultContent: state => state.commonModule.progressTrackingDefaultContent,
                categoryProgressSaveLoader: state => state.progressTrackingModule.categoryProgressSaveLoader,
                categoryProgressUpdateLoader: state => state.progressTrackingModule.categoryProgressUpdateLoader,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;

            vm.resetCalendar();

            if (!vm.timezones.length) {
                vm.getTimezones();
            }

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
            vm.env = process.env;

            window.addEventListener('resize', vm.resizeEventHandler);
            vm.resizeEventHandler();

            if (!Object.values(vm.progressTrackingDefaultContent).length) {
                vm.getDefaultContentByType(4);
            }
        },

        unmounted () {
            const vm = this;

            window.removeEventListener('resize', vm.resizeEventHandler);
        },

        methods: {
            ...mapActions({
                getTimezones: 'commonModule/getTimezones',
                saveProgressTracking: 'progressTrackingModule/saveProgressTracking',
                deleteProgressTracking: 'progressTrackingModule/deleteProgressTracking',
                getQuestionSections: 'progressTrackingModule/getQuestionSections',
                saveQuestionSection: 'progressTrackingModule/saveQuestionSection',
                deleteQuestionSection: 'progressTrackingModule/deleteQuestionSection',
                sortCustomQuestionSection: 'progressTrackingModule/sortCustomQuestionSection',
                sortCustomQuestions: 'progressTrackingModule/sortCustomQuestions',
                deleteCustomQuestion: 'progressTrackingModule/deleteCustomQuestion',
                getReward: 'rewardModule/getReward',
                getDefaultContentByType: 'commonModule/getDefaultContentByType',
                createCategoryCustomProgress: 'progressTrackingModule/createCategoryCustomProgress',
                updateCategoryProgress: 'progressTrackingModule/updateCategoryProgress',
                updateProgressCategory: 'progressTrackingModule/updateProgressCategory',
                deleteCustomTracking: 'progressTrackingModule/deleteCustomTracking',
            }),

            ...mapMutations({
                addVarsToReservedWords: 'commonModule/ADD_VARIABLE_TO_RESERVED_WORDS',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);

                if (vm.$parent.$parent && vm.$parent.$parent.getProgressTrackings) {
                    vm.$parent.$parent.getProgressTrackings(vm.$parent.$parent.params);
                } else {
                    vm.$parent.getProgressTrackings(vm.$parent.params);
                }
            },

            toggleStep (step) {
                const vm = this;

                if (vm.isNew) {
                    if (step <= vm.lastStep) {
                        vm.progressStep = step;
                    }
                } else {
                    vm.progressStep = step;
                }
            },

            resetCalendar (calendarMonth = null) {
                const vm            = this;
                const date          = new Date();
                let todayDate       = new Date();
                let weekdays        = [1, 2, 3, 4, 5, 6, 7];
                let dailyInterval   = 1;
                let weeklyInterval  = 1;
                let monthlyInterval = 1;
                let yearlyInterval  = 1;
                let days            = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
                let endDate         = '';
                let startDate       = new Date();
                let sendTimeHour    = '';

                //Start date based on timezone
                if (vm.form.recurring_settings.send_at_type ==  'PM') {
                    sendTimeHour = parseInt(vm.form.recurring_settings.send_at) + 12;
                } else {
                    sendTimeHour = parseInt(vm.form.recurring_settings.send_at);
                }

                if (sendTimeHour < todayDate.getHours()) {
                    startDate.setDate(startDate.getDate() + 1);
                }

                //Occurances selected
                if (vm.form.recurring_settings.end_type == 2) {
                    let endOccurrences = vm.form.recurring_settings.end_occurrences;

                    endOccurrences = endOccurrences > 0 ? parseInt(endOccurrences) : 0;

                    if (vm.form.recurring_settings.day_type == 'd') {
                        dailyInterval = vm.form.recurring_settings.days ? vm.form.recurring_settings.days : 1;
                        endOccurrences = endOccurrences *  dailyInterval;
                        endOccurrences = parseInt(endOccurrences);
                    }

                    if (vm.form.recurring_type == 3) {
                        weeklyInterval = vm.form.recurring_settings.weeks ? vm.form.recurring_settings.weeks : 1;
                        let weekdayscount = weeklyInterval * 7;
                        endOccurrences = endOccurrences * weekdayscount;
                        endOccurrences = parseInt(endOccurrences);
                    }

                    if (sendTimeHour > todayDate.getHours()) {
                        endOccurrences = endOccurrences - 1;
                    }

                    if (vm.form.recurring_settings.day_type == 'w' && vm.form.recurring_type == 2) {
                        endDate = vm.calcWorkingDays(endOccurrences);
                    } else {
                        endDate = date.setDate(date.getDate() + endOccurrences);
                    }
                }

                // if limit selected
                if (vm.form.recurring_settings.end_type == 1 && vm.form.data_settings.limited_submission == 1) {
                    let submissionsLimit = vm.form.data_settings.total_submission;

                    submissionsLimit = submissionsLimit > 0 ? parseInt(submissionsLimit) : 0;

                    if (vm.form.recurring_settings.day_type == 'd') {
                        dailyInterval = vm.form.recurring_settings.days ? vm.form.recurring_settings.days : 1;
                        submissionsLimit = submissionsLimit *  dailyInterval;
                        submissionsLimit = parseInt(submissionsLimit);
                    }

                    if (vm.form.recurring_type == 3) {
                        weeklyInterval = vm.form.recurring_settings.weeks ? vm.form.recurring_settings.weeks : 1;
                        let weekdayscount = weeklyInterval * 7;
                        submissionsLimit = submissionsLimit * weekdayscount;
                        submissionsLimit = parseInt(submissionsLimit);
                    }

                    if (sendTimeHour > todayDate.getHours()) {
                        submissionsLimit = submissionsLimit - 1;
                    }

                    if (vm.form.recurring_settings.day_type == 'w' && vm.form.recurring_type == 2) {
                        endDate = vm.calcWorkingDays(submissionsLimit);
                    } else if (vm.form.recurring_type == 3) {
                        endDate = date.setDate(date.getDate() + submissionsLimit);
                    }
                } else if (vm.form.recurring_settings.end_type == 1 && vm.form.data_settings.limited_submission == 0) {
                    endDate = '';
                }

                //End On selected
                if (vm.form.recurring_settings.end_type == 3) {
                    endDate = vm.form.recurring_settings.end_date;

                    let CurrentDate = new Date();

                    if (CurrentDate > endDate) {
                        endDate = CurrentDate;
                    }
                }

                // For Daily basis
                if (vm.form.recurring_type == 2) {
                    //Every Day's
                    if (vm.form.recurring_settings.day_type == 'd') {
                        dailyInterval = vm.form.recurring_settings.days ? vm.form.recurring_settings.days : 1;
                    }

                    //Every Work Day
                    if (vm.form.recurring_settings.day_type == 'w') {
                       weekdays   = [2, 3, 4, 5, 6];
                    }
                }

                // For Weekly basis
                if (vm.form.recurring_type == 3) {
                    weekdays = [];
                    const weeks = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
                    weeks.forEach((week, w) => {
                        if (vm.form.recurring_settings.weekdays[week] == true) {
                            weekdays.push(parseInt([w + 1]));
                        }
                    });

                    weeklyInterval = vm.form.recurring_settings.weeks ? vm.form.recurring_settings.weeks : 1;
                }

                // For Monthly basis
                if (vm.form.recurring_type == 4) {
                    //Every Day of Month's
                    let day   = 1;
                    let month = 1;
                    let week  = 1;
                    const weekName = vm.form.recurring_settings.week_name ? vm.form.recurring_settings.week_name : 'mon';

                    if (vm.form.recurring_settings.repeat == 1) {
                        day = vm.form.recurring_settings.day ? vm.form.recurring_settings.day : 1;
                        month = vm.form.recurring_settings.month ? vm.form.recurring_settings.month : 1;

                        startDate.setDate(day);
                        days = parseInt(day);
                    }

                    //Every Week of month
                    if (vm.form.recurring_settings.repeat == 2) {
                        const weeks = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
                        const index = weeks.findIndex(w => w == weekName);
                        weekdays = parseInt([index + 1]);

                        week = vm.form.recurring_settings.week ? vm.form.recurring_settings.week : 1;
                        month = vm.form.recurring_settings.month ? vm.form.recurring_settings.month : 1;

                        const currentYear     = calendarMonth && calendarMonth.year ? calendarMonth.year : moment().format('YYYY');
                        const currentMonth    = calendarMonth && calendarMonth.month ? calendarMonth.month : moment().format('MM');
                        const firstDayOfMonth = moment(`${ currentYear }-${ currentMonth }`, 'YYYY-MM-DD');
                        let currentDay        = moment(firstDayOfMonth, 'YYYY-MM-DD');

                        days = vm.nthWeekdayOfMonth(currentYear, currentMonth, week, index).getDate();
                    }

                    monthlyInterval = parseInt(month);
                }

                // For Yearly basis
                if (vm.form.recurring_type == 5) {
                    //Every Day of Month's
                    let day   = 1;
                    let month = 1;
                    let week  = 1;
                    const weekName = vm.form.recurring_settings.week_name ? vm.form.recurring_settings.week_name : 'mon';

                    if (vm.form.recurring_settings.repeat == 1) {
                        day = vm.form.recurring_settings.day ? vm.form.recurring_settings.day : 1;
                        month = vm.form.recurring_settings.month ? parseInt(vm.form.recurring_settings.month) : 1;
                        startDate.setMonth((month - 1), day);
                        days = parseInt(day);
                    }

                    //Every Week of month
                    if (vm.form.recurring_settings.repeat == 2) {
                        const weeks = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
                        const index = weeks.findIndex(w => w == weekName);
                        weekdays = parseInt([index + 1]);

                        week = vm.form.recurring_settings.week ? vm.form.recurring_settings.week : 1;
                        month = vm.form.recurring_settings.month ? parseInt(vm.form.recurring_settings.month) : 1;

                        const currentYear     = calendarMonth && calendarMonth.year ? calendarMonth.year : moment().format('YYYY');
                        const currentMonth    = calendarMonth && calendarMonth.month ? calendarMonth.month : moment().format('MM');
                        const firstDayOfMonth = moment(`${ currentYear }-${ currentMonth }`, 'YYYY-MM-DD');
                        let currentDay        = moment(firstDayOfMonth, 'YYYY-MM-DD');

                        days = vm.nthWeekdayOfMonth(currentYear, currentMonth, week, index).getDate();

                        startDate.setMonth((month - 1), days);
                    }

                    if ((new Date()).getTime() > startDate.getTime()) {
                        startDate.setFullYear(startDate.getFullYear() + 1)
                    }

                    monthlyInterval = 12;
                }

                if (startDate > endDate && endDate) {
                    startDate = endDate;
                }

                vm.recurringEndDate = endDate ? moment(endDate).format('ll') : '';

                vm.calendarAttributes = [{
                                            dates: {
                                                start: startDate,
                                                end: endDate,
                                                days,
                                                weekdays,
                                                dailyInterval,
                                                weeklyInterval,
                                                monthlyInterval,
                                                yearlyInterval
                                            },
                                            highlight: {
                                                class: false,
                                                fillMode: 'solid',
                                                contentClass: 'assign_day',
                                                style: {
                                                    backgroundColor: '#2F7FED',
                                                }
                                            },
                                            popover: {
                                                slot: 'todo-row',
                                            },
                                        }];
            },

            calcWorkingDays (days) {
                 var fromDate = new Date();
                 var count = 0;

                 while (count < days) {
                     fromDate.setDate(fromDate.getDate() + 1);

                     if (fromDate.getDay() != 0 && fromDate.getDay() != 6) { // Skip weekends
                        count++;
                     }
                 }

                 return fromDate.getTime();
            },

            nthWeekdayOfMonth (year, month, nth, dow) {
                const d = new Date(year, month - 1, 7 * (nth - 1) + 1);
                const w = d.getDay();
                d.setDate(d.getDate() + (7 + dow - w) % 7);

                return d;
            },

            resetForm (force = true) {
                const vm = this;

                if (force) {
                    vm.progressStep = 1;

                    setTimeout(function () {
                        vm.reminderTab = 'reminders';
                        vm.clientReminderTab = 'goal';
                    }, 2000);

                    vm.resetProgressTracking();

                    if (vm.progressTracking.id) {
                        vm.isNew = false;
                    }
                }

                if (vm.progressTracking.id) {
                    setTimeout(function () {
                        const progressTracking = JSON.parse(JSON.stringify(vm.progressTracking));

                        if (progressTracking.public_settings && progressTracking.public_settings.cover === undefined) {
                            progressTracking.public_settings.cover = '';
                            progressTracking.public_settings.small_cover = '';
                        }

                        if (progressTracking.data_settings && progressTracking.data_settings.has_progress_question_label === undefined) {
                            progressTracking.data_settings.has_progress_question_label = 0;
                            progressTracking.data_settings.progress_question_label = 'Progress Questions';
                        }

                        if (progressTracking.recurring_settings && progressTracking.recurring_settings.time_zone === undefined) {
                            progressTracking.recurring_settings.time_zone = vm.authUser.timezone;
                        }

                        if (progressTracking.data_settings.rewards == undefined) {
                            progressTracking.data_settings.rewards = {
                                                                          has_reward_points: 0,
                                                                          reward_points: 0,
                                                                          reward_notif: 0,
                                                                          reward_notif_method: 1,
                                                                          reward_reason: 'submitting your progress report',
                                                                      };
                        }

                        if (progressTracking.data_settings.reverse_goal === undefined) {
                            progressTracking.data_settings.reverse_goal = 0;
                        }

                        if (progressTracking.data_settings.week_enabled === undefined) {
                            progressTracking.data_settings.week_enabled = 1;
                        }

                        if (progressTracking.public_settings.general_bg_color === undefined) {
                            progressTracking.public_settings.general_bg_color = '#FFF4E2';
                            progressTracking.public_settings.general_text_color = '#000000';
                            progressTracking.public_settings.general_element_color = '#ED9393';
                            progressTracking.public_settings.general_element_text_color = '#2f7eed';
                        }

                        let headline_setting = progressTracking.headline_setting ? progressTracking.headline_setting : {};
                        let subheadline_setting = progressTracking.subheadline_setting ? progressTracking.subheadline_setting : {};

                        if (!headline_setting.font_size) {
                            headline_setting = {
                                font_family: 'Inter',
                                font_weight: 600,
                                font_size: 50,
                                font_color: progressTracking.public_settings.cover_textcolor,
                                has_shadow: 0,
                                shadow_x: 0,
                                shadow_y: 4,
                                shadow_blur: 4,
                                shadow_color: 'rgba(0, 0, 0, 0.25)',
                            };
                        }

                        if (!subheadline_setting.font_size) {
                            subheadline_setting = {
                                font_family: 'Inter',
                                font_weight: 600,
                                font_size: 25,
                                font_color: '#2C3E50',
                                has_shadow: 0,
                                shadow_x: 0,
                                shadow_y: 4,
                                shadow_blur: 4,
                                shadow_color: 'rgba(0, 0, 0, 0.68)',
                            };
                        }

                        const applySavedStyling = vm.form.apply_saved_styling ? vm.form.apply_saved_styling : 0;

                        vm.form = progressTracking;
                        vm.form.headline_setting = headline_setting;
                        vm.form.subheadline_setting = subheadline_setting;
                        vm.form.recurring_settings.repeat = parseInt(vm.form.recurring_settings.repeat);
                        vm.form.reminder.goal_sending_method = parseInt(vm.form.reminder.goal_sending_method);
                        vm.form.reminder.notification_sending_method = parseInt(vm.form.reminder.notification_sending_method);
                        vm.form.apply_saved_styling = applySavedStyling;

                        if (vm.form.recurring_settings && !vm.form.recurring_settings.day) {
                            vm.form.recurring_settings.day = 1;
                        }

                        if (vm.form.public_settings && vm.form.public_settings.explainer_video_type === undefined) {
                            vm.form.public_settings.explainer_video_type  = 0;
                            vm.form.public_settings.explainer_embed = '';
                        }

                        if (vm.form.reminder.goal_sending_method == undefined) {
                            vm.form.reminder.goal_sending_method  = 1;
                            vm.form.reminder.goal_email_subject   = vm.getDefaultMessage('email-subject', 'goal-reminder');
                            vm.form.reminder.goal_email_message   = vm.getDefaultMessage('email-content', 'goal-reminder');
                            vm.form.reminder.goal_sms_message     = vm.getDefaultMessage('sms-content', 'goal-reminder');
                            vm.form.reminder.show_goal_email_logo = true;
                            vm.form.reminder.goal_logo_image      = '';
                            vm.form.reminder.goal_media           = [];
                        }

                        if (vm.form.reminder.notification_sending_method == undefined) {
                            vm.form.reminder.has_notification             = 0;
                            vm.form.reminder.notification_sending_method  = 1;
                            vm.form.reminder.notification_email_subject   = vm.getDefaultMessage('email-subject', 'notification-reminder');
                            vm.form.reminder.notification_email_message   = vm.getDefaultMessage('email-content', 'notification-reminder');
                            vm.form.reminder.notification_sms_message     = vm.getDefaultMessage('sms-content', 'notification-reminder');
                            vm.form.reminder.show_notification_email_logo = true;
                            vm.form.reminder.notification_logo_image      = '';
                            vm.form.reminder.notification_media           = [];
                            vm.form.reminder.notification_email_recipient = [vm.authUser.email];
                            vm.form.reminder.notification_sms_recipient   = [vm.authUser.sms_notification_number];
                        } else if (typeof vm.form.reminder.notification_email_recipient == 'string') {
                            vm.form.reminder.notification_email_recipient = [vm.authUser.email];
                            vm.form.reminder.notification_sms_recipient   = [vm.authUser.sms_notification_number];
                        }

                        if (vm.form.reminder.show_goal_signature == undefined) {
                            vm.form.reminder.show_goal_signature = 0;
                            vm.form.reminder.goal_signature_id = 0;
                            vm.form.reminder.show_signature  = 0;
                            vm.form.reminder.signature_id  = 0;
                        }

                        if (vm.form.reminder.show_notification_signature == undefined) {
                            vm.form.reminder.show_notification_signature = 0;
                            vm.form.reminder.notification_signature_id = 0;
                        }

                        if (vm.form.public_settings.cover_type == undefined) {
                            vm.form.public_settings.cover_type = 3;
                        }

                        if (vm.form.public_settings.footer_has_facebook == undefined) {
                            vm.form.public_settings.footer_has_facebook = 1;
                            vm.form.public_settings.footer_has_twitter = 1;
                            vm.form.public_settings.footer_has_instagram = 1;
                            vm.form.public_settings.footer_has_linkedin = 1;
                            vm.form.public_settings.footer_has_youtube = 1;
                        }

                        if (vm.form.public_settings.seo_title == undefined) {
                            vm.form.public_settings.seo_title = '';
                            vm.form.public_settings.seo_keyword = '';
                            vm.form.public_settings.seo_desc = '';
                            vm.form.public_settings.seo_index = 0;
                            vm.form.public_settings.seo_share_title = '';
                            vm.form.public_settings.seo_share_desc = '';
                            vm.form.public_settings.seo_thumb = '';
                            vm.form.public_settings.seo_fav = '';
                        }

                        if (vm.form.recurring_settings.day_type == undefined) {
                            vm.form.recurring_settings.day_type = 'd';
                        }

                        if (vm.form.public_settings.thankyou_bgcolor == undefined) {
                            vm.form.public_settings.thankyou_bgcolor = '#edebeb';
                            vm.form.public_settings.thankyou_textcolor = '#2c3e50';
                        }

                        if (vm.form.reminder.goal_email_button_bg_color == undefined) {
                            vm.form.reminder.goal_email_button_bg_color           = '#2c3e50';
                            vm.form.reminder.goal_email_button_text_color         = '#FFF';
                            vm.form.reminder.email_button_bg_color                = '#2c3e50';
                            vm.form.reminder.email_button_text_color              = '#FFF';
                            vm.form.reminder.notification_email_button_bg_color   = '#2c3e50';
                            vm.form.reminder.notification_email_button_text_color = '#FFF';
                        }

                        if (vm.form.data_settings.body_weight_title == undefined) {
                            vm.form.data_settings.body_weight_title             = 'Weight';
                            vm.form.data_settings.body_weight_sub_text          = 'What\'s your weight today?';
                            vm.form.data_settings.body_fat_title                = 'Body Fat';
                            vm.form.data_settings.body_fat_sub_text             = 'What\'s your body fat percentage today?';
                            vm.form.data_settings.body_circumference_title      = 'Circumference';
                            vm.form.data_settings.body_circumference_sub_text   = 'What are your circumference measurements today?';
                        }

                        vm.goalEmail = {
                            defaultHandler: true,
                            subject: vm.form.reminder.goal_email_subject,
                            email: vm.form.reminder.goal_email_message,
                            is_email_logo: vm.form.reminder.show_goal_email_logo ? 1 : 0,
                            is_signature: vm.form.reminder.goal_show_signature ? 1 : 0,
                            signature_id: vm.form.reminder.goal_signature_id,
                            show_email_logo: vm.form.reminder.show_goal_email_logo ? 1 : 0,
                            email_logo: vm.form.reminder.goal_logo_image,
                            email_button_bg_color: vm.form.reminder.goal_email_button_bg_color ? vm.form.reminder.goal_email_button_bg_color : '#2c3e50',
                            email_button_text_color: vm.form.reminder.goal_email_button_text_color ? vm.form.reminder.goal_email_button_text_color : '#FFF',
                            module_id: vm.progressTracking.id,
                        };

                        vm.reminderEmail = {
                            defaultHandler: true,
                            subject: vm.form.reminder.email_subject,
                            email: vm.form.reminder.email_message,
                            is_email_logo: vm.form.reminder.show_email_logo ? 1 : 0,
                            is_signature: vm.form.reminder.show_signature ? 1 : 0,
                            signature_id: vm.form.reminder.signature_id,
                            show_email_logo: vm.form.reminder.show_email_logo ? 1 : 0,
                            email_logo: vm.form.reminder.logo_image,
                            email_button_bg_color: vm.form.reminder.email_button_bg_color ? vm.form.reminder.email_button_bg_color : '#2c3e50',
                            email_button_text_color: vm.form.reminder.email_button_text_color ? vm.form.reminder.email_button_text_color : '#FFF',
                            module_id: vm.progressTracking.id,
                        };

                        vm.notificationEmail = {
                            defaultHandler: true,
                            subject: vm.form.reminder.notification_email_subject,
                            email: vm.form.reminder.notification_email_message,
                            is_email_logo: vm.form.reminder.show_notification_email_logo ? 1 : 0,
                            is_signature: vm.form.reminder.notification_show_signature ? 1 : 0,
                            signature_id: vm.form.reminder.notification_signature_id,
                            show_email_logo: vm.form.reminder.show_notification_email_logo ? 1 : 0,
                            email_logo: vm.form.reminder.notification_logo_image,
                            email_button_bg_color: vm.form.reminder.notification_email_button_bg_color ? vm.form.reminder.notification_email_button_bg_color : '#2c3e50',
                            email_button_text_color: vm.form.reminder.notification_email_button_text_color ? vm.form.reminder.notification_email_button_text_color : '#FFF',
                            module_id: vm.progressTracking.id,
                        };

                        vm.goalSmsText         = vm.progressTracking.reminder && vm.progressTracking.reminder.goal_sms_message ? vm.progressTracking.reminder.goal_sms_message : '';
                        vm.messageSmsText      = vm.progressTracking.reminder && vm.progressTracking.reminder.sms_message ? vm.progressTracking.reminder.sms_message : '';
                        vm.notificationSmsText = vm.progressTracking.reminder && vm.progressTracking.reminder.notification_sms_message ? vm.progressTracking.reminder.notification_sms_message : '';
                    }, 100);
                }

                setTimeout(function () {
                    vm.reminderTab = 'reminders';
                    vm.clientReminderTab = 'goal';
                }, 500);
            },

            resetProgressTracking () {
                const vm = this;

                vm.form = {
                    name: "",
                    is_classic: 1,
                    has_goal: 1,
                    access_code: "",
                    url: "",
                    recurring_type: 3,
                    collection_type: 1,
                    recurring_settings: {
                        hours: 1,
                        day: 1,
                        days: 1,
                        weeks: 1,
                        months: 1,
                        week: 1,
                        month: 1,
                        years: 1,
                        end_type: 1,
                        end_occurrences: 1,
                        end_date: '',
                        week_name: 'mon',
                        weekdays: {
                            mon: false,
                            tue: false,
                            wed: false,
                            thu: false,
                            fri: false,
                            sat: false,
                            sun: true,
                        },
                        repeat: 1,
                        send_before: 5,
                        send_before_type: 3,
                        send_at: 7,
                        send_at_type: 'PM',
                        time_zone: vm.user ? vm.user.timezone : 'America/New_York',
                        day_type: 'd',
                    },
                    reminder: {
                        has_reminder: 1,
                        sending_method: 1,
                        throttle_for_user_tz: 1,
                        recipient_email:'',
                        recipient_mobile:'',
                        email_subject: '',
                        email_message: '',
                        sms_message: '',
                        media: [],
                        show_email_logo: true,
                        show_email_signature: false,
                        signature_id: 0,
                        logo_image: '',
                        goal_sending_method: 1,
                        goal_email_subject: '',
                        goal_email_message: '',
                        goal_sms_message: '',
                        show_goal_email_logo: true,
                        goal_logo_image: '',
                        goal_media: [],
                        has_notification: 0,
                        notification_sending_method: 1,
                        notification_email_subject: '',
                        notification_email_message: '',
                        notification_sms_message: '',
                        show_notification_email_logo: true,
                        notification_logo_image: '',
                        notification_media: [],
                        notification_email_recipient: [vm.user ? vm.user.email : ''],
                        notification_sms_recipient: [vm.user ? vm.user.sms_notification_number : ''],
                        show_goal_signature: 0,
                        goal_signature_id: 0,
                        show_signature: 0,
                        show_notification_signature: 0,
                        notification_signature_id: 0,
                        goal_email_button_bg_color: '#2c3e50',
                        goal_email_button_text_color: '#FFF',
                        email_button_bg_color: '#2c3e50',
                        email_button_text_color: '#FFF',
                        notification_email_button_bg_color: '#2c3e50',
                        notification_email_button_text_color: '#FFF',
                    },
                    data_settings: {
                        has_weight: 1,
                        weight_unit: 'Pounds',
                        max_weight: 500,
                        has_body_fat: 1,
                        max_percent:100,
                        has_circumference: 0,
                        c_unit:'Inches',
                        has_progress_pics: 1,
                        photo_orientation:'Portrait',
                        photo_view:{
                            front: true,
                            back:true,
                            side: true
                        },
                        total_submission: 6,
                        limited_submission: 0,
                        reverse_goal: 1,
                        week_enabled: 1,
                        has_progress_question_label: 1,
                        progress_question_label: 'Progress Questions',
                        rewards: {
                            has_reward_points: 0,
                            reward_points: 0,
                            reward_notif: 0,
                            reward_notif_method: 1,
                            reward_reason: 'submitting your progress report',
                        },
                        neck: 1,
                        shoulders: 1,
                        chest: 1,
                        waist: 1,
                        biceps: 0,
                        belly_button: 0,
                        hips: 0,
                        thigh: 0,
                        calf: 0,
                        bg_color: '#fff4e2',
                        text_color: '#121525',
                        body_weight_title: 'Weight',
                        body_weight_sub_text: 'What\'s your weight today?',
                        body_fat_title: 'Body Fat',
                        body_fat_sub_text: 'What\'s your body fat percentage today?',
                        body_circumference_title: 'Circumference',
                        body_circumference_sub_text: 'What are your circumference measurements today?',
                    },
                    public_settings: {
                        display_explainer_video: 0,
                        explainer_video_type: 0,
                        explainer_video: "",
                        explainer_embed: "",
                        display_company_branding: 1,
                        company_branding: "",
                        display_cover: 1,
                        headline: 'Time To Submit Your Progress',
                        subheadline: '',
                        display_logo:1,
                        logo: '',
                        display_author:1,
                        author:"",
                        display_header:1,
                        display_footer:1,
                        header_bgcolor: '#FFF',
                        header_textcolor: '#000',
                        submit_btn_text: 'Submit Progress',
                        element_color: '#2c3e50',
                        element_text_color: '#2C3E50',
                        submit_btn_textcolor: '#fff',
                        submit_btn_bgcolor: '#2c3e50',
                        footer_bgcolor: '#D4D4D4',
                        footer_textcolor: '#2C3E50',
                        cover_color: '#1976d2',
                        cover_textcolor: '#2C3E50',
                        cover: '',
                        small_cover: '',
                        footer_has_facebook: 1,
                        footer_has_twitter: 1,
                        footer_has_instagram: 1,
                        footer_has_linkedin: 0,
                        footer_has_youtube: 0,
                        thankyou_bgcolor: '#edebeb',
                        thankyou_textcolor: '#2c3e50',
                        seo_title: '{{company_name}} Client Tracking',
                        seo_keyword: 'Client tracking',
                        seo_desc: `Whatever can be measured can be improved and what you focus on becomes reality. Let's track your Client Tracking!`,
                        seo_index: 0,
                        seo_share_title: '{{company_name}} Client Tracking',
                        seo_share_desc: `Whatever can be measured can be improved and what you focus on becomes reality. Let's track your Client Tracking!`,
                        seo_thumb: '',
                        seo_fav: '',
                    },
                    headline_setting: {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.25)',
                    },
                    subheadline_setting: {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.68)',
                    },
                };

                vm.generateDefaultMessage();
                vm.isNew = true;
                vm.headerTab = 'logo';

                vm.goalEmail = {
                    defaultHandler: true,
                    subject: vm.form.reminder.goal_email_subject,
                    email: vm.form.reminder.goal_email_message,
                    is_email_logo: vm.form.reminder.show_goal_email_logo,
                    is_signature: vm.form.reminder.goal_show_signature,
                    signature_id: vm.form.reminder.goal_signature_id,
                    show_email_logo: vm.form.reminder.show_goal_email_logo ,
                    email_logo: vm.form.reminder.goal_logo_image,
                    email_button_bg_color: vm.form.reminder.goal_email_button_bg_color ? vm.form.reminder.goal_email_button_bg_color : '#2c3e50',
                    email_button_text_color: vm.form.reminder.goal_email_button_text_color ? vm.form.reminder.goal_email_button_text_color : '#FFF',
                };

                vm.reminderEmail = {
                    defaultHandler: true,
                    subject: vm.form.reminder.email_subject,
                    email: vm.form.reminder.email_message,
                    is_email_logo: vm.form.reminder.show_email_logo,
                    is_signature: vm.form.reminder.show_signature,
                    signature_id: vm.form.reminder.signature_id,
                    show_email_logo: vm.form.reminder.show_email_logo,
                    email_logo: vm.form.reminder.logo_image,
                    email_button_bg_color: vm.form.reminder.email_button_bg_color ? vm.form.reminder.email_button_bg_color : '#2c3e50',
                    email_button_text_color: vm.form.reminder.email_button_text_color ? vm.form.reminder.email_button_text_color : '#FFF',
                };

                vm.notificationEmail = {
                    defaultHandler: true,
                    subject: vm.form.reminder.notification_email_subject,
                    email: vm.form.reminder.notification_email_message,
                    is_email_logo: vm.form.reminder.show_notification_email_logo,
                    is_signature: vm.form.reminder.notification_show_signature,
                    signature_id: vm.form.reminder.notification_signature_id,
                    show_email_logo: vm.form.reminder.show_notification_email_logo,
                    email_logo: vm.form.reminder.notification_logo_image,
                    email_button_bg_color: vm.form.reminder.notification_email_button_bg_color ? vm.form.reminder.notification_email_button_bg_color : '#2c3e50',
                    email_button_text_color: vm.form.reminder.notification_email_button_text_color ? vm.form.reminder.notification_email_button_text_color : '#FFF',
                };
            },

            generateDefaultMessage () {
                const vm = this;

                vm.form.reminder.email_subject  = '';
                vm.form.reminder.email_message  = '';
                vm.form.reminder.sms_message    = '';
                vm.form.reminder.goal_email_subject  = '';
                vm.form.reminder.goal_email_message  = '';
                vm.form.reminder.goal_sms_message    = '';
                vm.form.reminder.notification_email_subject  = '';
                vm.form.reminder.notification_email_message  = '';
                vm.form.reminder.notification_sms_message    = '';

                vm.form.reminder.email_subject               = vm.getDefaultMessage('email-subject', 'reminder');
                vm.form.reminder.email_message               = vm.getDefaultMessage('email-content', 'reminder');
                vm.form.reminder.sms_message                 = vm.getDefaultMessage('sms-content', 'reminder');
                vm.form.reminder.goal_email_subject          = vm.getDefaultMessage('email-subject', 'goal-reminder');
                vm.form.reminder.goal_email_message          = vm.getDefaultMessage('email-content', 'goal-reminder');
                vm.form.reminder.goal_sms_message            = vm.getDefaultMessage('sms-content', 'goal-reminder');
                vm.form.reminder.notification_email_subject  = vm.getDefaultMessage('email-subject', 'notification-reminder');
                vm.form.reminder.notification_email_message  = vm.getDefaultMessage('email-content', 'notification-reminder');
                vm.form.reminder.notification_sms_message    = vm.getDefaultMessage('sms-content', 'notification-reminder');
            },

            getDefaultMessage (type, section) {
                if (type == 'email-subject' && section == 'reminder') {
                    return "[CHECK-IN DUE] Let's verify our progress";
                } else if (type == 'email-content' && section == 'reminder') {
                    let email_message = "<p>Hello {{first_name}},</p>";
                        email_message += "<p>We're thrilled to see you striving for greatness with {{owner_first_name}}. It's time to showcase your progress and keep the momentum going!</p>";
                        email_message += "<p>Remember, we can only enhance what we can measure, so don't miss this chance to make meaningful strides on your journey.</p>";
                        email_message += "<p>Ready to share your accomplishments? Click below:</p>";
                        email_message += "<p>{{progress_tracking_button}}</p>";
                        email_message += "<p>Don't forget to use your member ID {{member_id}} for a seamless submission.</p>";
                        email_message += "<p>Your dedication inspires us, and we can't wait to see your achievements!</p>";
                        email_message += "<p>Warm regards,</p>";
                        email_message += "<p>Team {{company_name}}</p>";

                    return email_message;
                } else if (type == 'sms-content' && section == 'reminder') {
                    let sms_message = "Hello {{first_name}}! It's time to showcase your progress with {{owner_first_name}}.\r\n";
                        sms_message += "Ready to take the next step? Click here: {{progress_tracking_link}}.\r\n";
                        sms_message += "Use your member ID {{member_id}} for easy submissions. - ";
                        sms_message += "Team {{company_name}}";

                    return sms_message;
                } else if (type == 'email-subject' && section == 'goal-reminder') {
                    return "[TRACKING] You've been enrolled in {{tracking_type}} tracking";
                } else if (type == 'email-content' && section == 'goal-reminder') {
                    let email_message = "<p>Hello {{first_name}},</p>";
                        email_message += "<p>We're excited to inform you that you've been enrolled in {{tracking_type}} tracking by {{company_name}}! Periodically, you'll be asked to submit your progress reports.</p>";
                        email_message += "<p>To check when you need to submit your progress, log in to your client portal and head to your calendar.</p>";
                        email_message += "<p>Tracking ID: {{progress_scenario}}</p>";
                        email_message += "<p>{{progress_tracking_goal_button}}</p>";
                        email_message += "<p>For submitting your goals, make use of your member ID, which is {{member_id}}.</p>";
                        email_message += "<p>Best regards,</p>";
                        email_message += "<p>Team {{company_name}}</p>";
                        email_message += "<p>P.S. Don't forget to jot down your member ID in a safe place.</p>";

                    return email_message;
                } else if (type == 'sms-content' && section == 'goal-reminder') {
                    let sms_message = "Hi {{first_name}}, You're now part of progress tracking with {{company_name}}! To begin, click here:";
                        sms_message += "{{progress_tracking_goal_link}}\r\n";
                        sms_message += "Use your member ID to submit your updates. {{member_id}} \r\n";
                        sms_message += "Team {{company_name}}";

                    return sms_message;
                } else if (type == 'email-subject' && section == 'notification-reminder') {
                    return "Client tracking notification!";
                } else if (type == 'email-content' && section == 'notification-reminder') {

                    let email_message = "<p>Hi {{company_owner}},</p>";
                        email_message += "<p>{{full_name}} has just submitted their {{progress_tracking_name}} tracking form.</p>";
                        email_message += "<p>Click the link below to view the results.</p>";
                        email_message += "<p>{{progress_tracking_response_link}}</p>";

                    return email_message;
                } else if (type == 'sms-content' && section == 'notification-reminder') {
                    let sms_message = "Hi {{company_owner}},\r\n";
                        sms_message += "{{full_name}} has just submitted their {{progress_tracking_name}} tracking form.\r\n";
                        sms_message += "Click the link below to view the results.\r\n";
                        sms_message += "{{progress_tracking_response_link}}";

                    return sms_message;
                }

                return '';
            },

            handleReminderDefaultEmail () {
                const vm = this;

                vm.form.reminder.email_subject  = vm.getDefaultMessage('email-subject', 'reminder');
                vm.form.reminder.email_message  = vm.getDefaultMessage('email-content', 'reminder');

                vm.reminderEmail.subject  = vm.form.reminder.email_subject;
                vm.reminderEmail.email    = vm.form.reminder.email_message;
            },

            handleReminderDefaultSms () {
                const vm = this;

                vm.form.reminder.sms_message    = vm.getDefaultMessage('sms-content', 'reminder');
            },

            handleGoalDefaultEmail () {
                const vm = this;

                vm.form.reminder.goal_email_subject  = vm.getDefaultMessage('email-subject', 'goal-reminder');
                vm.form.reminder.goal_email_message  = vm.getDefaultMessage('email-content', 'goal-reminder');

                vm.goalEmail.subject  = vm.form.reminder.goal_email_subject;
                vm.goalEmail.email    = vm.form.reminder.goal_email_message;
            },

            handleNotificationDefaultEmail () {
                const vm = this;

                vm.form.reminder.notification_email_subject  = vm.getDefaultMessage('email-subject', 'notification-reminder');
                vm.form.reminder.notification_email_message  = vm.getDefaultMessage('email-content', 'notification-reminder');

                vm.notificationEmail.subject  = vm.form.reminder.notification_email_subject;
                vm.notificationEmail.email    = vm.form.reminder.notification_email_message;
            },

            handleGoalDefaultSms () {
                const vm = this;

                vm.form.reminder.goal_sms_message    = vm.getDefaultMessage('sms-content', 'goal-reminder');
            },

            handleNotificationDefaultSms () {
                const vm = this;

                vm.form.reminder.notification_sms_message    = vm.getDefaultMessage('sms-content', 'notification-reminder');
            },

            handleNotificationEmailRecipient (recipient) {
                const vm = this;

                vm.form.reminder.notification_email_recipient = recipient;
            },

            handleNotificationSMSRecipient (recipient) {
                const vm = this;

                vm.form.reminder.notification_sms_recipient = recipient;
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;

                params.reminder.goal_email_subject            = vm.goalEmail.subject;
                params.reminder.goal_email_message            = vm.goalEmail.email;
                params.reminder.goal_show_signature           = vm.goalEmail.is_signature;
                params.reminder.goal_signature_id             = vm.goalEmail.signature_id;
                params.reminder.show_goal_email_logo          = vm.goalEmail.show_email_logo;
                params.reminder.goal_logo_image               = vm.goalEmail.email_logo;
                params.reminder.email_subject                 = vm.reminderEmail.subject;
                params.reminder.email_message                 = vm.reminderEmail.email;
                params.reminder.show_signature                = vm.reminderEmail.is_signature;
                params.reminder.signature_id                  = vm.reminderEmail.signature_id;
                params.reminder.show_email_logo               = vm.reminderEmail.show_email_logo;
                params.reminder.logo_image                    = vm.reminderEmail.email_logo;
                params.reminder.notification_email_subject    = vm.notificationEmail.subject;
                params.reminder.notification_email_message    = vm.notificationEmail.email;
                params.reminder.notification_show_signature   = vm.notificationEmail.is_signature;
                params.reminder.notification_signature_id     = vm.notificationEmail.signature_id;
                params.reminder.show_notification_email_logo  = vm.notificationEmail.show_email_logo;
                params.reminder.notification_logo_image       = vm.notificationEmail.email_logo;

                params.reminder.goal_email_button_bg_color            = vm.goalEmail.email_button_bg_color;
                params.reminder.goal_email_button_text_color          = vm.goalEmail.email_button_text_color;
                params.reminder.email_button_bg_color                 = vm.reminderEmail.email_button_bg_color;
                params.reminder.email_button_text_color               = vm.reminderEmail.email_button_text_color;
                params.reminder.notification_email_button_bg_color    = vm.notificationEmail.email_button_bg_color;
                params.reminder.notification_email_button_text_color  = vm.notificationEmail.email_button_text_color;

                params.setFieldError = setFieldError;

                if (vm.progressStep == 2) {
                    const el = document.getElementsByClassName('tracking-update');

                    el.forEach((tracking)=>{
                        if (tracking) {
                            tracking.click();
                        }
                    });
                }

                vm.saveProgressTracking(params).then((result) => {
                    if (result && vm.progressStep < 5) {
                        if (vm.switchStep) {
                            vm.progressStep = vm.progressStep + 1;
                            vm.lastStep   = vm.progressStep;
                        }
                    } else if (result && vm.progressStep == 5) {
                        if (vm.switchStep) {
                            vm.closeModal();
                        }
                    }

                    vm.switchStep = true;
                });
            },

            handleProgressAutoSave () {
                const vm = this;
                const form = vm.$refs['progress-wizard-form'];
                const params = vm.form;

                params.reminder.goal_email_subject            = vm.goalEmail.subject;
                params.reminder.goal_email_message            = vm.goalEmail.email;
                params.reminder.goal_show_signature           = vm.goalEmail.is_signature;
                params.reminder.goal_signature_id             = vm.goalEmail.signature_id;
                params.reminder.show_goal_email_logo          = vm.goalEmail.show_email_logo;
                params.reminder.goal_logo_image               = vm.goalEmail.email_logo;
                params.reminder.email_subject                 = vm.reminderEmail.subject;
                params.reminder.email_message                 = vm.reminderEmail.email;
                params.reminder.show_signature                = vm.reminderEmail.is_signature;
                params.reminder.signature_id                  = vm.reminderEmail.signature_id;
                params.reminder.show_email_logo               = vm.reminderEmail.show_email_logo;
                params.reminder.logo_image                    = vm.reminderEmail.email_logo;
                params.reminder.notification_email_subject    = vm.notificationEmail.subject;
                params.reminder.notification_email_message    = vm.notificationEmail.email;
                params.reminder.notification_show_signature   = vm.notificationEmail.is_signature;
                params.reminder.notification_signature_id     = vm.notificationEmail.signature_id;
                params.reminder.show_notification_email_logo  = vm.notificationEmail.show_email_logo;
                params.reminder.notification_logo_image       = vm.notificationEmail.email_logo;

                params.reminder.goal_email_button_bg_color            = vm.goalEmail.email_button_bg_color;
                params.reminder.goal_email_button_text_color          = vm.goalEmail.email_button_text_color;
                params.reminder.email_button_bg_color                 = vm.reminderEmail.email_button_bg_color;
                params.reminder.email_button_text_color               = vm.reminderEmail.email_button_text_color;
                params.reminder.notification_email_button_bg_color    = vm.notificationEmail.email_button_bg_color;
                params.reminder.notification_email_button_text_color  = vm.notificationEmail.email_button_text_color;

                params.setFieldError = form.setFieldError;

                vm.saveProgressTracking(params);
            },

            handleDeleteProgressTracking (ids) {
                const vm  = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this progress scenario`, 'Delete');
                      options.preConfirm = function () {
                                                return vm.deleteProgressTracking({ ids }).then((result) => {
                                                    if (result) {
                                                        vm.closeModal();
                                                        if (vm.$parent.$parent && vm.$parent.$parent.refreshProgressTracking) {
                                                            vm.$parent.$parent.refreshProgressTracking();
                                                        } else {
                                                            vm.$parent.refreshProgressTracking();
                                                        }

                                                        vm.resetForm();
                                                    }
                                                });
                                            };

                Swal.fire(options);
            },

            addSection () {
                const vm = this;
                const title = '';
                const count = vm.questionSections.length ? 1 : 0;

                vm.saveQuestionSection({ title, count, progress_tracking_id: vm.progressTracking.id }).then((result) => {
                    if (result) {
                        Toastr.success('Custom question section has been added successfully!');
                    }
                });
            },

            handleSectionAutosave (section) {
                const vm = this;

                vm.saveQuestionSection(section).then((result) => {
                    if (result) {
                        if(section.enabled == 0){
                            section.enabled = false;
                        }

                        Toastr.success('Custom question section has been updated successfully!');
                    }
                });
            },

            handleSectionSave () {
                const vm = this;

                vm.saveQuestionSection(vm.questionElement).then((result) => {
                    if (result) {
                        Toastr.success('Custom question section has been updated successfully!');
                    }
                });
            },

            deleteSectionPrompt (section, index) {
                const vm  = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this question section`, 'Delete');
                      options.preConfirm = function () { return vm.deleteQuestionSection({ section_id: section.id, progress_tracking_id: section.progress_tracking_id }); };

                Swal.fire(options);
            },

            deleteQuestionPrompt (question, index) {
                const vm  = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this question`, 'Delete');
                      options.preConfirm = function () { return vm.deleteCustomQuestion({ question_id: question.id, progress_tracking_id: question.progress_tracking_id }); };

                Swal.fire(options);
            },

            goTo (refName) {
                const vm = this;

                if (vm.isNew) {
                    return false;
                }

                setTimeout(function () {
                    if ( vm.progressStep != 2 && vm.progressStep != 3 ) {
                        setTimeout(function () {
                            let element = vm.$refs[refName];

                            setTimeout(function() {
                                let top     = element.offsetTop;
                                const el    = document.querySelector('.preview_content .content_area');

                                if (el) {
                                    el.scrollTo(0, top);
                                }
                            }, 100);
                        }, 10);
                    } else {
                        vm.$refs['preview-content'].goTo(refName);
                    }
                }, 10);
            },

            setting (refName) {
                const vm = this;

                const section = ['cvrsetting', 'ftrsetting', 'subbtnsetting', 'formsetting', 'thankyousetting'];

                if (section.includes(refName)) {
                    vm.progressStep = refName === 'formsetting' ? 2 : 3;

                    setTimeout(function () {
                        let element = vm.$refs[refName];

                        setTimeout(function () {
                            if (element) {
                                let top     = element.offsetTop;
                                const el    = document.querySelector('.global_setting .tabs_content');

                                if (el) {
                                    el.scrollTo(0, top);
                                }
                            }
                        }, 100);
                    }, 100);
                }
            },

            scrollingTo (refName) {
                let targetElm = document.querySelector(`.preview_content .tracker_container #${refName}`);

                setTimeout(function() {
                    if (targetElm && targetElm.offsetTop) {
                        let top     = targetElm.offsetTop - 50;
                        const el    = document.querySelector('.preview_content .tracker_container');

                        if (el) {
                            el.scrollTo(0, top);
                        }
                    }
                }, 100);
            },

            settingsTo (refName) {
                let targetElm = document.querySelector(`.global_setting .tabs_content #${refName}`);
                let parElm = targetElm.closest('.section_content');
                let isOpen = parElm.classList.contains('show');

                if(targetElm && !isOpen){
                    parElm.classList.add('show');
                }

                setTimeout(function() {
                    if (targetElm && targetElm.offsetTop) {
                        let top     = (targetElm.offsetTop + parElm.offsetTop) - 50;
                        const el    = document.querySelector('.global_setting .tabs_content');

                        if (el) {
                            el.scrollTo(0, top);
                        }
                    }
                }, 1000);
            },

            addEditSectionQuetion (section, question = {}) {
                const vm = this;

                vm.customQuestionSectionId  = section.id;
                vm.selectedCustomQuestion   = question;
                vm.customQuestionPanel      = true;
            },

            handleSectionSort () {
                const vm = this;
                const ids = vm.sections.map((section) => { return section.id });

                vm.sortCustomQuestionSection({ ids });
            },

            handleQuestionSort (questions) {
                const vm = this;
                const ids = questions.map((question) => { return question.id });

                vm.sortCustomQuestions({ ids });
            },

            updateSmsText () {
                const vm = this;

                const goalSms      = vm.$refs['goal-sms-component'];
                const messageSms      = vm.$refs['message-sms-component'];
                const notificationSms = vm.$refs['notification-sms-component'];

                if (goalSms) {
                    vm.goalSmsText = goalSms.smsMedia && goalSms.smsMedia.media && goalSms.smsMedia.media[0] ? vm.form.reminder.goal_sms_message + '\n\n' + goalSms.smsMedia.media[0].toString() : vm.form.reminder.goal_sms_message;
                }

                if (messageSms) {
                    vm.messageSmsText = messageSms.smsMedia && messageSms.smsMedia.media && messageSms.smsMedia.media[0] ? vm.form.reminder.sms_message + '\n\n' + messageSms.smsMedia.media[0].toString() : vm.form.reminder.sms_message;
                }

                if (notificationSms) {
                    vm.notificationSmsText = notificationSms.smsMedia && notificationSms.smsMedia.media && notificationSms.smsMedia.media[0] ? vm.form.reminder.notification_sms_message + '\n\n' + notificationSms.smsMedia.media[0].toString() : vm.form.reminder.notification_sms_message;
                }
            },

            handleCopy (string) {
                copyText(string, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy link!');
                    } else {
                        Toastr.success('Link copied successfully!');
                    }
                });
            },

            toggleSectionbar () {
                const leftSidebar = document.querySelector('.global_setting');

                if (leftSidebar.classList.contains('show_m_sidebar')) {
                    leftSidebar.classList.remove('show_m_sidebar');
                } else {
                    leftSidebar.classList.add('show_m_sidebar');
                }
            },

            resizeEventHandler () {
                const vm = this;
                let screenWidth = window.innerWidth;

                if (screenWidth < 768) {
                    vm.preview = false;
                } else {
                    vm.preview = true;
                }
            },

            toggleSection (ev) {
                const vm = this;
                let parentElm = ev.currentTarget.closest('.section_content');

                if (parentElm.classList.contains('show')) {
                    parentElm.classList.remove('show');
                    vm.toggleAll = 1;
                } else {
                    parentElm.classList.add('show');
                    vm.toggleAll = 0;
                }
            },

            openAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for(let i = 0; i < allParentElm.length; i++){
                    allParentElm[i].classList.add('show');
                }

                vm.toggleAll = 0;
            },

            closeAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.remove('show');
                }

                vm.toggleAll = 1;
            },

            handleApplySavedStyle (section = 'footer') {
                const vm = this;

                if (vm.progressTrackingDefaultContent && vm.progressTrackingDefaultContent.public_settings && vm.form.public_settings) {
                    if (section == 'footer') {
                        vm.form.public_settings.display_footer       = vm.progressTrackingDefaultContent.public_settings.display_footer ? 1 : 0;
                        vm.form.public_settings.footer_company       = vm.progressTrackingDefaultContent.public_settings.footer_company;
                        vm.form.public_settings.footer_bgcolor       = vm.progressTrackingDefaultContent.public_settings.footer_bgcolor;
                        vm.form.public_settings.footer_policy        = vm.progressTrackingDefaultContent.public_settings.footer_policy;
                        vm.form.public_settings.footer_terms         = vm.progressTrackingDefaultContent.public_settings.footer_terms;
                        vm.form.public_settings.footer_textcolor     = vm.progressTrackingDefaultContent.public_settings.footer_textcolor;
                        vm.form.public_settings.footer_facebook      = vm.progressTrackingDefaultContent.public_settings.footer_facebook;
                        vm.form.public_settings.footer_twitter       = vm.progressTrackingDefaultContent.public_settings.footer_twitter;
                        vm.form.public_settings.footer_instagram     = vm.progressTrackingDefaultContent.public_settings.footer_instagram;
                        vm.form.public_settings.footer_linkedin      = vm.progressTrackingDefaultContent.public_settings.footer_linkedin;
                        vm.form.public_settings.footer_youtube       = vm.progressTrackingDefaultContent.public_settings.footer_youtube;
                        vm.form.public_settings.footer_has_instagram = vm.progressTrackingDefaultContent.public_settings.footer_has_instagram ? 1 : 0;
                        vm.form.public_settings.footer_has_facebook  = vm.progressTrackingDefaultContent.public_settings.footer_has_facebook ? 1 : 0;
                        vm.form.public_settings.footer_has_twitter   = vm.progressTrackingDefaultContent.public_settings.footer_has_twitter ? 1 : 0;
                        vm.form.public_settings.footer_has_linkedin  = vm.progressTrackingDefaultContent.public_settings.footer_has_linkedin ? 1 : 0;
                        vm.form.public_settings.footer_has_youtube   = vm.progressTrackingDefaultContent.public_settings.footer_has_youtube ? 1 : 0;
                    }
                }
            },

            handleCustomTrakingCreate (index, category) {
                const vm = this;
                const params = {
                    progress_tracking_id: vm.progressTracking.id,
                    progress_tracking_category_id: category.id,
                    name: 'New Tracking',
                    title: 'New Tracking',
                    sub_text: 'Description for new tracking',
                    unit: '',
                    has_limit: 0,
                    has_limit_label: 0,
                    type: 'ratings',
                    low_limit: 0,
                    high_limit: 10,
                    low_label: 'Low',
                    high_label: 'High',
                    has_custom_icon: 0,
                    icon: '',
                    default_icon: '',
                    has_notes: 0,
                    has_goal: 1,
                    can_override_goal: 1,
                    goal_type: 'Range',
                    goal_high_limit: 55,
                    goal_low_limit: 35,
                    goal_target_type: '',
                    goal_target: '',
                    settings: [],
                    options: [
                        // { value: 'oz', label: 'Ounce' },
                        // { value: 'ml', label: 'Milliliters' },
                        // { value: 'kcal', label: 'kcal' },
                        // { value: 'pcs', label: 'Pieces' },
                        // { value: 'servings', label: 'Servings' },
                        // { value: 'cups', label: 'Cups' },
                        // { value: 'gm', label: 'Grams' },
                        // { value: 'fists', label: 'Fists' },
                        // { value: 'palms', label: 'Palms' },
                        // { value: 'thumbs', label: 'Thumbs' },
                        // { value: 'steps', label: 'Steps' },
                        // { value: 'km', label: 'Kilometers' },
                        // { value: 'mi', label: 'Miles' },
                        // { value: 'hr', label: 'Hour' },
                        // { value: 'min', label: 'Minutes' },
                        // { value: 'bpm', label: 'bpm' },
                        // { value: 'mmHg', label: 'mmHg' },
                        // { value: 'ms', label: 'Milliseconds' },
                        // { value: 'ml/kg/min', label: 'ml/kg/min' },
                        // { value: 'pwv', label: 'pwv' },
                        // { value: 'ng/mL', label: 'ng/mL' },
                        // { value: 'nmol/L', label: 'nmol/L' },
                        // { value: 'mg/dL', label: 'mg/dL' },
                        // { value: 'mmol/L', label: 'mmol/L' },
                        // { value: 'pmol/L', label: 'pmol/L' },
                        // { value: 'mL/dL', label: 'mL/dL' },
                        // { value: 'L/L', label: 'L/L' },
                        // { value: 'g/dL', label: 'g/dL' },
                        // { value: 'mcU/mL', label: 'mcU/mL' },
                        // { value: 'ppm', label: 'ppm' },
                        // { value: 'ACEs', label: 'ACEs' },

                        {
                          type: 'New',
                          units: [
                              {
                                  value: 'ACEs',
                              },
                              {
                                  value: 'ppm',
                              },
                              {
                                  value: 'mcU/mL',
                              },
                              {
                                  value: 'g/dL',
                              },
                              {
                                  value: 'L/L',
                              },
                              {
                                  value: 'mL/dL',
                              },
                              {
                                  value: 'pmol/L',
                              },
                              {
                                  value: 'mmol/L',
                              },
                              {
                                  value: 'mg/dL',
                              },
                              {
                                  value: 'nmol/L',
                              },
                              {
                                  value: 'ng/mL',
                              },
                              {
                                  value: 'pwv',
                              },
                              {
                                  value: 'ml/kg/min',
                              },
                              {
                                  value: 'mmHg',
                              },
                              {
                                  value: 'kcal',
                              },
                          ]
                      },
                        {
                          type: 'Time',
                          units: [
                              {
                                  value: 'Milliseconds',
                              },
                              {
                                  value: 'Seconds',
                              },
                              {
                                  value: 'Minutes',
                              },
                              {
                                  value: 'Hours',
                              },
                              {
                                  value: 'Days',
                              },
                              {
                                  value: 'Months',
                              },
                              {
                                  value: 'Years',
                              },
                          ]
                      },
                      {
                          type: 'Distance',
                          units: [
                              {
                                  value: 'Millimeters',
                              },
                              {
                                  value: 'Centimeters',
                              },
                              {
                                  value: 'Inches',
                              },
                              {
                                  value: 'Feet',
                              },
                              {
                                  value: 'Yards',
                              },
                              {
                                  value: 'Meters',
                              },
                              {
                                  value: 'Kilometers',
                              },
                              {
                                  value: 'Miles',
                              },
                          ]
                      },
                      {
                          type: 'Liquid Measures',
                          units: [
                              {
                                  value: 'Milliliters',
                              },
                              {
                                  value: 'CCs',
                              },
                              {
                                  value: 'Ounces',
                              },
                              {
                                  value: 'Cups',
                              },
                              {
                                  value: 'Quarts',
                              },
                              {
                                  value: 'Pints',
                              },
                              {
                                  value: 'Liters',
                              },
                              {
                                  value: 'Gallons',
                              },
                          ]
                      },
                      {
                          type: 'Dry Measures',
                          units: [
                              {
                                  value: 'Pinch',
                              },
                              {
                                  value: 'Dash',
                              },
                              {
                                  value: 'Tsp',
                              },
                              {
                                  value: 'Tbs',
                              },
                              {
                                  value: 'Cups',
                              },
                              {
                                  value: 'Grams',
                              },
                              {
                                  value: 'Milligrams',
                              },
                              {
                                  value: 'Micrograms',
                              },
                          ]
                      },
                      {
                          type: 'Visual Measures',
                          units: [
                              {
                                  value: 'Fists',
                              },
                              {
                                  value: 'Palms',
                              },
                              {
                                  value: 'Thumbs',
                              },
                              {
                                  value: 'Pieces',
                              },
                              {
                                  value: 'Servings',
                              },
                          ]
                      },
                      {
                          type: 'Temperature',
                          units: [
                              {
                                  value: 'Degs F',
                              },
                              {
                                  value: 'Degs C',
                              },
                          ]
                      },
                      {
                          type: 'Vitals',
                          units: [
                              {
                                  value: 'BMI',
                              },
                              {
                                  value: 'BMR',
                              },
                              {
                                  value: 'RHR',
                              },
                              {
                                  value: 'AHR',
                              },
                              {
                                  value: 'MHR',
                              },
                              {
                                  value: 'O2 Sat',
                              },
                              {
                                  value: 'BPM',
                              },
                              {
                                  value: 'BP',
                              },
                              {
                                  value: 'Vo2max',
                              },
                              {
                                  value: 'ROM',
                              },
                          ]
                      },
                      {
                          type: 'Fitness',
                          units: [
                              {
                                  value: 'Steps',
                              },
                              {
                                  value: 'PR',
                              },
                              {
                                  value: '1RM',
                              },
                              {
                                  value: '3RM',
                              },
                              {
                                  value: '5RM',
                              },
                              {
                                  value: '10RM',
                              },
                              {
                                  value: 'Reps',
                              },
                              {
                                  value: 'Sets',
                              },
                              {
                                  value: 'Watts',
                              },
                              {
                                  value: 'Supersets',
                              },
                              {
                                  value: 'Circuits',
                              },
                              {
                                  value: 'Rounds',
                              },
                              {
                                  value: 'AMRAP',
                              },
                              {
                                  value: 'Calories',
                              },
                              {
                                  value: 'Pain Index',
                              },
                          ]
                      },
                      {
                          type: 'Weight',
                          units: [
                              {
                                  value: 'Kilograms',
                              },
                              {
                                  value: 'Pounds',
                              },
                          ]
                      },
                      {
                          type: 'Subjective Measures',
                          units: [
                              {
                                  value: 'Points',
                              },
                              {
                                  value: 'Rating',
                              },
                              {
                                  value: 'Stars',
                              },
                          ]
                      },
                    ]
                };

                vm.createCategoryCustomProgress(params).then((progress) => {
                    if (progress) {
                        if (vm.form.categories[index].progresses) {
                            vm.form.categories[index].progresses.push(progress);
                        } else {
                            vm.form.categories[index].progresses = [];
                            vm.form.categories[index].progresses.push(progress);
                        }
                    }
                });
            },

            toggleDetails (ev) {
                let elm = ev.currentTarget;
                let targetElm = elm.closest('.color_container').querySelector('.content_details');

                if (targetElm.classList.contains('show')) {
                    targetElm.classList.remove('show');
                    elm.innerHTML = 'Open';
                } else {
                    targetElm.classList.add('show');
                    elm.innerHTML = 'Close';
                }
            },

            customVariablePrompt (ev) {
                let showElm = ev.currentTarget.closest('li').querySelector('.dropdown_wpr');

                if (showElm.classList.contains('active')) {
                    showElm.classList.remove('active');
                } else {
                    showElm.classList.add('active');
                }
            },

            deleteCustomVariable (index, object, prog) {
                const vm = this;

                object.splice(index, 1);

                vm.updateCategoryProgress(prog)
            },

            addCustomVariable (ev, object, prog) {
                const vm = this;

                if (vm.customVariableName) {
                    object.push({
                                    title: vm.customVariableName,
                                    enabled: 1,
                                    is_edited: 0,
                                    is_custom: 1,
                                });

                    vm.customVariablePrompt(ev);

                    vm.customVariableName = '';

                    vm.updateCategoryProgress(prog)
                } else {
                    vm.customVariablePrompt(ev);
                }
            },

            handleDeleteCustomTracking (customTracking, customId, index) {
                const vm  = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this  custom tracking`, 'Delete');
                       options.preConfirm = function () {

                            return vm.deleteCustomTracking(customId).then((result) => {
                                if (result) {
                                    customTracking.splice(index, 1);
                                }
                            })
                        }

                Swal.fire(options);
            },

            closeDescription () {
                const vm = this;

                vm.showDescription = false;
            },

            handleAutoSaveForm () {
                const vm          = this;
                const saveButton  = document.getElementById('progress-save-button');

                if (saveButton) {
                    vm.switchStep   = false;
                    vm.closeWindow  = false;
                    saveButton.click();
                }
            },

            handleUnitMinMax (value, obj) {
                if (value == '%' || value == 'slider' || value == 'input' || value == 'percentage') {
                    obj.low_limit   = 0;
                    obj.high_limit  = 100;
                    obj.value = 0
                } else if (value == 'ratings') {
                    obj.low_limit   = 0;
                    obj.high_limit  = 10;
                    obj.value = 0
                }
            },

            handleNumberFieldMaxType (e, obj, field) {
                const value = parseInt(obj[field]);
                const min   = parseInt(e.target.min);
                const max   = parseInt(e.target.max);

                if (field.includes('low') && min > value) {
                    obj[field] = min;
                    e.target.value = min;
                    e.preventDefault();
                } else if (field.includes('high') && max < value) {
                    obj[field] = max;
                    e.target.value = max;
                    e.preventDefault();
                }
            },

            handleSelectOneCategory(measurement, prog){
                const vm = this;

                measurement.enabled = measurement.enabled ? 0 : 1 ;

                const selectOneCategory = prog.filter(measurement => measurement.enabled == 1);

                if (!selectOneCategory.length){
                   const options = Helper.swalWarningOptions('Oops!', ' Please select at least one item');
                   Swal.fire(options)
                   measurement.enabled = 1;
                }

            },

            handleCustomTracking(prog){
                const vm = this;

                if(!prog.unit){
                    const options = Helper.swalWarningOptions('Oops!', ' Please select a unit of measure');
                    Swal.fire(options)
                } else {
                    vm.updateCategoryProgress(prog)
                }
            },

            handleApplySavedStylingWarning () {
                const vm = this;

                if (vm.form.apply_saved_styling) {
                    const options = Helper.swalConfirmOptions('Are you sure?', 'Enabling this will update your header, cover, footer, and more with saved styles, and this action cannot be undone.');

                    Swal.fire(options).then((result) => {
                        if (result.isDismissed) {
                            vm.form.apply_saved_styling = 0;
                        }
                    });
                }
            },
        },
    }
</script>

<style scoped>
.section_title{
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #121525;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.section_title label{
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #6a6a6a;
    display: block;
    padding: 3px 0;
}
.global_setting .color_container .details_content{
    display: none;
}
.global_setting .color_container .details_content.show{
    display: block;
}
.inline-edit {
    display: flex;
    align-items: center;
    margin-right: auto;
}

.progress_info .inline-edit h3 {
    padding: 0;
    margin: 0 auto 0 0;
    font-size: 19px;
    line-height: 26px;
}

.progress_info .inline-edit .rename_fld {
    padding-right: 46px;
    position: relative;
    margin-right: auto;
    max-width: 360px;
    width: 100%;
}

.progress_info input {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #121525;
    padding: 10px 15px;
    background: #f0f5ff;
    border-radius: 5px;
}

.inline-edit button.save_btn {
    padding: 3px 8px;
    background: #2f7eed;
    color: #fff;
    border-radius: 3px;
    font-size: 8px;
    line-height: 10px;
    text-transform: uppercase;
    cursor: pointer;
    text-wrap: nowrap;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.global_setting .color_container {
    border-color: #e9e9e9;
    background: #fff;
    padding: 20px 30px;
}

.progress-wizard-form {
    display: flex;
    flex-direction: column;
    height: inherit;
}

.progress_item {
    border-radius: 10px;
    max-width: 400px;
    margin: 0 auto;
    border: 1px solid #e0e0e0;
}

.progress_item .item_img img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.progress_item .item_ttl {
    font-size: 16px;
    line-height: 22px;
    color: #121525;
    font-weight: 400;
    padding: 15px 20px 0 20px;
    margin-bottom: 15px;
    text-align: left;
    display: block;
    text-decoration: none;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.progress_item .item_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 20px 20px;
}

.progress_item .item_info .member {
    font-size: 13px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.progress_item .item_info img {
    margin-right: 5px;
    max-height: 16px;
    width: auto;
}

.step_name {
    display: flex;
    align-items: center;
}

.step_name h3 {
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #121525;
    padding: 5px 0;
    transition: all 0.3s ease-in-out;
}

.step_wpr h3:not(.sub_header) {
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: #32373b;
    padding: 5px 0;
}

.section_title {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #121525;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.section_title img {
    max-height: 25px;
    width: auto;
    padding-right: 10px;
}

.scope_area {
    padding: 15px 20px;
    background: #f9f9f9;
    border-radius: 5px;
}

.scope_area label.checkbox {
    justify-content: flex-start;
}

.scope_area .field_wpr {
    background: #fff;
    display: flex;
}

.scope_area .field_wpr .unit {
    background: #fbfbfb;
    border-left: 1px solid #d9d9d9;
    border-radius: 0 6px 6px 0;
    height: 50px;
    min-width: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 500;
}

.track_list {
    padding: 15px;
}

.track_list li {
    padding: 0 20px 20px 40px;
    position: relative;
}

.track_list li:not(:last-child):after {
    position: absolute;
    content: '';
    left: 14px;
    top: 0;
    bottom: 0;
    border-left: 2px dashed #b0d1ff;
}

.step_number {
    position: absolute;
    left: 4px;
    top: 0;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2f7eed;
    border: 3px solid #b0d1ff;
    color: #fff;
    z-index: 1;
    font-size: 9px;
    transition: all 0.3s ease-in-out;
}

.track_list li .step_name.active h3 {
    font-size: 15px;
    line-height: 20px;
}

.track_list li .step_name.active .step_number {
    font-size: 13px;
    left: 0;
    width: 30px;
    height: 30px;
}

.time_listing {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 10px -5px 20px;
}

.time_listing li {
    padding: 5px;
}

.time_listing li .checkbox {
    display: block;
}

.selection_box {
    padding: 7px 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    border: 1px solid #e9e9e9;
    color: #121525;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.time_listing li .checkbox input[type=radio]:checked~.selection_box {
    border: 1px solid #b0d1ff;
    background: rgba(47, 126, 237, 0.05);
    color: #2f7eed;
}

.selection_box:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.day_type {
    display: flex;
    flex-direction: column;
    margin: 0 -15px;
}

.day_type li {
    padding: 10px 15px;
    flex: 1 0 50%;
    position: relative;
}

.day_type li .day_wpr {
    display: flex;
    gap: 10px;
    border-radius: 5px;
}

.day_type li .day_wpr label {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
}

.day_type li .day_wpr label span {
    width: 18px;
    height: 18px;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.3);
}

.day_type li .day_wpr label span i {
    transform: scale(0);
    transition: all 0.3s ease-in-out;
}

.day_type li .day_wpr label input[type=radio]:checked~span i {
    transform: scale(1);
}

.day_type li .day_wpr h4 {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #5a5a5a;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.sqr_input {
    width: 30px;
    height: 25px;
    border: 1px solid #d9d9d9;
    margin: 0 5px;
    text-align: center;
    border-radius: 3px;
    background: #f5f5f5;
    color: #121525;
    font-size: 12px;
    font-weight: 500;
}

.date_input {
    width: 140px;
    height: 25px;
    border: 1px solid #d9d9d9;
    margin: 0 5px;
    padding: 0 5px;
    text-align: left;
    border-radius: 3px;
    background: #f5f5f5;
    color: #121525;
    font-size: 13px;
    font-weight: 500;
}

.multiselect.small_box {
    width: 80px;
    min-height: 25px;
    margin: 0 7px;
    font-size: 12px;
}

.sqr_input::-webkit-outer-spin-button,
.sqr_input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.sqr_input[type=number] {
    -moz-appearance: textfield;
}

.time_selection {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.time_selection .form_grp {
    padding: 10px;
    width: 50%;
    margin: 0;
    align-items: flex-end;
}

.day_sorting {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -5px;
}

.day_sorting li {
    padding: 5px;
    width: 25%;
    min-width: 90px;
}

.day_sorting li label {
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
}

.circumference_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 15px -10px;
}

.circumference_list li {
    padding: 10px;
    flex: 1 0 33.333%;
}

.circumference_list li.full {
    flex: 1 1 100%;
}

.circumference_list li .sub_scope_area,
.sub_scope_area_v2 {
    background: #f9f9f9;
    border-radius: 8px;
    padding: 15px;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.circumference_list li .sub_scope_area .field_wpr {
    border: 1px solid #e9e9e9;
    background: #fff;
}

.checkbox_list {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -5px;
}

.checkbox_list li {
    width: 25%;
    padding: 5px;
    position: relative;
}

.checkbox_list li .checkbox {
    background: #f9f9f9;
    border-radius: 5px;
    padding: 2px;
    justify-content: flex-start;
    position: relative;
}

.checkbox_list li .checkbox span {
    margin: 10px;
}

.checkbox_list li .checkbox span.checked i {
    opacity: 1;
    transform: scale(1);
}

.checkbox_list li .checkbox p {
    flex-grow: 1;
    font-weight: 500;
    color: #121525;
    padding: 10px 10px 10px 0;
}

.checkbox_list li .checkbox .item_edit .edit_fld {
    padding-right: 38px;
    position: relative;
    background: #fff;
    width: 100%;
}

.checkbox_list li .checkbox .item_edit .edit_fld input {
    width: 100%;
    font-size: 13px;
    line-height: 19px;
    font-weight: 500;
    color: #121525;
    padding: 8px 5px 8px 10px;
    background: #fff;
    border-radius: 5px;
}

.checkbox_list li .checkbox .item_edit .edit_fld .save_btn {
    padding: 3px 6px;
    background: #2f7eed;
    color: #fff;
    border-radius: 3px;
    font-size: 8px;
    line-height: 10px;
    text-transform: uppercase;
    cursor: pointer;
    text-wrap: nowrap;
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-50%);
}

.checkbox_list li .delete_btn {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ffa0a0;
    font-size: 10px;
    padding-bottom: 1px;
    color: #fff;
    position: absolute;
    right: -3px;
    top: -3px;
    cursor: pointer;
}

.checkbox_list li.add_item button {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 500;
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
}

.checkbox_list li.add_item button i {
    font-size: 9px;
}

.checkbox_list li .dropdown_wpr {
    min-width: 200px;
    left: 50%;
    top: auto;
    bottom: 80%;
    right: auto;
    transform: translateX(-50%);
    padding: 15px;
    margin-bottom: 10px;
    z-index: 4;
}

.checkbox_list li .dropdown_wpr:before {
    display: none;
}

.checkbox_list li .dropdown_wpr .group_item .input_label {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 400;
    margin-bottom: 6px;
    display: block;
}

.checkbox_list li .dropdown_wpr .group_item .field_wpr input {
    height: 30px;
    font-size: 11px;
    padding: 0 10px;
}

.checkbox_list li .dropdown_wpr .action_wpr {
    gap: 5px;
    margin-bottom: 0;
}

.checkbox_list li .dropdown_wpr button {
    height: 24px;
    width: auto;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
}

.checkbox_list li .dropdown_wpr .okay_btn {
    background: #2f7eed;
    color: #fff;
}

.custom_list li {
    border: 1px solid #eaeaea;
    background: #f5f5f5;
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 30px;
}

.custom_list li h5.category_bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 500;
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    padding: 0 15px;
    column-gap: 15px;
}

.category_bar .capsule_btn {
    border-right: 1px solid #d9d9d9;
}

.category_bar input {
    width: 100%;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    margin-right: 12px;
    padding: 15px 0;
}

.category_bar.active input,
.category_bar input:focus {
    background: #f7faff;
    border-left: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    padding: 15px;
}

.category_bar .add_btn {
    font-size: 11px;
    line-height: 16px;
    margin: 0 0 0 15px;
    padding-right: 15px;
    border-right: 1px solid #d9d9d9;
}

.category_bar .add_btn i {
    font-size: 10px !important;
}

.category_bar .delete_btn {
    color: #eb1414;
    font-size: 11px;
    line-height: 16px;
    margin: 0 0 0 15px;
    cursor: pointer;
}

.category_bar .bar_text {
    display: flex;
    align-items: center;
    flex-grow: 1;
    position: relative;
}

.category_bar .bar_text:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-bottom: 1px solid #e9e9e9;
}

.category_bar .bar_actions {
    display: flex;
    align-items: center;
    height: 50px;
}

.custom_list li h5 span.save {
    padding: 3px 8px;
    background: #2f7eed;
    color: #fff;
    border-radius: 3px;
    font-size: 8px;
    line-height: 10px;
    text-transform: uppercase;
    cursor: pointer;
}

.custom_list li h5 label {
    padding: 0 15px 0 0;
    font-size: 11px;
    line-height: 16px;
    font-weight: 500;
    color: #5a5a5a;
    cursor: auto;
    border-right: 1px solid #d9d9d9;
}

.custom_list li h5 label.has {
    color: #2f7eed;
}

.custom_list li h5 .edit_btn {
    font-size: 16px;
    color: #2F7FED;
    margin: 5px 0px;
    background: transparent;
    padding: 0px;
}

.custom_list li .section_wpr {
    border-top: 1px solid #eaeaea;
    margin: 10px -15px 0 -15px;
    padding: 10px 30px;
}

.sub_categories {
    padding: 30px 20px;
    border: 1px solid #eaeaea;
    background: #fafafa;
    border-radius: 6px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.sub_categories h6 {
    font-size: 13px;
    line-height: 18px;
    color: #999;
    font-weight: 400;
    text-align: center;
}

.category_bar .quest_title {
    flex: 1 1 auto;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    margin-right: 12px;
    padding: 15px 0;
}

.prog_edit {
    background: #fff;
    padding: 20px 30px 30px 30px;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    margin-top: 10px;
    cursor: auto;
}

.prog_edit :deep(.tab_content) {
    height: auto;
}

.prog_edit .field_wpr textarea {
    padding: 10px 15px;
    font-size: 13px;
    line-height: 18px;
    height: 100px;
    background: transparent;
    resize: none;
}

.grp_wpr {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 20px 0 5px 0;
}

.grp_wpr label .box_item {
    font-size: 13px;
    line-height: 15px;
    border-radius: 4px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* opacity: 0.5; */
    transition: all 0.3s ease-in-out;
}

.grp_wpr label .box_item i {
    font-size: 13px;
    margin-right: 10px;
}

.grp_wpr label.yes .box_item {
    border: 1px solid var(--var-card-elm-fade-color);
    color: var(--var-card-elm-color);
}

.grp_wpr label.no .box_item {
    border: 1px solid #d9d9d9;
    color: #777;
}

.grp_wpr label input[type="radio"]:checked~.box_item {
    opacity: 1;
}

.question-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 0 5px;
    gap: 20px;
}

.question-options label {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
}

.question-options label p {
    font-size: 13px;
    line-height: 18px;
    padding-left: 12px;
}

.question-options .radio_box {
    width: 13px;
    height: 13px;
    border: 1px solid #5a5a5a;
    border-radius: 50%;
    position: relative;
    display: block;
    margin-top: 3px;
}

.question-options .radio_box .dot {
    position: absolute;
    background: #5a5a5a;
    border-radius: 50%;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    transform: scale(0);
    transition: all 0.3s ease-in-out;
}

.question-options label input[type="radio"]:checked~.radio_box .dot {
    transform: scale(1);
}


.modal.add_question .modal_header {
    padding: 0 45px;
}

.modal.add_question .modal_body {
    padding: 30px 45px 35px 45px;
    display: block;
}

.mcq_list li {
    border: 1px solid #d9d9d9;
    padding: 0 15px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.mcq_list li input {
    padding: 12px 15px 10px 0;
    font-size: 14px;
    line-height: 19px;
}

.mcq_list li button {
    border-left: 1px solid #d9d9d9;
    padding: 0 0 0 15px;
}

.col-2 {
    display: flex;
    margin: 0 -15px;
}

.col-2 .color_palette {
    flex: 1 0 50%;
    padding: 10px 15px;
}

.preview_content {
    width: 40%;
    position: relative;
    margin-right: -40%;
    transition: all 0.3s ease-in-out;
    background: #fff;
    z-index: 2;
}

.preview_content.show {
    margin-right: 0;
}

.preview_btn {
    padding: 10px 20px;
    border-radius: 3px;
    background: #32373b;
    color: #fff;
    font-size: 12px;
    line-height: 15px;
    font-weight: 300;
    position: fixed;
    right: -30px;
    top: 100px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transform: rotate(-90deg);
    cursor: pointer;
}

.preview_content .close_btn {
    position: absolute;
    left: -35px;
    top: 2px;
    font-size: 14px;
}

.tab_sec li {
    display: flex;
    align-items: center;
}

.tab_sec .tag {
    padding: 2px 5px;
    border-radius: 7px;
    font-size: 8px;
    line-height: 10px;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    margin-left: 5px;
}

.cover_type {
    margin: 15px -35px 15px -35px;
    background: #fbfbfb;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    padding: 20px 35px;
}

.cover_type .type_list {
    justify-content: space-around;
    margin-top: 15px;
}

.days {
    display: flex;
    padding: 15px 0;
    gap: 7px;
}

.cell .days {
    border-top: 1px solid #E6E6E6;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 20px;
}

.days li {
    padding: 4px 0;
    flex: 0 1 55px;
    position: relative;
}

.days li label {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 0;
    padding: 10px 6px 12px 6px;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

.days li label span {
    font-size: 13px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 400;
}

.days li label .tick_box {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid #5a5a5a;
    position: relative;
    margin-top: 10px;
}

.days li label .tick_box:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    right: 2px;
    bottom: 2px;
    background-color: #5a5a5a;
    border-radius: 50%;
    transform: scale(0);
    transition: all 0.3s ease-in-out;
}

.days li label input {
    margin-right: 7px;
    opacity: 0;
}

.days li label.active {
    background: rgba(47, 126, 237, 0.05);
    border: 1px solid #b0d1ff;
}

.days li label.active .tick_box:after {
    transform: scale(1);
}

.sender_info {
    display: flex;
    padding-bottom: 5px;
    margin-bottom: 40px;
}

.sender_info img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
}

.sender_info .info {
    margin: 0;
}

.sender_info .info h5 {
    font-size: 13px;
    line-height: 15px;
    font-weight: 500;
    color: #2f7eed;
    margin-bottom: 3px;
}

.sender_info .info h6 {
    font-size: 11px;
    line-height: 13px;
    font-weight: 500;
    color: #5a5a5a;
    margin-bottom: 3px;
}

.sender_info .info h6 span {
    color: #121525;
    font-weight: 600;
    padding-right: 5px;
}

.cell {
    width: 405px;
    padding: 60px 15px 80px;
    border-radius: 40px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    margin: 30px auto;
}

.cell:after,
.cell:before {
    position: absolute;
    content: "";
    width: 50px;
    background: #f2f2f2;
    left: 50%;
    transform: translateX(-50%);
}

.cell:before {
    height: 7px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    top: 25px;
}

.cell:after {
    height: 50px;
    border: 1px solid #d9d9d9;
    border-radius: 50%;
    bottom: 15px;
}

.cell .content_area {
    height: 610px;
    padding: 0;
    border: 1px solid #e7e7e7;
    background: #fafafb;
    position: relative;
    display: flex;
    flex-direction: column;
}

.cell .msg_preview {
    padding: 20px;
    background: #fff;
    border: 1px solid #eaeaea;
    margin: 15px;
    position: relative;
}

.cell .sms_preview {
    padding: 15px 20px;
    background: #fff;
    border: 1px solid #eaeaea;
    margin: 15px;
    position: relative;
    border-radius: 10px;
    font-size: 12px;
    line-height: 17px;
    color: #5a5a5a;
}

.cell .msg_preview:after,
.cell .sms_preview::after {
    position: absolute;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    width: 20px;
    height: 13px;
    left: calc(50% - 10px);
    top: 100%;
}

.cell .sms_preview:after {
    right: 30px;
    left: auto;
}

.cell .msg_preview h2 {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 15px;
    font-weight: 500;
    color: #121525;
}

.cell .msg_preview p,
.cell .sms_preview p {
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 10px;
    font-weight: 400;
    color: #5a5a5a;
}

.cell .msg_preview p span {
    font-size: 11px;
    line-height: 16px;
    padding: 0;
    font-weight: 500;
    color: #121525;
}

.hourly_info {
    padding: 15px;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    background: #fff;
    font-size: 11px;
    line-height: 16px;
    font-weight: 400;
    color: #757575;
    text-align: center;
}

.form_grp :deep(.multiselect-single-label span) {
    font-size: 13px;
}

:deep(.cell .dashboard_footer) {
    width: 100%;
    text-align: center;
    padding: 20px 0;
}

:deep(.cell .dashboard_footer ul li) {
    padding: 2px;
}

:deep(.cell .dashboard_footer ul li a) {
    width: 20px;
    height: 20px;
    border: 1px solid #2f7eed;
    font-size: 10px;
    color: #2f7eed;
    text-decoration: none;
}

:deep(.cell .dashboard_footer h4) {
    font-size: 12px;
    line-height: 14px;
    margin: 10px 0 5px;
}

:deep(.cell .dashboard_footer p) {
    font-size: 9px;
    line-height: 11px;
}

.info_bar {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
    position: relative;
}

.info_bar .tutorial_btn {
    position: absolute;
    left: 100%;
    top: 25px;
    padding: 4px 10px;
    background: #fff;
    border-radius: 11px;
    font-size: 11px;
    line-height: 14px;
    color: #2f7eed;
    font-weight: 500;
    margin-left: 5%;
    cursor: pointer;
}

.time {
    min-width: 150px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
    padding: 10px 25px;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.time span {
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    display: block;
}

.step_bar2 {
    position: relative;
    padding: 20px 30px 10px 30px;
}

.step_bar2 ul {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 25px;
    position: relative;
    z-index: 1;
}

.step_bar2 ul li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.step_bar2 ul li h6 {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #121525;
    white-space: nowrap;
    text-align: center;
}

.step_bar2 ul li:first-child h6 {
    left: 0;
    transform: none;
    text-align: left;
}

.step_bar2 ul li:last-child h6 {
    right: 0;
    left: auto;
    transform: none;
    text-align: right;
}

.step_bar2 ul li span {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #fff;
    border: 3px solid #e5e5e5;
    font-size: 12px;
    font-weight: 500;
    color: #2c3e50;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.step_bar2 .total_bar {
    position: absolute;
    content: '';
    top: 32px;
    left: 30px;
    right: 30px;
    height: 2px;
    background: #d9d9d9;
}

.step_bar2 .total_bar span {
    background: #a1c8ff;
    position: absolute;
    left: 0;
    height: 100%;
    transition: all 0.3s ease-in-out;
}

.step_bar2 ul li.active span {
    background: #2f7eed;
    color: #fff;
    border-color: #a1c8ff;
}

.reset-default {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    line-height: 15px;
    color: #747474;
    font-weight: 400;
    cursor: pointer;
    position: relative;
}

.cover-header-with-text {
    display: flex;
}

.cover-header-with-text .small-cover-info {
    margin: 0;
    margin-left: 10px;
}

.cover-header-with-text div>small {
    font-size: 12px;
}

.flex-center {
    display: flex;
    align-items: center;
}


/* toggle_section */
.toggle_section {
    width: 25px;
    height: 25px;
    font-size: 16px;
    color: #999;
    cursor: pointer;
    margin-left: 15px;
}

.toggle_section i {
    transform: rotate(0);
    transition: all 0.3s ease-in-out;
}

.global_setting .tabs_content .section_content {
    position: relative;
}

.global_setting .tabs_content .section_content .section_header {
    margin: 10px 0;
}

.section_header .inline-edit .rename_fld,
.section_title .inline-edit .rename_fld,
.sub_header .inline-edit .rename_fld {
    padding-right: 46px;
    position: relative;
    margin-right: auto;
    max-width: 360px;
    width: 100%;
}

.section_header .inline-edit .rename_fld input,
.section_title .inline-edit .rename_fld input {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #121525;
    padding: 10px 15px;
    background: #f0f5ff;
    border-radius: 5px;
}

.sub_header .inline-edit .rename_fld input {
    width: 100%;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #121525;
    padding: 8px 15px;
    background: #fff;
    border-radius: 5px;
}

.section_content .section_header .handle-cat-drag {
    font-size: 14px;
    cursor: move;
}

.global_setting .tabs_content .section_content:after {
    content: '';
    position: absolute;
    left: 20px;
    top: 100%;
    right: 20px;
    border-bottom: 1px solid #e9e9e9;
}

.global_setting .tabs_content .section_content.show:after,
.global_setting .tabs_content .section_content:last-of-type:after,
.global_setting .tabs_content .section_content.open_area:after {
    border: 0;
}

.global_setting .tabs_content .drag_catagories .section_content:after {
    border-bottom: 1px solid #e9e9e9;
}

.global_setting .tabs_content .drag_catagories .section_content.show:after {
    border: 0;
}

.global_setting .tabs_content .section_content .toggle_content {
    max-height: 0;
    overflow: hidden;
    animation: smoothSlidedown 0.3s;
    animation-timing-function: cubic-bezier(1.0, 0.5);
}

.global_setting .tabs_content .section_content.show .toggle_content {
    max-height: 10000000px;
    animation: smoothSlideup 0.8s;
    animation-timing-function: cubic-bezier(1.0, 0.5);
}

.global_setting .tabs_content .section_content.show .toggle_section i {
    transform: rotate(-180deg);
}

/* --- */

.section_content .section_header .status {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    margin: 0 0 0 auto;
    flex-grow: 0;
}

.section_content .section_header .switch_option {
    flex: 1 1 auto;
}

.section_header h2 span {
    font-size: 13px;
    line-height: 17px;
    color: #f2a31d;
    display: block;
    padding-top: 7px;
}

.btn_list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px;
}

.btn_list li {
    background: #fff;
    border-radius: 10px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
    padding: 3px 10px;
    cursor: pointer;
}

@media(max-width: 1499px) {
    .time_selection .form_grp {
        flex: 0 0 100%;
        width: 100%;
    }

    .info_bar {
        display: flex;
        flex-direction: column;
        /* background: #fff; */
        /* border: 1px solid #e9e9e9;
        border-radius: 10px; */
        max-width: 400px;
        margin: 0 auto;
        position: relative;
    }

    .time {
        min-width: 150px;
    }

    .selection_box {
        font-size: 11px;
        line-height: 15px;
    }

    .time_listing li {
        padding: 3px;
    }
}

:deep(.weekdays .multiselect-single-label-text) {
    text-transform: capitalize;
}

:deep(.question_list) {
    display: block;
    margin: 0;
}

:deep(.question_list li) {
    width: 100%;
}

:deep(.question_list li h5 label) {
    margin-right: auto;
    margin-left: 0;
    padding-left: 0;
}

.grp_wpr.grp_option {
    display: flex;
    flex-wrap: wrap;
}

.box_item {
    flex: 1 0 calc(50% - 10px);
}

.box_item label {
    border: 1px solid #CBCDD3;
    border-radius: 6px;
    padding: 5px 7px;
    display: flex;
    align-items: center;
}

.mrl-5 {
    margin: 0 -5px;
}

.p-10-15 {
    padding: 10px 15px !important;
}

:deep(.dp__input_wrap input) {
    font-size: 12px;
    color: #5a5a5a;
}

.tab_slider {
    margin: 0 -15px;
}

:deep(.dp__main .dp__input) {
    font-size: 11px;
    max-width: 120px;
    padding: 0 0 0 35px !important;
}

/* Range Slider */
:deep(.cell .tracker_container) {
    background: #fbfbfb;
    /* padding: 0 20px 30px 20px; */
}

.water_card {
    background: #fff;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 30px;
}

.top_area {
    padding: 20px 20px 30px 20px;
    background: var(--var-card-bg-color);
    border-radius: 8px 8px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bottom_area {
    padding: 20px 20px 10px 20px;
    position: relative;
}

.bottom_area:before {
    position: absolute;
    content: '';
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 30px;
    background: url('~@/assets/images/curve_bg.svg');
    background-size: contain;
}

.water_card img {
    max-height: 100px;
    width: auto;
    display: block;
    margin: 0 auto 10px auto;
}

.water_card .main_title {
    font-size: 18px;
    line-height: 25px;
    color: var(--var-card-text-color);
    font-weight: 500;
    margin-bottom: 10px;
}

.water_card .sub_text {
    font-size: 13px;
    line-height: 20px;
    color: var(--var-card-text-color);
    font-weight: 400;
    padding: 0;
    margin-bottom: 15px;
}

.water_card .result {
    font-size: 20px;
    line-height: 23px;
    color: var(--var-card-text-color);
    font-weight: 400;
    padding: 5px 0;
    /* border-bottom: 1px solid #5a5a5a; */
    margin-bottom: 15px;
}

.water_card .result span {
    font-size: 15px;
    line-height: 18px;
    color: var(--var-card-text-color);
    font-weight: 400;
}

.water_card .result_list {
    width: 100%;
    padding-bottom: 15px;
}

.water_card .result_list ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    row-gap: 10px;
}

.water_card .result_list li {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 30%;
}

.water_card .result_list li label {
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
}

.water_card .result_list li label span {
    background: var(--var-card-elm-color);
    padding: 3px 8px;
    font-size: 10px;
    line-height: 12px;
    border-radius: 10px;
    color: #fff;
    font-weight: 400;
}

.water_card .result_list li .item_result {
    font-size: 20px;
    line-height: 23px;
    color: #121525;
    font-weight: 400;
    padding: 5px 0;
    /* border-bottom: 1px solid #5a5a5a; */
}

.water_card .result_list li .item_result span {
    font-size: 15px;
    line-height: 18px;
    color: #121525;
    font-weight: 400;
}

.water_card .result_list.sm li .item_result {
    font-size: 15px;
    line-height: 20px;
    color: #121525;
    font-weight: 400;
}

.water_card .ratings {
    display: flex;
    gap: 4px;
    padding-bottom: 15px;
}

.water_card .ratings span {
    font-size: 12px;
    color: #999;
}

.water_card .ratings span i.active {
    color: var(--var-card-elm-color);
}

.water_card .single_item {
    padding: 5px 10px;
    background: #fff;
    border-radius: 3px;
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
}

.water_card .single_item {
    padding: 5px 10px;
    background: #fff;
    border-radius: 3px;
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
    margin-bottom: 10px;
}

.water_card .multiple_items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 5px;
    margin-bottom: 10px;
}

.water_card .multiple_items span {
    padding: 4px 8px;
    background: #fff;
    border-radius: 3px;
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
}

.range_slider {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 0;
}

.range_slider .range_label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #5a5a5a;
    margin: 15px 0 0;
}

.range_slider span {
    height: 6px;
    background: var(--var-card-elm-color);
    position: absolute;
    top: 15px;
    border-radius: 3px;
}

.range_slider span label {
    position: absolute;
    right: 0;
    transform: translateX(50%);
    bottom: 100%;
    padding: 3px 6px;
    background: var(--var-card-elm-color);
    border-radius: 3px;
    font-size: 11px;
    line-height: 14px;
    color: #fff;
    font-weight: 400;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    display: inline-block;
    margin-bottom: 12px;
}

.range_slider span label:after {
    position: absolute;
    content: '';
    border-top: 4px solid var(--var-card-elm-color);
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.range_slider .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background: var(--var-card-elm-fade-color);
    outline: none;
    margin: 0;
    position: relative;
}

.range_slider .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid var(--var-card-elm-color);
    background: var(--var-card-elm-color);
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    z-index: 2;
}

.range_slider .slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border: 1px solid var(--var-card-elm-color);
    background: var(--var-card-elm-color);
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    z-index: 2;
}

.water_card .input_section {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.water_card .input_section .field_box {
    flex: 1 0 33.333%;
    padding: 0 10px 10px 10px;
    display: flex;
    flex-direction: column;
}

.water_card .input_section label {
    width: 100%;
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 400;
    text-align: left;
    padding: 10px 10px 7px 10px;
}

.water_card .input_section .field_box label {
    padding: 10px 0 7px 0;
}

.water_card .input_section .field_box .field_area {
    display: flex;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    position: relative;
}

.water_card .input_section input {
    height: 40px;
    width: 100%;
    padding: 0 10px;
    background: transparent;
}

.water_card .input_section .field_box .unit {
    background: #f5f5f5;
    border-left: 1px solid #e9e9e9;
    border-radius: 0 5px 5px 0;
    height: 40px;
    min-width: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 500;
}

.water_card .check_list {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -5px;
}

.water_card .check_list li {
    width: 50%;
    padding: 5px;
}

.water_card .check_list li .checkbox {
    justify-content: flex-start;
}

.water_card .check_list li .checkbox span.checked i {
    opacity: 1;
    transform: scale(1);
}

.water_card .check_list li .checkbox p {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #121525;
    padding-left: 10px;
}

.water_card .radio_list {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -10px;
}

.water_card .radio_list li {
    width: 50%;
    padding: 10px;
}

.water_card .radio_list li .radio_box {
    border: 1px solid #e9e9e9;
    background: #fff;
    border-radius: 8px;
    padding: 5px 15px 15px 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.water_card .radio_list li .radio_box.active {
    border-color: var(--var-card-bg-color);
    background: var(--var-card-bg-color);
}

.water_card .radio_list li .radio_box img {
    height: 50px;
    width: auto;
    margin-bottom: 5px;
}

.water_card .radio_list li .radio_box h5 {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #121525;
    margin-bottom: 5px;
}

.water_card .radio_list li .radio_box p {
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #5a5a5a;
}

.water_card .notes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-height: 25px;
    overflow: hidden;
    margin-bottom: 15px;
    transition: all 0.3s ease-in-out;
}

.water_card .notes.active {
    max-height: 500px;
}

.water_card .notes .add_notes {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background: #f5f5f5;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
    cursor: pointer;
}

.water_card .notes .add_notes i {
    margin-top: 2px;
    font-size: 8px;
}

.water_card .notes textarea {
    height: 80px;
    width: 100%;
    padding: 10px;
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #121525;
    resize: vertical;
}

.water_card .notes .save_btn {
    padding: 4px 10px;
    border-radius: 3px;
    background: var(--var-card-elm-color);
    color: #fff;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    margin-left: auto;
    cursor: pointer;
}

.water_card table.table_list {
    width: 100%;
    border-spacing: 1px;
    background: #eaeaea;
    margin-bottom: 15px;
}

.water_card table.table_list tr {
    border-bottom: 1px solid #e9e9e9;
}

.water_card table.table_list th,
.water_card table.table_list td {
    padding: 5px 10px;
    text-align: left;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
}

.water_card table.table_list th {
    background: #fbfbfb;
}

.water_card table.table_list td {
    background: #fff;
}

.water_card table.table_list td:first-child {
    width: 37px;
}

.water_card table.table_list td:last-child {
    width: 120px;
}

.water_card table.table_list td .field_area {
    gap: 5px;
    display: flex;
    align-items: center;
    position: relative;
}

.water_card table.table_list td input {
    height: 25px;
    width: 100%;
    background: transparent;
}

.water_card table.table_list td .unit svg {
    width: 18px;
    height: 20px;
}

.water_card table.table_list .checkbox span.checked i {
    opacity: 1;
    transform: scale(1);
}

.water_card .body_area {
    margin-bottom: 20px;
    text-align: left;
}

.water_card .body_area table.table_list td:last-child {
    width: 100px;
}

.water_card .body_area table.table_list .field_area.blur {
    opacity: 0.5;
    pointer-events: none;
}

.water_card .pain_area .tabs {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    gap: 20px;
    border-bottom: 1px solid #e9e9e9;
}

.water_card .pain_area .tabs li {
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #121525;
    padding: 0 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-bottom: -1px;
}

.water_card .pain_area .tabs li.active {
    color: var(--var-card-elm-color);
}

.water_card .pain_area .area_section {
    padding-top: 15px;
}

.water_card .pain_area .area_section .pain_item {
    padding: 10px 15px;
    background: #fbfbfb;
    border-radius: 5px;
    margin-bottom: 15px;
}

.water_card .pain_area .area_section .pain_item label.checkbox {
    justify-content: flex-start;
}

label.checkbox span.checked i {
    transform: scale(1);
    opacity: 1;
}

.water_card .pain_area .area_section .pain_item label.checkbox p {
    font-size: 13px;
    line-height: 16px;
    padding-left: 10px;
}

.water_card .pain_area .area_section .pain_item .select_box {
    width: 100%;
    border: 1px solid #e9e9e9;
    background: #fff;
    border-radius: 5px;
    padding: 7px 20px 7px 10px;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #5a5a5a;
    margin: 15px 0 25px 0;
    text-align: left;
    position: relative;
}

.water_card .pain_area .area_section .pain_item .select_box p {
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #777;
}

.water_card .pain_area .area_section .pain_item .select_box .tag_wpr {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
}

.water_card .pain_area .area_section .pain_item .select_box .tag {
    background: var(--var-card-elm-color);
    padding: 2px 6px;
    font-size: 10px;
    line-height: 12px;
    border-radius: 3px;
    color: #fff;
    font-weight: 400;
}

.water_card .pain_area .area_section .pain_item .select_box>i {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    color: #999;
}

.water_card .pain_area .area_section .pain_item .select_box .dropdown_wpr {
    z-index: 4;
}

.select_box .dropdown_wpr ul li {
    display: flex;
    gap: 10px;
}

.select_box .dropdown_wpr ul li:last-child {
    border: 0;
    justify-content: flex-start;
}

.select_box .dropdown_wpr ul li .checkbox {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    border: 1px solid #BABDC3;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    cursor: pointer;
    padding: 0 !important;
}

.select_box .dropdown_wpr ul li .checkbox i {
    font-size: 8px;
    line-height: 10px;
    color: #121525;
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.select_box .dropdown_wpr ul li .checkbox.checked i {
    transform: scale(1);
    opacity: 1;
}

.water_card .pain_area .area_section .pain_item .result_info {
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #5a5a5a;
    padding: 10px 15px;
    border-radius: 5px;
    background: var(--var-card-bg-color);
    margin: 10px 0;
}

.notification-warning {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: #F2A31D;
    margin-top: -5px;
}

/* ------- Custom Tracking ----------- */

.empty_items {
    padding: 30px 15px;
    border: 1px solid #f5f5f5;
    background: #fafafa;
    border-radius: 6px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 30px;
    font-size: 13px;
    line-height: 18px;
    color: #999;
    font-weight: 400;
    text-align: center;
}

.custom_btn {
    color: #5a5a5a;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 8px 20px;
    border: 1px solid #e9e9e9;
    border-radius: 20px;
    margin: 20px auto 5px auto;
    cursor: pointer;
}

.custom_btn i {
    font-size: 11px;
    margin-right: 5px;
}

.tracking_wpr {
    border-top: 1px solid #e9e9e9;
    padding: 10px 30px 30px 30px;
}

.question_wpr {
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    margin-top: 20px;
}

.question_wpr .section_title {
    /* padding: 20px 30px; */
    background: transparent;
    border-radius: 6px;
}

.question_wpr .section_title .delete_btn {
    color: #eb1414;
    font-size: 11px;
    line-height: 16px;
    margin: 0 0 0 15px;
    cursor: pointer;
}

/* .global_setting .setting_wpr .form_grp {
    padding: 5px 0;
} */

/* .global_setting .setting_wpr .form_grp .group_item {
    padding: 10px 5px;
} */

.progress_title h1 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #121525;
    margin-bottom: 5px;
    text-align: center;
}

.progress_title h4 {
    font-size: 13px;
    line-height: 18px;
    font-weight: 300;
    color: #5a5a5a;
    margin-bottom: 15px;
    text-align: center;
}

.category_label {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #121525;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    position: relative;
}

.category_label:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 15%;
    right: 15%;
    border-bottom: 1px solid #d9d9d9;
    z-index: 0;
}

.category_label span {
    padding: 7px 15px;
    background: #fafafb;
    position: relative;
    z-index: 1;
}

.submit_progress .field_wpr {
    background: #fff;
}

.submit_progress .field_wpr input {
    height: 45px;
}

.submit_progress .primary_btn {
    height: 45px;
    font-size: 15px;
    line-height: 20px;
}

.toggle_details {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #5a5a5a;
    margin: 0 15px;
    cursor: pointer;
}

.content_details {
    display: none;
}

.content_details.show {
    display: block;
}

.section_title label {
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #6a6a6a;
    display: block;
    padding: 3px 0;
}

.toggle_details {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #5a5a5a;
    margin: 0 15px;
    cursor: pointer;
}

.content_details {
    display: none;
}

.content_details.show {
    display: block;
}

.custom_icon {
    position: relative;
}

.custom_icon .reset-default {
    position: absolute;
    right: 0;
    bottom: 0;
}

.setting_wpr .group_item .input_label .information {
    align-self: center;
    font-size: 16px;
    color: #999;
    margin: 0 auto 0 10px;
}

.water_card .picture_section {
    border-top: 1px dashed #e9e9e9;
    padding: 15px 0 10px 0;
    margin-top: 25px;
}

.water_card .picture_section .field_area textarea {
    height: 80px;
    width: 100%;
    padding: 10px;
    font-size: 11px;
    color: #5a5a5a;
    border-radius: 4px;
    background: transparent;
    resize: none;
}

.water_card .picture_section :deep(.tab_content) {
    height: 100%;
}

.water_card .picture_section .upload_image {
    padding: 0 10px;
    width: 100%;
    margin-bottom: 0;
}

.water_card .picture_section .upload_image.Landscape :deep(.upload_wpr .upload_area),
.water_card .picture_section .upload_image.Portrait :deep(.upload_wpr .upload_area) {
    width: 100%;
    min-height: 150px;
    padding: 0;
    border: 0;
    background-color: #fbfbfb;
}

.water_card .picture_section :deep(.upload_wpr img) {
    height: 25px;
    width: auto;
    opacity: 0.5
}

.water_card .picture_section .upload_image :deep(.upload_wpr .upload_area h4) {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #5a5a5a;
}

.water_card .picture_section .upload_image :deep(.upload_wpr .info) {
    margin: 7px 0 !important;
    font-size: 11px;
    line-height: 14px;;
}
.inline-edit .setting_btn{
    font-size: 16px;
    color: #999;
    cursor: pointer;
    margin-left: 15px;
}

.progress_info .description{
    background: #fff;
    padding: 0 15px;
    border-radius: 5px;
    border: 0;
    position: absolute;
    right: 20px;
    top: 100%;
    z-index: 2;
    max-height: 0;
    overflow: hidden;
    animation: smoothSlidedown 0.3s;
    animation-timing-function: cubic-bezier(1.0, 0.5);
}

.progress_info .description .field_wpr{
    background: #fff;
}

.progress_info .description.show{
    border: 1px solid #f5f5f5;
    box-shadow: 0 1px 20px rgb(0,0,0,0.15);
    max-height: 500px;
    animation: smoothSlideup 0.8s;
    animation-timing-function: cubic-bezier(1.0, 0.5);
}

.collection-wpr {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}

.collection-wpr .day_wpr {
    display: flex;
    gap: 10px;
    border-radius: 5px;
}

.collection-wpr .day_wpr label {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
}

.collection-wpr .day_wpr h4 {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #121525;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.collection-wpr .day_wpr label span {
    width: 14px;
    height: 14px;
    border: 1px solid #5a5a5a;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
}

.collection-wpr .day_wpr label input[type=radio]~span i {
    transform: scale(0);
    transition: all 0.3s ease-in-out;
    color: #5a5a5a;
}

.collection-wpr .day_wpr label input[type=radio]:checked~span i {
    transform: scale(1);
    transition: all 0.3s ease-in-out;
}

@media(max-width: 1199px) {
    .info_bar {
        margin: 20px 10px;
    }

    .cover_type {
        margin: 15px -25px;
    }
}

@media(max-width: 991px) {
    .prog_edit {
        padding: 20px;
    }
}

@media(max-width: 767px) {

    .category_bar input,
    .category_bar .quest_title {
        font-size: 13px;
    }

    .checkbox_list li {
        width: 33.333%;
    }

    .circumference_list li {
        flex: 0 0 100%;
    }
}

@media(max-width: 599px) {
    .checkbox_list li label.checkbox p {
        font-size: 11px;
        line-height: 16px;
    }
}

@media(max-width: 499px) {
    .day_type li .day_wpr h4 {
        font-size: 11px;
    }

    .sqr_input {
        width: 20px;
        height: 20px;
        font-size: 10px;
    }

    :deep(.dp__main .dp__input) {
        font-size: 10px;
        padding: 0 0 0 25px !important;
    }

    :deep(.dp__main .dp__input_icons) {
        width: 12px;
    }

    .day_type li .day_wpr label {
        width: 40px;
        min-width: auto;
        flex-shrink: 0;
    }

    .time_listing li {
        width: 25%;
    }

    .circumference_list li {
        width: 100%;
    }

    .custom_list li {
        padding: 15px;
    }

    .custom_list li .section_wpr {
        padding: 10px 20px;
    }

    .category_bar .capsule_btn {
        padding: 0 10px 0 0;
    }

    .category_bar .add_btn {
        margin: 0 0 0 10px;
        padding-right: 10px;
    }

    .sub_categories {
        padding: 20px 15px;
    }

    .prog_edit {
        padding: 15px;
    }

    .cover_type {
        margin: 15px -15px;
    }

    .global_setting .color_container,
    .question_wpr .section_title {
        padding: 20px;
    }

    .tracking_wpr {
        padding: 10px 20px 30px 20px;
    }

    .global_setting .setting_wpr .form_grp .group_item {
        flex: 0 0 100%;
    }

    .scope_area .field_wpr .unit {
        height: 40px;
        font-size: 11px;
    }

    .checkbox_list li {
        width: 50%;
    }
}
</style>
